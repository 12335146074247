import React, { FC } from "react";

import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

import backgroundImage from "./SIPCBackgroundImage.svg";

const useStyles = makeStyles<Theme, { backgroundColor?: string }>((theme) =>
	createStyles({
		card: {
			backgroundColor: (props) => props.backgroundColor || "#333B49",
			backgroundImage: (props) =>
				props.backgroundColor ? "none" : `url(${backgroundImage})`,
			backgroundSize: 250,
			backgroundRepeat: "no-repeat",
			backgroundPositionY: -114,
			backgroundPositionX: -51,
			borderRadius: 4,
			display: "flex",
			paddingBottom: 24,
			paddingTop: 24,
			paddingLeft: 16,
			paddingRight: 16,
			alignItems: "flex-start",
			maxWidth: 600,
			[theme.breakpoints.down("md")]: {
				flexDirection: "column",
			},
		},
		image: {
			paddingLeft: 25,
			paddingRight: 50,
			[theme.breakpoints.down("md")]: {
				paddingRight: 12,
				paddingLeft: 0,
				paddingBottom: 16,
			},
		},
		description: {
			color: "#fff",
			fontWeight: 500,
			lineHeight: "22px",
			fontSize: 14,
			fontFamily: "Cerebri Sans",
		},
		button: {
			fontFamily: "Cerebri Sans",
			cursor: "pointer",
			textDecoration: "none",
			textTransform: "none",
			color: "#fff",
			opacity: 1,
			transition: "opacity 0.2s ease-in-out",
			"&:hover": {
				opacity: 0.8,
			},
		},
	})
);

interface Params {
	logo?: string;
	buttonHref?: string;
	backgroundColor?: string;
}

const SIPCBanner: FC<Params> = ({ logo, buttonHref, backgroundColor }) => {
	const styles = useStyles({ backgroundColor });

	return (
		<div className={styles.card}>
			<div className={styles.image}>
				<img alt="logo" src={logo || ""} />
			</div>

			<div>
				<Typography className={styles.description}>
					Member of SIPC, which protects securities customers of its members up
					to $500,000 (including $250,000 for claims for cash). Explanatory
					brochure available upon request or at{" "}
					<a
						rel="noopener noreferrer"
						href="https://www.sipc.org/"
						target="_blank"
						className={styles.button}
					>
						www.sipc.org.
					</a>
				</Typography>
			</div>
		</div>
	);
};

export default SIPCBanner;
