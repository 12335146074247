import { computed, observable, action } from "mobx";

import { addYears, isBefore } from "date-fns";
import { Application } from "stores/application/ApplicationRoot";
import FormField from "../helpers/FormField";

class DateOfBirth {
  public rootStore: Application;

  @observable public dateOfBirth: FormField<string> = new FormField("", [
    "required",
    "over16",
    "date-valid",
  ]);

  @observable
  public dateOfBirthServer: FormField<string> = new FormField("", []);

  @computed
  public get dateOfBirthServerComponents() {
    if (!this.dateOfBirthServer.value) {
      return undefined;
    }

    const components = this.dateOfBirthServer.value.split("-");

    return { day: components[1], month: components[2], year: components[0] };
  }

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @computed
  public get pageInvalid() {
    if (this.dateOfBirthServer.value) {
      return false;
    }

    const dob = new Date(this.dateOfBirth.value!);

    const isOverSixteen = isBefore(addYears(dob, 16), new Date());

    if (isOverSixteen) {
      return false;
    }

    return true;
  }

  @action
  public set = (value: string) => {
    this.dateOfBirth.set(value);
  };

  @action
  public putRequest() {
    const { dateOfBirth, dateOfBirthServer } = this;

    const { apiClient } = this.rootStore;

    if (!apiClient) {
      throw new Error("Could not connect to services.");
    }

    if (dateOfBirthServer.value) {
      //todo double check this doesnt cause issues
      return;
    }

    return {
      dateOfBirth: dateOfBirth.value!,
    };
  }
}

export default DateOfBirth;
