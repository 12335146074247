import { useCallback, useEffect, useState } from "react";
import useSnackbar from "./useSnackbar";

type IFuncWithParams = (param1?: any, param2?: any, param3?: any) => any;

const useApiCall = (
  apiCall: IFuncWithParams,
  onComplete: IFuncWithParams,
  clearErrorVariables?: any[]
): {
  loading: boolean;
  errMsg: any;
  makeCall: () => void;
  response: any;
} => {
  // state
  const [errorMessage, setErrMsg] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [call, setCall] = useState(false);
  const [response, setResponse] = useState<any>();

  const [errMsg] = useSnackbar({
    message: errorMessage,
    timeout: 4000,
    variant: "error",
  });

  // make api call
  useEffect(() => {
    if (call === true) {
      (async function run() {
        setResponse(undefined);

        setCall(false);

        setLoading(true);

        try {
          const res = await apiCall();

          setLoading(false);

          onComplete();

          setResponse(res.data || res);
        } catch (error) {
          setLoading(false);

          if (error.message) {
            setErrMsg(error.message || "network error");
            return;
          }

          setErrMsg(
            "An unexpected error occured while processing your request."
          );
        }
      })();
    }
    /* eslint-disable-next-line  */
  }, [call, setErrMsg]);

  // clear error message whenever a for variable changes
  useEffect(() => {
    setErrMsg(undefined);
    /* eslint-disable-next-line  */
  }, clearErrorVariables);

  const makeCall = useCallback(() => {
    setCall(true);
  }, [setCall]);

  return { loading, errMsg, makeCall, response };
};

export default useApiCall;
