import useApiCall from "hooks/useApiCall";
import React, {
	FunctionComponent,
	useEffect,
	useContext,
	useState,
} from "react";

import DefaultLayout from "pages/DefaultLayout";
import ApplicationContext, {
	ApplicationType,
} from "stores/application/ApplicationRoot";
import CustomStepLoader from "components/UI/new/StepLoader/CustomStepLoader";
import StepCaption from "components/UI/new/StepCaption";
import StepTitle from "components/UI/new/StepTitle";
import SectionDescription from "components/UI/Typography/SectionDescription";
import ResendEmailVerification from "containers/ResendEmailVerification";
import EmailIcon from "components/UI/icons/EmailIcon";
import SearchIcon from "components/UI/icons/SearchIcon";
import ToolsCTA from "components/Tools/ToolsCTA";
import SIPCBanner from "components/Banners/SIPCBanner";
import SIPCLogo from "components/Banners/SIPCLogo.svg";
import SupportLogo from "components/UI/icons/SupportIcon.svg";
import Box from "@material-ui/core/Box";
import SupportBannder from "components/Banners/SupportBanner";
import { Grow, LinearProgress } from "@material-ui/core";
import jwtDecode from "jwt-decode";
import { AccessToken } from "@okta/okta-auth-js";
import LogoWithText from "components/UI/new/LogoWithText";
import useOkta from "hooks/useOkta";
import Snack from "components/UI/Snack/Snack";

interface IStatusContainerProps {
	getApplication: () => any;
}

export const StatusContainer: FunctionComponent<IStatusContainerProps> = () => {
	const application = useContext(ApplicationContext);

	const { oktaAuth, authState, isSubscriber: jwtIsSubscriber } = useOkta();

	const { response: data, makeCall: getUser } = useApiCall(
		() => {
			return application.apiClient?.getUser();
		},
		() => ({ status: 200 }),
		[]
	);

	useEffect(() => {
		getUser();

		setInterval(() => {
			getUser();
		}, 5000);
	}, [getUser]);

	const { loading, makeCall, errMsg } = useApiCall(
		() => {
			return application.apiClient?.getApplication();
		},
		() => ({ status: 204 }),
		[]
	);

	useEffect(() => {
		makeCall();
	}, [makeCall]);

	const [emailVerified, setEmailVerified] = useState(false);

	const verificationParam = data?.verificationEmail;

	const [loadingRefreshToken, setLoadingRefreshToken] = useState(false);

	const refreshToken = async () => {
		setLoadingRefreshToken(true);

		if (
			application.applicationType !== ApplicationType.SUBSCRIPTION ||
			!authState?.accessToken
		) {
			return;
		}

		console.log("stariing...", authState.accessToken);

		const newToken = (await oktaAuth.token.renew(
			authState.accessToken
		)) as AccessToken;


		console.log('newToken', newToken);

		if (newToken) {
			oktaAuth.tokenManager.add("accessToken", newToken);
		}

		if (!newToken.accessToken) {
			throw new Error("unable to refresh token");
		}

		const decoded = jwtDecode<{ groups: string[]; }>(newToken.accessToken);

		console.log('decoded', decoded);

		if (
			!decoded.groups?.includes("Subscribers") ||
			decoded.groups?.includes("Subscription Applicants")
		) {
			setTimeout(() => {
				refreshToken();
			}, 1000);
			return;
		}


		window.location.href = process.env.REACT_APP_TRADE || "";
	};

	useEffect(() => {
		if (
			!emailVerified ||
			application.applicationType !== ApplicationType.SUBSCRIPTION
		) {
			return;
		}

		refreshToken();
		// eslint-disable-next-line
	}, [emailVerified, application.applicationType]);

	useEffect(() => {
		if (verificationParam === undefined) {
			return;
		}

		setEmailVerified(verificationParam);
	}, [verificationParam]);

	const [openCTAs, setOpenCTAs] = useState(false);

	useEffect(() => {
		if (loading === true) {
			return;
		}

		setTimeout(() => {
			setOpenCTAs(true);
		}, 3500);
	}, [loading]);

	const isSubscriber = application.isSubscriber;

	if (loadingRefreshToken) {
		return (
			<Box
				height="100%"
				display="flex"
				justifyContent="center"
				alignItems="center"
			>
				<div>
					<LogoWithText />
					<LinearProgress />
				</div>
			</Box>
		);
	}

	return (
		<>
			<DefaultLayout
				hasContinue={false}
				onNextDisabled={true}
				onBackDisabled={true}
			>
				{!emailVerified && (
					<CustomStepLoader
						submittedStep={
							<>
								<StepCaption value="JUST FINISHED" />

								<StepTitle
									value={
										isSubscriber
											? "Subscription Form Complete!"
											: "Application Complete!"
									}
								/>

								<SectionDescription
									value={
										isSubscriber
											? "Your subscription form has been successfully completed."
											: "Your application has been successfully completed."
									}
								/>
							</>
						}
						completedStep={
							<>
								<StepCaption value="JUST FINISHED" />

								<StepTitle
									value={
										isSubscriber
											? "Subscription Form Complete!"
											: "Application Complete!"
									}
								/>

								<SectionDescription
									value={
										isSubscriber
											? "Your subscription form has been successfully completed."
											: "Your application has been successfully completed."
									}
								/>
							</>
						}
						nextStep={
							<>
								<StepCaption value="UP NEXT" />

								<StepTitle value="Email Verification" />

								<SectionDescription
									value={
										isSubscriber
											? "To gain access, click link in the email that was sent to you."
											: "Click link in the email that was sent to you."
									}
								/>

								<ResendEmailVerification />
							</>
						}
						nextStepIcon={<EmailIcon />}
						loading={loading}
					/>
				)}

				{Boolean(!!emailVerified && !isSubscriber) && (
					<CustomStepLoader
						nextStepIcon={<SearchIcon />}
						submittedStep={
							<>
								<StepCaption value="JUST FINISHED" />

								<StepTitle value="Email Verification" />

								<SectionDescription value="Your email has been verified successfully." />
							</>
						}
						completedStep={
							<>
								<StepCaption value="JUST FINISHED" />

								<StepTitle value="Email Verification" />

								<SectionDescription value="Your email has been verified successfully." />
							</>
						}
						nextStep={
							<>
								<StepCaption value="UP NEXT" />

								<StepTitle value="Under Review" />

								<SectionDescription value="We typically take less than 1 business day to review an account application. You’ll hear from us soon!" />

								<SectionDescription value="While you wait, get familiarized with the AI platform." />
							</>
						}
						loading={loading}
					/>
				)}

				{Boolean(!!jwtIsSubscriber && !application.isSubscriber) && (
					<Grow in={openCTAs}>
						<Snack
							open={Boolean(!!jwtIsSubscriber && !application.isSubscriber)}
							message="Continue to Subscription"
							variant="info"
							onView={() =>
								(window.location.href = process.env.REACT_APP_TRADE || "")
							}
						/>
					</Grow>
				)}

				{!isSubscriber && (
					<Grow in={openCTAs}>
						<Box pt={8}>
							<ToolsCTA />

							<Box mt={12} mb={2}>
								<SIPCBanner logo={SIPCLogo} />
							</Box>

							<SupportBannder
								backgroundColor="#2C74F6"
								caption="we are here to help"
								description="Options AI offers expert support to answer your trading questions."
								buttonText="Get in touch"
								buttonHref="https://support.optionsai.com"
								logo={SupportLogo}
							/>
						</Box>
					</Grow>
				)}

				{errMsg}
			</DefaultLayout>
		</>
	);
};

export default StatusContainer;
