import { browserHistory as history } from "core/history/history";
import { useEffect, useState } from "react";

interface IUseGoBackParams {
  location: any;
  secondaryRoute: string;
}

const useGoBack = ({ location, secondaryRoute }: IUseGoBackParams) => {
  const [called, setCalled] = useState<boolean>(false);

  useEffect(() => {
    if (called) {
      if (location.state && location.state.returnUrl) {
        history.replace(location.state.returnUrl);
        location.state.returnURl = undefined;
        return;
      }

      if (location.state && location.state.navpanel) {
        history.replace(`${location.pathname}#navpanel=true`);
        return;
      }

      if (location.state && location.state.from) {
        history.goBack();
        return;
      }

      history.replace(secondaryRoute);
    }
  }, [called, location.state, secondaryRoute, location.pathname]);

  const goBack = () => {
    setCalled(true);
  };

  return { goBack };
};

export default useGoBack;
