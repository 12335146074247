import {
	FormControl,
	Select as MUISelect,
	MenuItem,
	makeStyles,
	createStyles,
	Theme,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

const useStyles = makeStyles<Theme, { selectedValue?: boolean }>((theme) =>
	createStyles({
		placeholder: {
			color: (props) => (props.selectedValue ? "#000" : "#BDBDBD !important"),
		},
	})
);

interface ISelectOption {
	value: any;
	label: string;
}

interface ISelectProps {
	selectedValue?: any;
	handleChange?: (e: any) => void;
	values?: ISelectOption[];
	header?: string;
	htmlFor?: string;
	id?: string;
	native?: boolean;
	placeholder?: string;
	disabled?: boolean;
}

const Select: FunctionComponent<ISelectProps> = ({
	selectedValue = "",
	handleChange,
	values = [],
	header = "",
	htmlFor = `filled-${header}`,
	id,
	native = true,
	placeholder = "",
	disabled = false,
}) => {
	const styles = useStyles({ selectedValue: !!selectedValue });

	return (
		<FormControl style={{ marginTop: 13, marginBottom: 20 }} fullWidth>
			<MUISelect
				disabled={disabled}
				className={styles.placeholder}
				displayEmpty
				id={id}
				value={selectedValue}
				onChange={(e: any) => handleChange && handleChange(e.target.value)}
			>
				<MenuItem value="">{placeholder}</MenuItem>
				{values.map((value) => (
					<MenuItem key={value.value} value={value.value}>
						{value.label}
					</MenuItem>
				))}
			</MUISelect>
		</FormControl>
	);
};

export default Select;
