import React, { FC } from "react";

import { Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: 56,
		lineHeight: "63px",
		fontWeight: 900,
		color: "white",
		fontFamily: "Cerebri Sans",
		marginBottom: 40,
		"@media (max-width: 850px)": {
			fontSize: 20,
			lineHeight: "24px",
			marginBottom: 20,
		},
		[theme.breakpoints.down("md")]: {
			fontSize: "48px",
			lineHeight: "52px",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "32px",
			lineHeight: "36px",
		},
	},
	description: {
		fontSize: 14,
		lineHeight: "27px",
		fontWeight: 700,
		color: "white",
		marginTop: 20,
		fontFamily: "Cerebri Sans",
		"@media (max-width: 850px)": {
			fontSize: 12,
			lineHeight: "20px",
		},
		[theme.breakpoints.down("md")]: {
			fontSize: 12,
			lineHeight: "20px",
		},
	},
}));

interface Params {
	title?: string;
	descriptions?: string[];
}

const StepPanelBasic: FC<Params> = React.memo(
	({ title, descriptions = [] }) => {
		const classes = useStyles();

		return (
			<div>
				<Typography className={classes.title}>{title}</Typography>

				{descriptions.map((description, index) => (
					<Typography className={classes.description} key={index}>
						{description}
					</Typography>
				))}
			</div>
		);
	}
);

export default StepPanelBasic;
