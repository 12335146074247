import { EmploymentStatus } from "../Application.d";

const genericCleanedEnum = (enumVal?: EmploymentStatus) => {
  if (!enumVal) {
    return undefined;
  }

  const s = enumVal
    .toString()
    .split("_")
    .join(" ")
    .split("-")
    .join(" ")
    .toLowerCase();

  return s.charAt(0).toUpperCase() + s.slice(1);
};

export { genericCleanedEnum };
