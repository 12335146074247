import { observable } from "mobx";
import FormField from "stores/helpers/FormField";
import { Application } from "./ApplicationRoot";

class SweepProgram {
	public rootStore: Application;

	@observable
	public enroll = new FormField<boolean>(false);

	constructor(rootStore: any) {
		this.rootStore = rootStore;
	}

	public putRequest() {
		const { enroll } = this;

		const { apiClient } = this.rootStore;

		if (!apiClient) {
			throw new Error("Could not connect to services.");
		}

		return enroll.value || false;
	}
}

export default SweepProgram;
