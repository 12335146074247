import React from "react";

import { Box, createStyles, Grid, makeStyles } from "@material-ui/core";

import AILogo from "components/UI/icons/AILogo";
import OptionsUniversityIcon from "components/UI/icons/OptionsUniversityIcon";
import PageHeader from "components/UI/Typography/PageHeader";
import ToolsCard from "./ToolsCard";
import useIsDesktop from "hooks/useIsDesktop";
import XTwitterLogo from "components/UI/icons/XTwitterLogo";
import IOSAppStoreLink_QRcode from "assets/IOSAppStoreLinkQRcode.jpg";
import AppStoreBlackIcon from "components/UI/icons/AppStoreBlackIcon";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {},
		toolsContainer: {
			display: "flex",
			flexDirection: "column",
			"& > *": {
				marginBottom: 16,
				marginRight: 0,
			},
		},
		header: {
			fontFamily: "Cerebri Sans",
			fontSize: 24,
			lineHeight: "63px",
			fontWeight: 900,
			marginBottom: 20,
			marginTop: 83,
		},
		qrCode: {
			backgroundImage: `url(${IOSAppStoreLink_QRcode})`,
			backgroundSize: "cover",
			backgroundPosition: "center",
			width: 85,
			height: 85,
			marginRight: 20,
		},
	})
);

const ToolsCTA = React.memo(() => {
	const styles = useStyles();

	const isDesktop = useIsDesktop();

	return (
		<div className={styles.root}>
			<PageHeader value="What's Next" />

			<div className={styles.toolsContainer}>
				<Grid container spacing={isDesktop ? 4 : 2}>
					<Grid item xs={12} md={6}>
						<ToolsCard
							link="https://twitter.com/optionslearn"
							description="Get the latest updates on what we are seeing throughout the day."
							header="Join us on X (Twitter)"
							icon={<XTwitterLogo size={36} />}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<ToolsCard
							link="https://apps.apple.com/app/options-ai/id1668959270"
							descriptionComponent={
								<>
									<Box
										my={1}
										width="100%"
										display={"flex"}
										justifyContent={"center"}
										alignItems={"center"}
									>
										<div className={styles.qrCode} />

										<AppStoreBlackIcon />
									</Box>
								</>
							}
							header="Download the iOS App"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<ToolsCard
							link="https://www.optionsai.com/how-to-use-options-ai/"
							description="Get up to speed quickly with our How to videos."
							header="Watch our how to videos:"
							icon={<AILogo />}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<ToolsCard
							link="https://learn.optionsai.com"
							description="Quickly graduate to level 3 income, leverage and hedging spread strategies with our short straightforward video series. Or dive deeper with our full course."
							header="Options University"
							icon={<OptionsUniversityIcon />}
						/>
					</Grid>
				</Grid>
			</div>
		</div>
	);
});

export default ToolsCTA;
