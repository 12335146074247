import { AnnualIncome, WorthValues } from "core/application/Application.d";

export const INCOME_VALUES = [
  {
    label: "Under $25,000",
    value: AnnualIncome.EXTRA_LOW,
  },
  {
    label: "$25,001 - $50,000",
    value: AnnualIncome.LOW,
  },
  {
    label: "$50,001 - $100,000",
    value: AnnualIncome.MID,
  },
  {
    label: "$100,001 - $200,000",
    value: AnnualIncome.UPPER_MID,
  },
  {
    label: "$200,001 - $300,000",
    value: AnnualIncome.UPPER,
  },
  {
    label: "$300,001 - $500,000",
    value: AnnualIncome.UPPER_WEALTHY,
  },
  {
    label: "$500,001 - $1,200,000",
    value: AnnualIncome.WEALTHY,
  },
  {
    label: "Over $1,200,000",
    value: AnnualIncome.EXTRA_WEALTHY,
  },
];

export const WORTH_VALUES = [
  {
    label: "Under $20,000",
    value: WorthValues.LOWEST,
  },
  {
    label: "$20,001 - $50,000",
    value: WorthValues.EXTRA_LOW,
  },
  {
    label: "$50,001 - $100,000",
    value: WorthValues.LOW,
  },
  {
    label: "$100,001 - $200,000",
    value: WorthValues.MID,
  },
  {
    label: "$200,001 - $500,000",
    value: WorthValues.UPPER_MID,
  },
  {
    label: "$500,001 - $1,000,000",
    value: WorthValues.UPPER,
  },
  {
    label: "$1,000,001 - $5,000,000",
    value: WorthValues.WEALTHY,
  },
  {
    label: "Over $5,000,000",
    value: WorthValues.EXTRA_WEALTHY,
  },
];
