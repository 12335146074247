import React, { FC, useContext } from "react";

import InputMask from "react-input-mask";
import { Box, FormControl, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import ApplicationContext from "stores/application/ApplicationRoot";
import "./css/phone.css";
import TextfieldWithValidation from "components/UI/TextField/TextfieldWithValidation";

const MASK = "(999) 999-9999";

interface IPhoneProps {}

const Phone: FC<IPhoneProps> = () => {
	const { phone, isSubscriber } = useContext(ApplicationContext);

	const onPhoneNumberChange = (e: any) => {
		phone.number.set(e.target.value);
	};

	const onSubscriberPhoneChange = (value = "") => {
		phone.number.set(value);
	};

	if (isSubscriber) {
		return (
			<>
				<Box width="100%" display="flex" alignItems="center">
					<PhoneInput
						defaultCountry="US"
						placeholder="Enter phone number"
						value={phone.number.value}
						onChange={onSubscriberPhoneChange}
						error={
							phone.number.value
								? isValidPhoneNumber(phone.number.value)
									? undefined
									: "Invalid phone number"
								: "Phone number required"
						}
					/>
				</Box>

				{phone.number.errMsg &&
					!isValidPhoneNumber(phone.number.value || "") && (
						<Typography color="error">
							Please enter a valid phone number.
						</Typography>
					)}
			</>
		);
	}

	return (
		<FormControl fullWidth={true}>
			<InputMask
				alwaysShowMask={true}
				mask={MASK}
				value={phone.number.value}
				onChange={onPhoneNumberChange}
			>
				{(inputProps: any) => (
					<TextfieldWithValidation
						{...inputProps}
						type="tel"
						valid={
							!phone.pageinValid && !phone.number.errMsg && !!phone.number.value
						}
						useRawEvent
						error={phone.number.errMsg}
					/>
				)}
			</InputMask>
		</FormControl>
	);
};

export default observer(Phone);
