import { action, observable } from "mobx";

import Validator from "core/validations";

/**
 * class is responsible for getting/setting values and error messages for a particular input
 */
class FormField<T> {
	private VALIDATION_DELAY_MS = 376;

	@observable public value?: T;
	@observable public errMsg: string;
	@observable public error: boolean;

	private validationTimeout: any;

	private validators: string[] = [];
	private customErrors = {};

	constructor(
		value?: T,
		validators: string[] = [],
		errorMessages: any = {},
		validationDelayMS?: number
	) {
		this.value = value;
		this.errMsg = "";
		this.validators = validators;
		this.customErrors = errorMessages;
		this.validationTimeout = undefined;
		this.error = false;
		this.VALIDATION_DELAY_MS = validationDelayMS || 376;
	}

	// set value
	// clear error message
	// start validations
	@action public set = (value: T) => {
		this.value = value;
		this.errMsg = "";
		this.error = false;
		this.runValidationsInstant();
		this.runValidations();
	};

	// clear timeout to throttle and delay showing error until user has
	// finished typing in the input
	private runValidations = () => {
		const self = this;
		clearTimeout(this.validationTimeout);

		this.validationTimeout = setTimeout(() => {
			if (this.validators.length < 1) {
				return (this.errMsg = "");
			}

			let value = this.value;

			if (typeof value === "string") {
				value = value.trim() as any as T;
			}

			const validator = new Validator(
				{ value },
				{ value: this.validators },
				this.customErrors
			);

			validator.passes(function () {
				// Validation passed
				self.errMsg = "";
			});

			validator.fails(function () {
				self.errMsg = validator.errors.get("value").join(" ");
			});
		}, this.VALIDATION_DELAY_MS);
	};

	private runValidationsInstant = () => {
		const self = this;

		if (this.validators.length < 1) {
			return (this.error = false);
		}

		let value = this.value;

		if (typeof value === "string") {
			value = value.trim() as any as T;
		}

		const validator = new Validator(
			{ value },
			{ value: this.validators },
			this.customErrors
		);

		validator.passes(function () {
			// Validation passed
			self.error = false;
		});

		validator.fails(function () {
			self.error = true;
		});
	};
}

export default FormField;
