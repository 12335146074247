import React, { FC, Fragment } from "react";
import { Dialog, Typography, Box } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface IInfoPanelProps
  extends RouteComponentProps<{}, {}, { returnUrl?: string; from?: boolean }> {
  open?: boolean;
  header?: string;
  description?: string;
}

const InfoPanel: FC<IInfoPanelProps> = ({
  open,
  header,
  description,
  history,
}) => {
  if (!open) {
    return <Fragment />;
  }

  const handleClose = () => {
    history.replace("#");
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box px={5} py={6}>
        <Typography gutterBottom={true} variant="h5">
          {header}
        </Typography>
        <Typography style={{ whiteSpace: "pre-line" }} variant="body2">
          {description}
        </Typography>
      </Box>
    </Dialog>
  );
};

export default withRouter(InfoPanel);
