import APP_PATHS from "./appPaths";

export const FieldRoute = {
	FIRST_LAST: APP_PATHS.basicInfo,
	PHONE: APP_PATHS.basicInfo,
	ADDRESS: APP_PATHS.basicInfo,

	DOB: APP_PATHS.identity,
	SSN: APP_PATHS.identity,
	CITIZENSHIP: APP_PATHS.identity,
	TAX_STATUS: APP_PATHS.marital,

	FINANCIAL: APP_PATHS.financialProfile,
	KNOWLEDGE: APP_PATHS.investmentKnowledge,
	GOALS: APP_PATHS.investmentGoals,
	HORIZON_LIQUIDITY: APP_PATHS.tradingProfile,
	POLITICAL_EXPOSURE: APP_PATHS.affiliations,
	CONTROL_PERSON: APP_PATHS.affiliations,
	AFFILIATION: APP_PATHS.affiliations,
	EMPLOYMENT: APP_PATHS.employment,

	SWEEP_PROGRAM: APP_PATHS.sweepProgram,
	TRUSTED_PERSON: APP_PATHS.trustedContact,
	AGREEMENT: APP_PATHS.agree,
	REVIEW: APP_PATHS.review,
	SIGNATURE: APP_PATHS.signature,
	COMPLETE: APP_PATHS.status,
};
