import { action, observable } from "mobx";
import { createContext } from "react";
import { sortCountriesByName } from "utils/sortCountriesByName";

interface ICountryItem {
  code: string;
  name: string;
}

class Countries {
  @observable
  public value: ICountryItem[] = [];

  @action
  public set = (countries: ICountryItem[]) => {
    if (countries.length < 1) {
      this.value = [];
      return;
    }
    const sorted = sortCountriesByName(countries);
    this.value = sorted;
  };
}

const CountriesContext = createContext(new Countries());

export default CountriesContext;
