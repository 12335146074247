import {
  CircularProgress,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "33px",
      color: "#828282",
      marginBottom: 26,
    },
    button: {
      color: theme.palette.primary.main,
      cursor: "pointer",
      display: "inline-flex",
      alignItems: "center",
    },
    loading: {
      marginLeft: 8,
    },
    success: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: "18px",
      fontFamily: "Cerebri Sans",
      color: theme.palette.primary.main,
    },
    error: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: "18px",
      fontFamily: "Cerebri Sans",
      color: theme.palette.error.main,
    },
  })
);

interface Params {
  onResend?: () => void;
  loading?: boolean;
  error?: boolean;
  success?: boolean;
}

const ResendEmailVerification = React.memo(
  ({ error, loading, onResend, success }: Params) => {
    const styles = useStyles();

    return (
      <>
        <Typography className={styles.root}>
          If you can not to see an email from Options AI, check your junk inbox
          and/or{" "}
          <span onClick={onResend} className={styles.button}>
            send email again{" "}
            {!!loading && (
              <CircularProgress className={styles.loading} size={12} />
            )}
          </span>
        </Typography>

        {!!success && (
          <Typography className={styles.success}>
            Email re-sent successfully!
          </Typography>
        )}

        {!!error && (
          <Typography className={styles.error}>
            An error occured while resending email, please try again.
          </Typography>
        )}
      </>
    );
  }
);

export default ResendEmailVerification;
