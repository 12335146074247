const TRUSTED_CONTACT_COPY = {
  header: `Trusted Contact Person`,
  description: `Would you like to add a trusted person?`,
  infopanel: {
    p1: `A trusted contact person must be age 18 or older and based in the United States. In the unlikely scenario that we are unable to contact you for an extended period of time, via both email and/or physical mail, we may reach out to your trusted contact to help get in touch with you.`,

    p2: `In the event that there is a reasonable belief that you are victim to financial exploitation, the trusted contact can place a temporary hold on your account to prevent any disbursements of funds.`,
    listHeader: `It is important to note that a trusted contact:`,
    list: [
      `cannot take any action on your account`,
      `cannot access any of your account details`,
      `is not considered to be a beneficiary`,
    ],
  },
};

export default TRUSTED_CONTACT_COPY;
