import React, { FC, useContext, useMemo, useState } from "react";

import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react-lite";

import SectionHeader from "components/UI/Typography/SectionHeader";
import ApplicationFormLayout from "containers/Layout/ApplicationFormLayout";
import LegalName from "containers/new/LegalName";
import AddressForm from "containers/new/Address";
import Phone from "containers/new/Phone";
import ApplicationContext from "stores/application/ApplicationRoot";
import APP_PATHS from "../appPaths";
import BasicInfoPanel from "containers/Steps/BasicInfoPanel";
import useIsDesktop from "hooks/useIsDesktop";

const useStyles = makeStyles(() =>
	createStyles({
		nameDescription: {
			fontWeight: 400,
			fontSize: 12,
			lineHeight: "16px",
			color: "#828282",
			marginBottom: 15,
		},
		formChildrenContainer: {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			"@media (max-width: 850px)": {
				display: "block",
				marginTop: "40px",
			},
		},
	})
);

const BasicInfoNew: FC<RouteComponentProps<{}, {}, any>> = observer(
	({ history, location }) => {
		const styles = useStyles();
		const application = useContext(ApplicationContext);
		const { addresses, isSubscriber } = application;


		console.log('application type', application.applicationType);
		console.log('is subscriber', isSubscriber);

		const onNextDisabled = useMemo(() => {
			return (
				addresses.addressesInvalid ||
				application.name.pageInvalid ||
				application.loading
			);
		}, [
			addresses.addressesInvalid,
			application.loading,
			application.name.pageInvalid,
		]);

		const [returnUrl] = useState(location.state?.returnUrl);

		const onNext = async () => {
			if (returnUrl === "/review") {
				await application.submitStepOne();

				location.state.returnUrl = undefined;

				history.replace("/review");
				return;
			}

			if (application.isSubscriber) {
				history.push(APP_PATHS.employment, { from: true });
				return;
			}

			history.push(APP_PATHS.identity, { from: true });
		};

		const isDesktop = useIsDesktop();

		return (
			<ApplicationFormLayout
				step={2}
				isSubscriber={isSubscriber}
				loading={application.loading}
				onBackDisabled={!!isSubscriber}
				continueText={isSubscriber ? "Next: Investor Status" : "Next: Verify Identity"}
				onNext={onNext}
				onNextDisabled={onNextDisabled}
				leftPanelChildren={<BasicInfoPanel isSubscriber={isSubscriber} />}
				formChildren={
					<div className={styles.formChildrenContainer}>
						<Box width={"100%"}>
							<Box marginTop="36px">
								{isDesktop ? (
									<>
										<SectionHeader marginBottom={0} value="Your name" />
										<Typography className={styles.nameDescription}>
											{application.isSubscriber
												? `Please enter your legal name.`
												: `Please enter your full legal name as it appears on your government
										issued ID`}
										</Typography>{" "}
									</>
								) : null}
							</Box>

							<LegalName />

							<Box marginTop="24px">
								<SectionHeader value="Phone Number" />

								<Phone />
							</Box>

							<Box marginTop="24px">
								<SectionHeader value="Address" />

								<AddressForm
									id="home-address"
									hasMailingAddress={!application.isSubscriber}
									address={application.addresses.residential}
								/>
							</Box>

							{Boolean(
								addresses.useMailingAddress.value && !application.isSubscriber
							) && (
									<Box marginTop="38px">
										<SectionHeader value="Mailing Address" />

										<AddressForm
											id="mailing-address"
											address={application.addresses.mailing}
										/>
									</Box>
								)}
						</Box>
					</div>
				}
			/>
		);
	}
);

export default BasicInfoNew;
