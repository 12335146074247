import React, { FC, useState, useEffect, useContext } from "react";

import { observer } from "mobx-react-lite";
import { RouteComponentProps, Redirect } from "react-router-dom";

import DefaultLayout from "./DefaultLayout";
import ApplicationContext, {
	LoadingState,
} from "stores/application/ApplicationRoot";

import APP_PATHS from "./appPaths";
import StepDisplay from "containers/Steps/StepDisplay";

interface IStepsProps
	extends RouteComponentProps<
		{ step: string },
		{},
		{ upload?: boolean; from?: boolean }
	> {}

const Steps: FC<IStepsProps> = ({ match, location, history }) => {
	const activeStep = match.params.step;

	const upload = location.state?.upload;

	const {
		submitStepOne,
		loading = true,
		submitStepTwo,
		submitStepThree,
		error,
	} = useContext(ApplicationContext);

	const [pageState, setPageState] = useState(LoadingState.UNKNOWN);

	const [secondaryRoute, setSecondaryRoute] = useState("/");

	const [nextRoute, setNextRoute] = useState("");

	useEffect(() => {
		if (!upload) {
			setPageState(LoadingState.FALSE);
			return;
		}

		if (activeStep === "2") {
			console.log("submitting step");
			submitStepOne();
		}

		if (activeStep === "3") {
			submitStepTwo();
		}

		if (activeStep === "welcome") {
			submitStepThree();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [upload]);

	useEffect(() => {
		if (activeStep === "1") {
			window.document.title = `OPTIONS.AI - Application - Step 1`;

			setNextRoute(APP_PATHS.basicInfo);
		}

		if (activeStep === "2") {
			window.document.title = `OPTIONS.AI - Application - Step 2`;

			setSecondaryRoute(APP_PATHS.marital);
			setNextRoute(APP_PATHS.investmentKnowledge);
		}

		if (activeStep === "3") {
			window.document.title = `OPTIONS.AI - Application - Step 3`;

			setSecondaryRoute(APP_PATHS.employment);
			setNextRoute(APP_PATHS.sweepProgram);
		}

		if (activeStep === "welcome") {
			setNextRoute(APP_PATHS.status);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (loading) {
			setPageState(LoadingState.TRUE);
			return;
		}

		if (!loading) {
			setPageState(LoadingState.FALSE);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	const handleContinue = () => {
		history.push(nextRoute, { from: true });
	};

	if (error) {
		return <Redirect to={APP_PATHS.error500} />;
	}

	const processing =
		pageState === LoadingState.UNKNOWN ||
		pageState === LoadingState.TRUE ||
		loading;

	if (nextRoute === APP_PATHS.status && !processing) {
		return <Redirect to={APP_PATHS.status} />;
	}

	return (
		<>
			<DefaultLayout
				onBackDisabled={activeStep === "welcome" || activeStep === "1"}
				hasContinue={false}
				onNext={handleContinue}
				onNextDisabled={pageState !== LoadingState.FALSE}
				secondaryRoute={secondaryRoute}
			>
				<StepDisplay
					loading={processing}
					activeStep={activeStep}
					onNext={handleContinue}
				/>
			</DefaultLayout>
		</>
	);
};

export default observer(Steps);
