import { computed, observable } from "mobx";

import { Application } from "stores/application/ApplicationRoot";
import FormField from "../helpers/FormField";

class Signature {
  public rootStore: Application;

  // Signature
  @observable public field: FormField<string> = new FormField("");

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @computed
  public get pageInvalid() {
    return !this.field.value;
  }

  public putRequest() {
    const { field } = this;

    const { apiClient } = this.rootStore;

    if (!apiClient) {
      throw new Error("Could not connect to services.");
    }

    return apiClient.signApplication(field.value!);
  }
}

export default Signature;
