import React, { FC, useContext, useEffect } from "react";

import { Typography, Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import ApplicationContext from "stores/application/ApplicationRoot";
import SignatureForm from "containers/new/Signature";
import PageHeader from "components/UI/Typography/PageHeader";
import ApplicationFormLayout from "containers/Layout/ApplicationFormLayout";
import ReviewPanel from "containers/Steps/ReviewPanel";
import SectionDescription from "components/UI/Typography/SectionDescription";
import useIsDesktop from "hooks/useIsDesktop";

const useStyles = makeStyles((theme) => ({
	text: {
		fontWeight: 700,
		fontSize: "13px",
		marginBottom: 10,
		[theme.breakpoints.down("md")]: {
			fontSize: "11px",
		},
		"@media (max-width: 850px)": {
			fontSize: "12px",
		},
	},
	smallText: {
		fontSize: "13px",
		[theme.breakpoints.down("md")]: {
			fontSize: "11px",
		},
	},
}));

interface IProps
	extends RouteComponentProps<
		{},
		{},
		{ returnUrl?: string; from?: boolean; upload?: true }
	> {}

const SignaturePage: FC<IProps> = ({ history }) => {
	useEffect(() => {
		window.scrollTo({ top: 0 });
	}, []);

	const styles = useStyles();

	const { signature } = useContext(ApplicationContext);

	const handleNext = () => {
		history.push("/steps/welcome", { from: true, upload: true });
	};

	const isDesktop = useIsDesktop("md");

	return (
		<>
			<ApplicationFormLayout
				step={4}
				continueText="Submit Application"
				onNext={handleNext}
				onNextDisabled={signature.pageInvalid}
				leftPanelChildren={<ReviewPanel />}
				formChildren={
					<>
						<PageHeader
							gutterBottom={isDesktop ? undefined : 8}
							value="Your Signature"
						/>

						<Box pb={isDesktop ? 4 : 2}>
							<Typography className={styles.smallText} variant="body2">
								{TYPOGRAPHY.statement1}
							</Typography>
						</Box>

						{TYPOGRAPHY.bodyStatements.map((statement) => (
							<Typography className={styles.text} key={statement}>
								{statement}
							</Typography>
						))}

						<Box className={styles.smallText} pt={isDesktop ? 2 : 0.5} pb={2}>
							{TYPOGRAPHY.statement2}
						</Box>

						<SectionDescription value={TYPOGRAPHY.footer} />

						<SignatureForm />
					</>
				}
			/>
		</>
	);
};
const statements = [
	`1. All information that I have provided in this application is current and accurate`,
	`2. I will promptly notify you of any change in the information provided in this application`,
	`3. I am not a securities professional and meet the definition of ‘Non-Professional Subscriber’ as defined in the data agreements`,
	`4. I have carefully read, understood and retained copies of each of the above documents and I agree to all of the terms and provisions contained therein,
     including the predispute arbitration clause wherein I agree in advance to arbitrate any controversies which may arise between myself and Options AI`,
];

const TYPOGRAPHY = {
	header: "Your Signature",
	statement1: `By signing below and tapping or clicking "Submit Application", I agree to the following:`,
	bodyStatements: statements,
	statement2: `I understand that placing my electronic signature below and tapping or clicking "Submit Application" is the legally binding equivalent of my written signature and may be applied to each of the agreements where applicable`,
	footer: `Sign in the space provided below`,
};

export default observer(SignaturePage);
