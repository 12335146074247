import React from "react";

const OptionsUniversityIcon = React.memo(() => {
	return (
		<svg
			width="36"
			height="36"
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="36" height="36" rx="18" fill="#F2994A" />
			<path
				d="M24.7742 17.0745C25.3917 17.4675 25.3917 18.3689 24.7742 18.7619L15.0369 24.9584C14.3711 25.382 13.5 24.9038 13.5 24.1147L13.5 11.7217C13.5 10.9326 14.3711 10.4544 15.0369 10.878L24.7742 17.0745Z"
				fill="white"
			/>
		</svg>
	);
});

export default OptionsUniversityIcon;
