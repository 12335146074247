import { action, computed, observable } from "mobx";

import { EmploymentStatus, IAddress } from "core/application/Application.d";
import { Application } from "stores/application/ApplicationRoot";
import FormField from "../helpers/FormField";
import Address from "stores/helpers/Address";
import { IEmploymentRequestPayload } from "core/api/types/request/request";

interface IEmployment {
	employmentStatus: string;
	employment?: {
		employerName: string;
		position: string;
		employerAddress: string;
		occupation: string;
		jobFunction: string;
	};
	unemployed?: {
		description: string;
	};
}

class Employment {
	@observable
	public addressComponents = new Address();

	@action
	public setAddress = (address: IAddress) => {
		this.addressComponents.streetAddress.set(address.streetAddress);
		this.addressComponents.streetAddress2.set(address.streetAddress2 || "");
		this.addressComponents.city.set(address.city);
		this.addressComponents.state.set(address.state);
		this.addressComponents.zipCode.set(address.zipCode);
	};

	@computed
	public get pageInvalid() {
		const { position, occupation, jobFunction, employerAddress, rootStore } =
			this;

		if (rootStore.isSubscriber && this.employmentStatus.value) {
			if (
				this.employmentStatus.value === EmploymentStatus.EMPLOYED &&
				!(
					position.value &&
					!position.error &&
					!this.addressComponents.name.error &&
					this.addressComponents.name.value &&
					occupation.value &&
					!occupation.error &&
					jobFunction.value &&
					!jobFunction.error &&
					employerAddress
				)
			) {
				return true;
			}

			if (
				this.employmentStatus.value === EmploymentStatus.SELFEMPLOYED &&
				!(
					position.value &&
					!position.error &&
					this.addressComponents.name.value &&
					occupation.value &&
					!occupation.error &&
					jobFunction.value &&
					!jobFunction.error &&
					employerAddress
				)
			) {
				return true;
			}

			return false;
		}

		if (
			!this.employmentStatus.value &&
			this.employmentStatus.value !== EmploymentStatus.EMPLOYED
		) {
			return true;
		}

		if (
			this.employmentStatus.value === EmploymentStatus.EMPLOYED &&
			!(
				position.value &&
				!position.error &&
				!this.addressComponents.name.error &&
				this.addressComponents.name.value &&
				occupation.value &&
				!occupation.error &&
				jobFunction.value &&
				!jobFunction.error &&
				employerAddress
			)
		) {
			return true;
		}

		if (
			this.employmentStatus.value === EmploymentStatus.SELFEMPLOYED &&
			!(
				position.value &&
				!position.error &&
				this.addressComponents.name.value &&
				occupation.value &&
				!occupation.error &&
				jobFunction.value &&
				!jobFunction.error &&
				employerAddress
			)
		) {
			return true;
		}

		const needsDescription =
			this.employmentStatus.value === EmploymentStatus.UNEMPLOYED ||
			this.employmentStatus.value === EmploymentStatus.STUDENT ||
			this.employmentStatus.value === EmploymentStatus.RETIRED;

		if (
			needsDescription &&
			(!this.unEmployedDescription.value || this.unEmployedDescription.error)
		) {
			return true;
		}

		return false;
	}
	public rootStore: Application;

	@observable
	public employmentStatus = new FormField<EmploymentStatus>(
		undefined,
		["required"],
		{ required: "Please select your employment status." }
	);

	@observable
	public position = new FormField("", ["min:2", "required"], {
		required: "This field is required",
		min: "What is your current position?",
	});

	@observable
	public occupation = new FormField("", ["min:2", "required"], {
		min: "What is your occupation?",
		required: "This field is required",
	});

	@computed
	public get employerAddress() {
		if (!this.addressComponents.streetAddress.value) {
			return undefined;
		}

		const { streetAddress, streetAddress2, city, state, zipCode } =
			this.addressComponents;

		const combined = `${streetAddress.value} ${streetAddress2.value} ${
			city.value ? `${city.value},` : ""
		} ${state.value} ${zipCode.value}`;

		return combined.replace("  ", " ").trim();
	}

	@observable
	public jobFunction = new FormField("", ["min:2", "required"], {
		min: "Enter your job functions at your current position.",
		required: "This field is required",
	});

	@observable
	public unEmployedDescription = new FormField("", ["min:3", "required"], {
		min: "How you will be funding your account?",
		required: "This field is required. ",
	});

	@observable
	public marital = new FormField<string>("");

	constructor(rootStore: any) {
		this.rootStore = rootStore;

		this.addressComponents.setNameRequired();
	}

	@action
	public set = (payload: IEmployment) => {
		this.employmentStatus.set(payload.employmentStatus as EmploymentStatus);

		console.log("in here........");

		if (
			(payload.employmentStatus === EmploymentStatus.RETIRED ||
				payload.employmentStatus === EmploymentStatus.STUDENT ||
				payload.employmentStatus === EmploymentStatus.UNEMPLOYED) &&
			payload.unemployed
		) {
			this.unEmployedDescription.set(payload.unemployed!.description);
			return;
		}

		const { employment } = payload;

		if (employment) {
			console.log(employment);
			console.log(employment.employerName);
			console.log(employment.employerAddress);

			this.addressComponents.name.set(employment.employerName);

			this.addressComponents.streetAddress.set(employment.employerAddress);

			this.occupation.set(employment.occupation);

			this.jobFunction.set(employment.jobFunction);

			this.position.set(employment.position);
		}
	};

	public putRequest() {
		const {
			employmentStatus,
			occupation,
			position,
			jobFunction,
			employerAddress,
			unEmployedDescription,
			addressComponents,
		} = this;

		const { apiClient } = this.rootStore;

		if (!apiClient) {
			throw new Error("Could not connect to services.");
		}

		let payload: IEmploymentRequestPayload = {
			employmentStatus: employmentStatus.value!,
			employment: {
				employerName: addressComponents.name.value!,
				position: position.value!,
				occupation: occupation.value!,
				jobFunction: jobFunction.value!,
				employerAddress: employerAddress!,
			},
			unemployed: {
				description: unEmployedDescription.value!,
			},
		};

		if (
			payload.employmentStatus === EmploymentStatus.EMPLOYED ||
			payload.employmentStatus === EmploymentStatus.SELFEMPLOYED
		) {
			delete payload.unemployed;
		}

		if (
			payload.employmentStatus !== EmploymentStatus.EMPLOYED &&
			payload.employmentStatus !== EmploymentStatus.SELFEMPLOYED
		) {
			delete payload.employment;
		}

		return payload;
	}
}

export default Employment;
