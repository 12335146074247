import { action, computed, observable } from "mobx";

import {
  LiquidityNeeds,
  RiskTolerance,
  TimeHorizon,
} from "core/application/Application";
import { Application } from "stores/application/ApplicationRoot";
import FormField from "../helpers/FormField";

interface ISuitability {
  liquidityNeeds: string;
  riskTolerance: string;
  timeHorizon: string;
}

class InvestmentPreferences {
  public rootStore: Application;

  @observable
  public liquidityNeeds = new FormField<LiquidityNeeds>();

  @observable
  public riskTolerance = new FormField<RiskTolerance>();

  @observable
  public timeHorizon = new FormField<TimeHorizon>();

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @computed
  public get pageInvalid() {
    const { timeHorizon, liquidityNeeds, riskTolerance } = this;

    return !timeHorizon.value || !liquidityNeeds.value || !riskTolerance.value;
  }

  @action
  public set = (payload: ISuitability) => {
    this.liquidityNeeds.set(payload.liquidityNeeds as LiquidityNeeds);
    this.riskTolerance.set(payload.riskTolerance as RiskTolerance);
    this.timeHorizon.set(payload.timeHorizon as TimeHorizon);
  };

  public putRequest() {
    const { liquidityNeeds, timeHorizon, riskTolerance } = this;

    const { apiClient } = this.rootStore;

    if (!apiClient) {
      throw new Error("Could not connect to services.");
    }

    return {
      liquidityNeeds: liquidityNeeds.value!,
      timeHorizon: timeHorizon.value!,
      riskTolerance: riskTolerance.value!,
    };
  }
}

export default InvestmentPreferences;
