import React, { FC } from "react";

import { Grow, StandardTextFieldProps, TextField } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { Theme, makeStyles } from "@material-ui/core/styles";

interface StyleProps {
	selectError: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>({
	validationBox: {
		minWidth: 22,
		minHeight: 22,
		borderRadius: "50%",
		backgroundColor: "#71B691",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		marginLeft: 25,
		"@media (max-width: 850px)": {
			marginLeft: 10,
		},
	},
	container: {
		display: "flex",
		alignItems: "center",
	},
	icon: {
		width: 15,
		height: 15,
		fill: "white",
	},
	error: {
		marginBottom: (props) => (props.selectError ? 0 : 25),
		// marginTop: (props) => (props.Action ? 0 : -12.5),
		marginTop: (props) => (props.selectError ? 0 : -12.5),
		fontSize: 13,
		fontWeight: 500,
		width: "100%",
		flex: 1,
		display: "block",
	},
	root: {
		"& .MuiInputBase-input::placeholder": {
			color: "grey",
		},
		"& .MuiInputBase-input:focus::placeholder": {
			color: "grey",
		},
	},
});

interface Params extends Omit<StandardTextFieldProps, "error"> {
	valid?: boolean;
	showValidation?: boolean;
	error?: string | boolean | null;
	hideErrorMessage?: boolean;
	customField?: JSX.Element;
	selectError?: boolean;
}

const TextfieldWithValidation: FC<Params> = React.memo((props) => {
	const { valid, showValidation = true } = props;

	const classes = useStyles({ selectError: !!props.selectError });

	return (
		<>
			<div className={classes.container}>
				{props.customField ? (
					props.customField
				) : (
					<TextField
						className={classes.root}
						fullWidth
						{...props}
						error={!!props.error}
						helperText={
							props.error?.valueOf() === "true"
								? undefined
								: props.error || props.helperText
						}
					/>
				)}

				{showValidation ? (
					<Grow in={valid && showValidation}>
						<div className={classes.validationBox}>
							<Check className={classes.icon} />
						</div>
					</Grow>
				) : null}
			</div>
		</>
	);
});

export default TextfieldWithValidation;
