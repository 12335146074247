import FormField from "stores/helpers/FormField";
import { action, observable } from "mobx";

const errorMessages = {
	name: "Please provide a valid name. If you are self employed, you may use your business or first and last name.",
	street: "Please provide a valid street address.",
	city: "Please provide a valid city.",
	state: "Please select a state.",
	zipCode: "Please enter a valid zip code.",
};

class Address {
	@observable
	public name = new FormField<string>("", ["min:2"], {
		min: errorMessages.name,
	});

	public streetAddress = new FormField<string>(
		"",
		["min:4", "pobox", "required", "max:30"],
		{
			min: errorMessages.street,
			required: "Street address is required.",
			max: "Split address into two lines if over 30 characters.",
		}
	);

	public streetAddress2 = new FormField<string>("", ["pobox", "max:30"], {
		max: "This field only allows 30 characters.",
	});

	public city = new FormField<string>("", ["required"], {
		min: errorMessages.city,
		required: "City is required.",
	});

	public state = new FormField<string>("", ["required"], {
		min: errorMessages.state,
		required: "State is required.",
	});

	public country = new FormField<string>("", ["required"], {
		required: "Country is required.",
	});

	public zipCode = new FormField<string>("", ["required"], {
		min: errorMessages.zipCode,
		required: "Zip code is required.",
	});

	@action
	public setNameRequired = () => {
		this.name = new FormField<string>("", ["min:2", "required"], {
			min: errorMessages.name,
			required:
				"This field is required. If you are self employed, you may use your business or first and last name.",
		});
	};
}

export default Address;
