import React, { FC, useEffect } from "react";
import { useState } from "react";
import ControlPerson from "stores/application/ControlPerson";
import { FormControl, Typography, Box, Button } from "@material-ui/core";
import TextField from "components/UI/TextField/TextField";
import { observer } from "mobx-react-lite";

interface IControlPersonDetailsProps {
  initialDescription?: string;
  onSave: (...params: any) => void;
  handleClose: () => void;
}

const ControlPersonDetails: FC<IControlPersonDetailsProps> = ({
  initialDescription = "",
  onSave,
  handleClose,
}) => {
  const [controlPerson] = useState(new ControlPerson(undefined));

  const setDescription = (value: string) => {
    controlPerson.description.set(value);
  };

  const { description } = controlPerson;

  const handleSave = () => {
    onSave(description.value);
  };

  useEffect(() => {
    !!initialDescription && setDescription(initialDescription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormControl margin="normal" fullWidth={true}>
        <Typography variant="body1" gutterBottom={true}>
          {pageContent.namesOfRelated}
        </Typography>

        <TextField
          value={description.value}
          onChange={setDescription}
          fullWidth={true}
          multiline={true}
          label="Companies"
          error={description.errMsg}
        />
      </FormControl>

      <Box pt={2} display="flex" justifyContent="flex-end">
        <Button
          onClick={handleClose}
          style={{ color: "#8B8B8B" }}
          variant="text"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          disableElevation={true}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </Box>
    </>
  );
};

const pageContent = {
  header: "Control Person",
  description: `Is the account holder a control person of a publicly traded company?
  (Director, Officer, or 10% Stock Holder).`,
  infoPanel: `If you're not sure, then you probably are not a control person.`,
  namesOfRelated: `Please provide the names of relevant companies or stock tickers`,
};

export default observer(ControlPersonDetails);
