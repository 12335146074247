import { MaritalStatus } from "../Application.d";

const readableMaritalStatus = (status?: MaritalStatus) => {
  switch (status) {
    case MaritalStatus.Divorced: {
      return "Divorced";
    }

    case MaritalStatus.Married: {
      return "Married";
    }

    case MaritalStatus.Single: {
      return "Single";
    }

    case MaritalStatus.Widowed: {
      return "Widowed";
    }

    default:
      return undefined;
  }
};

export { readableMaritalStatus };
