import { createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: 25,
      fontSize: 13,
      fontWeight: 500,
      width: "100%",
      flex: 1,
      display: "block",
      color: theme.palette.error.main,
    },
  })
);

interface Params {
  value?: string;
}

const ErrorField = React.memo(({ value }: Params) => {
  const styles = useStyles();

  return <Typography className={styles.root}>{value}</Typography>;
});

export default ErrorField;
