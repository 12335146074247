import {
	AnnualIncome,
	LiquidityNeeds,
	RiskTolerance,
	TimeHorizon,
	WorthValues,
} from "core/application/Application.d";

export const LIQUIDITY_NEEDS_VALUES = [
	{
		label: "Not Important",
		value: LiquidityNeeds.NOT_IMPORTANT,
	},
	{
		label: "Somewhat Important",
		value: LiquidityNeeds.SOMEWHAT_IMPORTANT,
	},
	{
		label: "Very Important",
		value: LiquidityNeeds.VERY_IMPORTANT,
	},
];

export const RISK_TOLERANCE_VALUES = [
	{
		label: "Low",
		value: RiskTolerance.LOW,
	},
	{
		label: "Medium",
		value: RiskTolerance.MEDIUM,
	},
	{
		label: "High",
		value: RiskTolerance.HIGH,
	},
];

export const TIME_HORIZON_VALUES = [
	{
		label: "Short",
		value: TimeHorizon.SHORT,
	},
	{
		label: "Average",
		value: TimeHorizon.AVERAGE,
	},
	{
		label: "Longest",
		value: TimeHorizon.LONGEST,
	},
];

type OneOfEnums =
	| LiquidityNeeds
	| RiskTolerance
	| TimeHorizon
	| AnnualIncome
	| WorthValues;

export function getEnumLabel<T>(
	enumValue: OneOfEnums,
	array: Array<{
		value: OneOfEnums;
		label: string;
	}>
): string {
	const found = array.find((incomeVal) => incomeVal.value === enumValue);

	if (!found) {
		return "";
	}

	return found.label;
}
