import React, { FC, useContext, useMemo, useState } from "react";

import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";

import EmploymentStatusContainer from "containers/new/EmploymentStatus";
import EmploymentRole from "containers/new/EmploymentRole";
import ApplicationContext from "stores/application/ApplicationRoot";
import { EmploymentStatus } from "core/application/Application.d";
import Address from "containers/new/Address";
import SectionHeader from "components/UI/Typography/SectionHeader";
import APP_PATHS from "pages/appPaths";
import ApplicationFormLayout from "containers/Layout/ApplicationFormLayout";
import SuitabilityPanel from "containers/Steps/SuitabilityPanel";
import CompanyName from "containers/new/CompanyName";

interface IEmploymentProps
	extends RouteComponentProps<
		{},
		{},
		{ returnUrl?: string; from?: boolean; upload?: true; }
	> { }

const Employment: FC<IEmploymentProps> = ({ history, location }) => {
	const application = useContext(ApplicationContext);
	const { employment } = application;

	const hasCompanyFields = useMemo(() => {
		return (
			employment.employmentStatus.value === EmploymentStatus.EMPLOYED ||
			employment.employmentStatus.value === EmploymentStatus.SELFEMPLOYED
		);
	}, [employment.employmentStatus.value]);

	const [returnUrl] = useState(location.state?.returnUrl);

	const handleNext = async () => {
		if (returnUrl === APP_PATHS.review) {
			await application.submitStepTwo();

			location.state.returnUrl = undefined;

			history.replace(APP_PATHS.review);
			return;
		}

		if (application.isSubscriber) {
			history.push(APP_PATHS.affiliations, { from: true });
			return;
		}

		history.push("/steps/3", { from: true, upload: true });
	};

	const disabled = employment.pageInvalid || application.loading;

	return (
		<ApplicationFormLayout
			loading={application.loading}
			onNext={handleNext}
			onNextDisabled={disabled}
			noNavButtonMargin
			isSubscriber={application.isSubscriber}
			continueText={application.isSubscriber ? "Next: Non-Professional Investor" : "Next: Review and Agree"}
			step={3}
			leftPanelChildren={<SuitabilityPanel />}
			formChildren={
				<>
					<SectionHeader value="Which of the follow best describes you?" />

					<EmploymentStatusContainer />

					{hasCompanyFields && (
						<>
							<Box pt={2}>
								<SectionHeader value="Role" />

								<EmploymentRole />
							</Box>

							<Box pt={2}>
								<SectionHeader value="Company Details" />
							</Box>

							<CompanyName />

							<Address
								id="employer-address"
								label="Address"
								address={employment.addressComponents}
								initialEmploymentString={
									employment.addressComponents.streetAddress.value
								}
							/>
						</>
					)}
				</>
			}
		/>
	);
};

export default observer(Employment);
