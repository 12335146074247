import Box from "@material-ui/core/Box";
import { Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Check } from "@material-ui/icons";
import React, { FC } from "react";

const useStyles = makeStyles<Theme>((theme) => {
  const size = 48;

  return {
    circle: {
      borderRadius: 25,
      backgroundColor: theme.palette.primary.main,
      animation: "$colorSwap 800ms ease-out 500ms forwards",
      width: size,
      height: size,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    circleLoading: {
      borderRadius: 25,
      backgroundColor: theme.palette.primary.main,
      animationDirection: "alternate",
      animationName: "$colorSwap",
      animationDuration: "1s",
      animationDelay: "800ms",
      animationIterationCount: "infinite",
      width: size,
      height: size,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    circle2: {
      borderRadius: 25,
      backgroundColor: "#fff",
      border: "1px solid #E7E7E7",
      width: size,
      height: size,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    circleNumber: {
      fontSize: 22,
      color: "#fff",
    },
    header: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontWeight: 900,
      fontSize: 36,
      fontFamily: "Cerebri Sans",
    },
    description: {
      fontSize: 12,
    },
    caption: {
      fontSize: 14,
      fontWeight: 600,
      color: "#828282",
      lineHeight: "23px",
      fontFamily: "Cerebri Sans",
      letterSpacing: "1px",
    },
    rightSide: {
      maxWidth: "65%",
      verticalAlign: "top",
      marginLeft: theme.spacing(2.5),
    },
    downArrow: {
      width: 10,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 25,
      animation: "$grow 800ms ease-out 800ms forwards",
      height: 0,
      marginTop: theme.spacing(2),
    },
    leftSide: {
      display: "inline-flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    finishedStep: {
      animation: "$appear 800ms ease-in",
    },

    nextStep: {
      animation: "$appear2 800ms linear 1470ms forwards",
      opacity: 0,
    },

    button: {
      borderRadius: 4,
      minWidth: 137,
      textTransform: "none",
      fontSize: 18,
      lineHeight: "17px",
      letterSpacing: "0.1px",
      fontWeight: 600,
      display: "flex",
      width: "100%",
      padding: "16px",
      margintop: 31,
    },

    "@keyframes appear2": {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },

    "@keyframes appear": {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },

    "@keyframes colorSwap": {
      from: {
        backgroundColor: theme.palette.primary.main,
      },
      to: {
        backgroundColor: theme.palette.secondary.dark,
      },
    },

    "@keyframes grow": {
      "0%": { height: 0, backgroundColor: theme.palette.primary.main },

      "100%": { height: "20vh", backgroundColor: theme.palette.secondary.dark },
    },
  };
});

interface IStepLoaderProps {
  loading?: boolean;
  submittedStep?: JSX.Element;
  completedStep?: JSX.Element;
  nextStep?: JSX.Element;
  nextStepIcon?: JSX.Element;
}

const StepLoader: FC<IStepLoaderProps> = ({
  loading = true,
  submittedStep,
  completedStep,
  nextStep,
  nextStepIcon,
}) => {
  const classes = useStyles();

  if (loading) {
    return (
      <Box className={classes.finishedStep} mt={4}>
        <Box className={classes.leftSide}>
          <Box className={classes.circleLoading}>
            <Typography className={classes.circleNumber}></Typography>
          </Box>
        </Box>

        <Box className={classes.rightSide} display="inline-block">
          {submittedStep}
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.finishedStep} mt={4}>
        <Box className={classes.leftSide}>
          <Box className={classes.circle}>
            <Check fontSize="large" style={{ color: "#fff" }} />
          </Box>

          <Box className={classes.downArrow}></Box>
        </Box>

        <Box className={classes.rightSide} display="inline-block">
          {completedStep}
        </Box>
      </Box>

      <Box className={classes.nextStep} mt={2} minHeight={200}>
        <Box className={classes.leftSide}>
          <Box className={classes.circle2}>{nextStepIcon}</Box>
        </Box>

        <Box className={classes.rightSide} display="inline-block">
          {nextStep}
        </Box>
      </Box>
    </>
  );
};

export default StepLoader;
