import React, { useContext } from "react";

import StepPanelBasic from "components/Application/StepPanelBasic";
import { observer } from "mobx-react-lite";
import ApplicationContext from "stores/application/ApplicationRoot";

const ReviewPanel = observer(() => {
	const application = useContext(ApplicationContext);

	const isSub = application.isSubscriber;

	return (
		<StepPanelBasic
			title={"Take a moment to review."}
			descriptions={[
				isSub ? 'Please ensure that all information is complete and accurate. This will ensure that you’re up and running as quickly as possible.' :

					`It's really important that your information is complete and accurate. This will ensure your application is processed smoothly.`,
				isSub ? '' : `If there are any issues with your application we will be in touch shortly.`,
			]}
		/>
	);
});

export default ReviewPanel;
