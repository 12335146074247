import { computed, observable } from "mobx";

import { Application } from "stores/application/ApplicationRoot";
import FormField from "../helpers/FormField";

class Agreement {
  public rootStore: Application;

  // Signature
  @observable public field: FormField<boolean> = new FormField<boolean>(false, [
    "required",
  ]);

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @computed
  public get pageInvalid() {
    return !this.field.value;
  }

  public putRequest() {
    const { field: agree } = this;

    const { apiClient } = this.rootStore;

    if (!apiClient) {
      throw new Error("Could not connect to services.");
    }

    if (!agree.value) {
      throw new Error("Documents not agreed to");
    }

    return agree.value;
  }
}

export default Agreement;
