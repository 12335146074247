const PREFERENCES_COPY = {
  header: "Investment Profile",
  liquidityNeeds: {
    description: "What are your liquidity needs?",
    infoHeader: "Liquidity Needs",
    info: `The ability to quickly and easily convert all or part of the account into cash without experiencing signifiant loss`,
  },
  timeHorizon: {
    description: "What is your time horizon?",
    infoHeader: "Time Horizon",
    info: ` The number of years planned to invest to achieve a particular financial goal`,
  },
  riskTolerance: {
    description: "What is your risk tolerance?",
    infoHeader: "Risk Tolerance",
    info: `The amount of financial risk are you willing to be exposed to`,
  },
};

export default PREFERENCES_COPY;
