import React, { FC, useContext, useState } from "react";

import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";

import InvestmentKnowledgeForm from "containers/new/InvestmentKnowledgeForm";
import ApplicationContext from "stores/application/ApplicationRoot";
import APP_PATHS from "pages/appPaths";
import ApplicationFormLayout from "containers/Layout/ApplicationFormLayout";
import SuitabilityPanel from "containers/Steps/SuitabilityPanel";
import SectionHeader from "components/UI/Typography/SectionHeader";

interface IInvestmentKnowledgeProps
	extends RouteComponentProps<{}, {}, { returnUrl?: string; from?: boolean }> {}

const InvestmentKnowledgeNew: FC<IInvestmentKnowledgeProps> = ({
	history,
	location,
}) => {
	const application = useContext(ApplicationContext);

	const { investmentProfile } = application;

	const nextDisabled =
		!investmentProfile.optionsExperience.value ||
		!investmentProfile.stocksExperience.value ||
		application.loading;

	const [returnUrl] = useState(location.state?.returnUrl);

	const handleNext = async () => {
		if (returnUrl === APP_PATHS.review) {
			await application.submitStepTwo();

			location.state.returnUrl = undefined;

			history.replace(APP_PATHS.review);
			return;
		}

		history.push("/investment-goals", { from: true });
	};

	return (
		<>
			<ApplicationFormLayout
				step={3}
				loading={application.loading}
				onNext={handleNext}
				onNextDisabled={nextDisabled}
				continueText="Next: Investment Goals"
				formChildren={
					<>
						<SectionHeader marginBottom={40} value="Investment Knowledge" />

						<InvestmentKnowledgeForm />
					</>
				}
				leftPanelChildren={<SuitabilityPanel />}
			/>
		</>
	);
};

export default observer(InvestmentKnowledgeNew);
