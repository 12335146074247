import { action, computed, observable } from "mobx";
import FormField from "stores/helpers/FormField";

import { Application } from "stores/application/ApplicationRoot";
import Address from "../helpers/Address";
import { IAddressRequestPayload } from "core/api/types/request/request";

export interface IAddress {
  streetAddress: string;
  streetAddress2?: string;
  city: string;
  state: string;
  zipCode: string;
  country?: string;
  province?: string;
}

class Addresses {
  @computed public get addressesInvalid(): boolean {
    const primaryAddress =
      this.residential.name.error ||
      !this.residential.streetAddress.value ||
      this.residential.streetAddress.error ||
      !this.residential.city.value ||
      !this.residential.zipCode.value ||
      this.residential.zipCode.error ||
      !this.residential.state.value ||
      (!!this.isEmployerSearch && !this.residential.name.value);

    if (this.useMailingAddress.value) {
      return (
        !this.mailing.streetAddress.value ||
        this.mailing.streetAddress.error ||
        !this.mailing.city.value ||
        !this.mailing.zipCode.value ||
        !this.mailing.state.value ||
        this.mailing.zipCode.error ||
        primaryAddress
      );
    }

    return primaryAddress;
  }
  public rootStore: Application;

  @observable
  public mailing = new Address();

  @observable
  public residential = new Address();

  @observable
  public useMailingAddress = new FormField<boolean>(false);

  @observable
  public isEmployerSearch?: boolean;

  constructor(
    rootStore?: any,
    initialResidential?: IAddress,
    isEmployerSearch = false
  ) {
    this.rootStore = rootStore;
    this.isEmployerSearch = isEmployerSearch;
  }

  @action
  public setMailing = (payload: IAddress) => {
    this.useMailingAddress.set(true);
    this.mailing.streetAddress.set(payload.streetAddress);
    this.mailing.streetAddress2.set(payload.streetAddress2 || "");
    this.mailing.city.set(payload.city);
    this.mailing.state.set(payload.state);
    this.mailing.zipCode.set(payload.zipCode);
  };

  @action
  public setResidential = (payload: IAddress) => {
    this.residential.streetAddress.set(payload.streetAddress);
    this.residential.streetAddress2.set(payload.streetAddress2 || "");
    this.residential.city.set(payload.city);
    this.residential.state.set(payload.state);
    this.residential.zipCode.set(payload.zipCode);
    this.residential.country.set(payload.country || "");
  };

  public putRequest() {
    const { streetAddress, streetAddress2, city, state, zipCode, country } =
      this.residential;

    const { useMailingAddress, mailing } = this;

    const { apiClient } = this.rootStore;

    if (!apiClient) {
      throw new Error("Could not connect to services.");
    }

    const payload: IAddressRequestPayload = {
      residentialAddress: {
        streetAddress: streetAddress.value!,
        streetAddress2: streetAddress2.value,
        city: city.value!,
        state: state.value!,
        zipCode: zipCode.value!,
        country: country.value,
      },
    };

    if (useMailingAddress.value) {
      payload.useMailingAddress = true;

      payload.mailingAddress = {
        streetAddress: mailing.streetAddress.value!,
        streetAddress2: mailing.streetAddress2.value,
        city: mailing.city.value!,
        state: mailing.state.value!,
        zipCode: mailing.zipCode.value!,
      };
    }

    return payload;
  }
}

export default Addresses;
