import React, { FC, useContext, useMemo } from "react";

import SignatureCanvas from "react-signature-canvas";
import {
	RootRef,
	Button,
	Box,
	createStyles,
	makeStyles,
	useMediaQuery,
} from "@material-ui/core";

import theme from "pages/theme";
import ApplicationContext from "stores/application/ApplicationRoot";

interface ISignatureFormProps {}

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			"& > canvas": {
				backgroundColor: "#F8F8F8",
				border: "1px solid #E7E7E7",
			},
		},
	})
);

const SignatureForm: FC<ISignatureFormProps> = (props) => {
	const isDesktop = useMediaQuery("(min-width: 850px)");

	const canvasStyle = useMemo(() => {
		return {
			height: 120,
			width: isDesktop
				? window.innerWidth * 0.55 - window.innerWidth * 0.55 * 0.26
				: window.innerWidth! * 0.78,
		};
	}, [isDesktop]);

	const { signature } = useContext(ApplicationContext);
	const styles = useStyles();

	let canvasRef: any = React.createRef();

	const onSignatureEnd = (val: any) => {
		const imageStr = canvasRef.toDataURL("image/png");

		signature.field.set(imageStr);
	};

	const setRef = (ref: any) => {
		canvasRef = ref;
	};

	const handleClear = () => {
		canvasRef.clear();
		signature.field.set("");
	};

	return (
		<>
			<Box className={styles.root} position="relative">
				<RootRef rootRef={canvasRef}>
					<SignatureCanvas
						ref={setRef}
						onEnd={onSignatureEnd}
						penColor={theme.palette.primary.main}
						canvasProps={canvasStyle}
					/>
				</RootRef>
				{signature.field.value && (
					<Box zIndex={9999} position="absolute" top={-6} right={-6}>
						<Button style={{ color: "red" }} onClick={handleClear}>
							Clear Signature
						</Button>
					</Box>
				)}
			</Box>
		</>
	);
};

export default SignatureForm;
