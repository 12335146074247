import React from "react";

const ArrowRightWhite = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.73101 12L13 7.5L8.73101 3L7.94583 3.82767L10.8744 6.91477L2.5 6.91477L2.5 8.08523L10.8744 8.08523L7.94583 11.1723L8.73101 12Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowRightWhite;
