import React, { FC } from "react";
import { useContext } from "react";

import { observer } from "mobx-react-lite";

import ApplicationContext from "stores/application/ApplicationRoot";
import TextfieldWithValidation from "components/UI/TextField/TextfieldWithValidation";

interface IDependentsProps {}

const Dependents: FC<IDependentsProps> = (props) => {
	const { taxStatus } = useContext(ApplicationContext);

	const setDependents = (e: any) => {
		taxStatus.dependents.set(e.target.value);
	};

	return (
		<TextfieldWithValidation
			onChange={setDependents}
			value={taxStatus.dependents.value || ""}
			type="number"
			placeholder="Number of dependents"
			error={taxStatus.dependents.errMsg}
			valid={
				!taxStatus.dependents.errMsg &&
				taxStatus.dependentsAsInt !== undefined &&
				taxStatus.dependentsAsInt >= 0
			}
		/>
	);
};

export default observer(Dependents);
