import React, { FC } from "react";

import Box from "@material-ui/core/Box";
import { makeStyles, ListItem, Radio, Typography } from "@material-ui/core";

interface IInvestmentGoalOptionProps {
	title?: string;
	subTitle?: string;
	active?: boolean;
	onClick?: () => void;
}

const useStyles = (active?: boolean) =>
	makeStyles((theme) => ({
		root: {
			width: "100%",
		},
		radioRoot: {
			color: "#E7E7E7",
			padding: 0,
			"& > span > div > svg": {
				height: 18,
				width: 18,
			},
		},
		title: {
			fontSize: "14px",
			fontWeight: 600,
			marginBottom: 5,
			lineHeight: "14px",
			[theme.breakpoints.up("xl")]: {
				fontSize: "16px",
			},
		},
		subtitle: {
			fontSize: "12px",
			fontWeight: 400,
			color: "#828282",
			[theme.breakpoints.up("xl")]: {
				fontSize: "14px",
			},
		},
	}));

const InvestmentGoalOption: FC<IInvestmentGoalOptionProps> = ({
	title,
	subTitle,
	active,
	onClick,
}) => {
	const classes = useStyles(active)();

	return (
		<ListItem onClick={onClick} button className={classes.root}>
			<Box display="flex" alignItems="flex-start" width="100%">
				<Radio
					checked={active}
					classes={{ root: classes.radioRoot }}
					color="primary"
				/>

				<Box pl={1.5} flex={1}>
					<Typography className={classes.title}>{title}</Typography>

					<Typography className={classes.subtitle}>{subTitle}</Typography>
				</Box>
			</Box>
		</ListItem>
	);
};

export default InvestmentGoalOption;
