import { Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Check } from "@material-ui/icons";
import SectionDescription from "components/UI/Typography/SectionDescription";
import React, { FC } from "react";

const useStyles = makeStyles<Theme>((theme) => {
  const size = 48;

  return {
    circle: {
      borderRadius: 25,
      backgroundColor: theme.palette.primary.main,
      animation: "$colorSwap 800ms ease-out 500ms forwards",
      width: size,
      height: size,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    circleLoading: {
      borderRadius: 25,
      backgroundColor: theme.palette.primary.main,
      animationDirection: "alternate",
      animationName: "$colorSwap",
      animationDuration: "1s",
      animationDelay: "800ms",
      animationIterationCount: "infinite",
      width: size,
      height: size,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    circle2: {
      borderRadius: 25,
      backgroundColor: theme.palette.primary.main,
      width: size,
      height: size,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    circleNumber: {
      fontSize: 22,
      color: "#fff",
    },
    header: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontWeight: 900,
      fontSize: 36,
      fontFamily: "Cerebri Sans",
    },
    description: {
      fontSize: 12,
    },
    caption: {
      fontSize: 14,
      fontWeight: 600,
      color: "#828282",
      lineHeight: "23px",
      fontFamily: "Cerebri Sans",
      letterSpacing: "1px",
    },
    rightSide: {
      maxWidth: "65%",
      verticalAlign: "top",
      marginLeft: theme.spacing(2.5),
    },
    downArrow: {
      width: 10,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 25,
      animation: "$grow 800ms ease-out 800ms forwards",
      height: 0,
      marginTop: theme.spacing(2),
    },
    leftSide: {
      display: "inline-flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    finishedStep: {
      animation: "$appear 800ms ease-in",
    },

    nextStep: {
      animation: "$appear2 800ms linear 1470ms forwards",
      opacity: 0,
    },

    button: {
      borderRadius: 4,
      minWidth: 137,
      textTransform: "none",
      fontSize: 18,
      lineHeight: "17px",
      letterSpacing: "0.1px",
      fontWeight: 600,
      display: "flex",
      width: "100%",
      padding: "16px",
      margintop: 31,
    },

    "@keyframes appear2": {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },

    "@keyframes appear": {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },

    "@keyframes colorSwap": {
      from: {
        backgroundColor: theme.palette.primary.main,
      },
      to: {
        backgroundColor: theme.palette.secondary.dark,
      },
    },

    "@keyframes grow": {
      "0%": { height: 0, backgroundColor: theme.palette.primary.main },

      "100%": { height: "20vh", backgroundColor: theme.palette.secondary.dark },
    },
  };
});

interface Step {
  caption: string;
  header: string;
  description: string;
  step: any;
  circleColor?: string;
}

interface IStepLoaderProps {
  finishedStep: Step;
  nextStep: Step;
  loading?: boolean;
  onNext?: () => void;
  buttonText?: string;
  buttonHref?: string;
  buttonTwo?: {
    buttonTwoText: string;
    buttonTwoHref: string;
  };
  buttonOne?: boolean;
}

const StepLoader: FC<IStepLoaderProps> = ({
  finishedStep,
  nextStep,
  loading = true,
  onNext,
  buttonText = "Continue",
  buttonTwo,
  buttonHref,
  buttonOne = true,
}) => {
  const classes = useStyles();

  if (loading) {
    return (
      <Box className={classes.finishedStep} mt={4}>
        <Box className={classes.leftSide}>
          <Box className={classes.circleLoading}>
            <Typography className={classes.circleNumber}>
              {finishedStep.step}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.rightSide} display="inline-block">
          <Typography className={classes.caption}>SUBMITTING</Typography>

          <Typography className={classes.header}>
            {finishedStep.header}
          </Typography>

          <SectionDescription value="Give us a moment while we process your information." />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.finishedStep} mt={4}>
        <Box className={classes.leftSide}>
          <Box className={classes.circle}>
            <Check fontSize="large" style={{ color: "#fff" }} />
          </Box>

          <Box className={classes.downArrow}></Box>
        </Box>

        <Box className={classes.rightSide} display="inline-block">
          <Typography className={classes.caption}>
            {finishedStep.caption}
          </Typography>

          <Typography className={classes.header}>
            {finishedStep.header}
          </Typography>

          <SectionDescription value={finishedStep.description} />
        </Box>
      </Box>

      <Box className={classes.nextStep} mt={2} minHeight={200}>
        <Box className={classes.leftSide}>
          <Box
            className={classes.circle2}
            style={{ backgroundColor: nextStep.circleColor }}
          >
            <Typography className={classes.circleNumber}>
              {nextStep.step}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.rightSide} display="inline-block">
          <Typography className={classes.caption}>
            {nextStep.caption}
          </Typography>

          <Typography className={classes.header}>{nextStep.header}</Typography>

          <SectionDescription value={nextStep.description} />

          <div>
            {buttonOne && (
              <Button
                onClick={!buttonHref ? onNext : undefined}
                className={classes.button}
                component="a"
                target="_blank"
                href={buttonHref}
                disableElevation={true}
                variant="contained"
                color="primary"
              >
                {buttonText}
              </Button>
            )}

            {Boolean(buttonTwo) && (
              <Button
                component="a"
                target="_blank"
                href={buttonTwo?.buttonTwoHref}
                className={classes.button}
                disableElevation={true}
                variant="contained"
                color="secondary"
              >
                {buttonTwo?.buttonTwoText}
              </Button>
            )}
          </div>
        </Box>
      </Box>
    </>
  );
};

export default StepLoader;
