import React, { FC, useContext, useMemo, useState } from "react";

import { FormControl, Box, IconButton } from "@material-ui/core";
import { TextField as MUIField } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { Close, Visibility, VisibilityOff } from "@material-ui/icons";

import ApplicationContext from "stores/application/ApplicationRoot";
import TextfieldWithValidation from "components/UI/TextField/TextfieldWithValidation";

interface ISocialSecurityNumberProps {}

const SocialSecurityNumber: FC<ISocialSecurityNumberProps> = (props) => {
	const { ssn } = useContext(ApplicationContext);

	const removeServerResponse = () => {
		ssn.serverResponse.set("");
	};

	const [isPassword, setIsPassword] = useState(true);

	const handleClick = () => {
		setIsPassword(!isPassword);
	};

	const formatSSN = (value: string) => {
		let formatted = value;

		if (value.length > 3) {
			formatted = `${value.slice(0, 3)}-${value.slice(3)}`;
		}

		if (value.length > 5) {
			formatted = `${value.slice(0, 3)}-${value.slice(3, 5)}-${value.slice(5)}`;
		}

		return formatted;
	};

	const handleChange = (event: any) => {
		const cursorPosition = event.target.selectionStart;
		const inputValue = event.target.value;
		const currentSSNValue = ssn.field.value || "";

		const numericValue = event.target.value.replace(/[^0-9]/g, "");

		if (!ssn.field.value || ssn.field.value?.length === 0 || !isPassword) {
			if (numericValue.length > 9) {
				return;
			}

			ssn.field.set(formatSSN(numericValue));
			return;
		}

		// Determine if addition or deletion has happened
		if (inputValue.length > currentSSNValue.length) {
			//Addition

			const copy = ssn.field.value?.slice();

			const newStr =
				copy.slice(0, cursorPosition - 1) +
				numericValue +
				copy.slice(cursorPosition - 1);

			if (newStr.length > 11) {
				return;
			}

			const formatedSSN = formatSSN(newStr.replace(/[^0-9]/g, ""));

			ssn.field.set(formatedSSN);
		} else if (inputValue.length < currentSSNValue.length) {
			// Deletion
			const copy = ssn.field.value.slice();

			const newStr =
				copy?.slice(0, cursorPosition) + copy?.slice(cursorPosition + 1);

			const formatedSSN = formatSSN(newStr.replace(/[^0-9]/g, ""));

			ssn.field.set(formatedSSN);
		}
	};

	const maskedValue = useMemo(() => {
		if (isPassword) {
			const copy = ssn.field.value?.slice();

			return copy?.replace(/[0-9]/g, "•");
		}

		return ssn.field.value;
	}, [ssn.field.value, isPassword]);

	return (
		<>
			{!ssn.serverResponse.value && (
				<TextfieldWithValidation
					placeholder="xxx-xx-xxxx"
					fullWidth={true}
					type="text"
					onChange={handleChange}
					value={isPassword ? maskedValue : ssn.field.value}
					error={ssn.field.errMsg}
					InputProps={{
						endAdornment: (
							<IconButton onClick={handleClick}>
								{isPassword ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						),
					}}
					valid={!ssn.pageInvalid}
				/>
			)}

			{ssn.serverResponse.value && (
				<FormControl fullWidth={true}>
					<Box position="relative" width="100%">
						<MUIField
							disabled={true}
							margin="normal"
							fullWidth={true}
							value={ssn.serverResponse.value}
						/>
						<Box position="absolute" top="20%" right={0}>
							<IconButton onClick={removeServerResponse}>
								<Close fontSize="small" color="error" />
							</IconButton>
						</Box>
					</Box>
				</FormControl>
			)}
		</>
	);
};

export default observer(SocialSecurityNumber);
