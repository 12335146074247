import { IPoliticalExposureRequestPayload } from "core/api/types/request/request";
import { action, computed, observable } from "mobx";

import { Application } from "stores/application/ApplicationRoot";
import FormField from "../helpers/FormField";

interface IPoliticalExposure {
  politicallyExposed: boolean;
  relatedFamilyMembers?: string;
  politicalOrganizations?: string;
}

class PoliticalExposure {
  public rootStore: Application;

  @observable
  public isPoliticallyExposed = new FormField<boolean>(false);

  @observable
  public organizations = new FormField<string>("", ["required", "min:2"], {
    required:
      "Please provide the names of the related political organization. ",
    min: "Names of the related political organization must be atleast 2 characters long. ",
  });

  @observable
  public relations = new FormField<string>("", ["required", "min:2"], {
    required:
      "Please provide the names of the officials and officials immediate family members.",
    min: "Names of the officials and officials immediate family members must be atleast 2 characters long.",
  });

  constructor(rootStore?: any) {
    this.rootStore = rootStore;
  }

  @computed
  public get pageInvalid() {
    const { isPoliticallyExposed, organizations, relations } = this;

    if (
      isPoliticallyExposed.value &&
      (!organizations.value ||
        organizations.value.length < 2 ||
        !relations.value ||
        relations.value.length < 2)
    ) {
      return true;
    }

    return false;
  }

  @action
  public set = (payload: IPoliticalExposure) => {
    this.isPoliticallyExposed.set(payload.politicallyExposed);

    if (!payload.politicallyExposed) {
      return;
    }

    this.organizations.set(payload.politicalOrganizations || "");
    this.relations.set(payload.relatedFamilyMembers || "");
  };

  public putRequest() {
    const { isPoliticallyExposed, relations, organizations } = this;

    const { apiClient } = this.rootStore;

    if (!apiClient) {
      throw new Error("Could not connect to services.");
    }

    const payload: IPoliticalExposureRequestPayload = {
      politicallyExposed: isPoliticallyExposed.value!,
      politicalOrganizations: organizations.value!,
      relatedFamilyMembers: relations.value!,
    };

    if (!payload.politicallyExposed) {
      delete payload.politicalOrganizations;
      delete payload.relatedFamilyMembers;
    }

    return payload;
  }
}

export default PoliticalExposure;
