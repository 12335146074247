import React, { useContext } from "react";

import StepPanelBasic from "components/Application/StepPanelBasic";
import { observer } from "mobx-react-lite";
import ApplicationContext from "stores/application/ApplicationRoot";

interface Params {
	copyOverride?: {
		title?: string;
		subTitle?: string;
	};
}

const SuitabilityPanel = observer(({ copyOverride }: Params) => {
	const application = useContext(ApplicationContext);
	const isSub = application.isSubscriber;

	return (
		<StepPanelBasic
			title={copyOverride?.title ? copyOverride.title : isSub ? "Please tell us about your employment." : "Tell us about your investment experience"}
			descriptions={[copyOverride?.subTitle ? copyOverride.subTitle :
				isSub ? `Knowing your employment status helps us determine if you are a professional investor.` :
					`We need to ask some questions about you and your experience with investing. Options trading isn't for everyone, we want to help you find out if it's right for you.`,
			]}
		/>
	);
});

export default SuitabilityPanel;
