import { action, computed, observable } from "mobx";

import { Application } from "stores/application/ApplicationRoot";
import FormField from "../helpers/FormField";

interface ICitizenship {
  usCitizen: boolean;
  country: string;
  usPermanentResident: boolean;
}

class Citizenship {
  @computed
  public get pageInvalid() {
    if (!this.isUSCitizen.value && this.country.value!.length < 1) {
      return true;
    }

    if (!this.isUSCitizen.value && !this.usPermanentResident.value) {
      return true;
    }

    return false;
  }

  @computed
  public get residentErrorMessage() {
    if (!this.isUSCitizen.value && !this.usPermanentResident.value) {
      return "Sorry, Options AI is only available to US persons.";
    }
    return "";
  }

  @computed public get citizenLabel() {
    return this.isUSCitizen.value
      ? "Yes, I am a United States citizen."
      : "No, I am not a U.S. citizen.";
  }
  public rootStore: Application;

  @observable
  public country = new FormField<string>("USA");

  @observable
  public usPermanentResident = new FormField<boolean>(true);

  @observable
  public isUSCitizen = new FormField<boolean>(true);

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @action
  public set = (payload: ICitizenship) => {
    this.isUSCitizen.set(payload.usCitizen);
    this.country.set(payload.country || "USA");
    this.usPermanentResident.set(payload.usPermanentResident);
  };

  public putRequest() {
    const { country, usPermanentResident } = this;

    const { apiClient } = this.rootStore;

    if (!apiClient) {
      throw new Error("Could not connect to services.");
    }

    return {
      country: country.value!,
      usPermanentResident:
        country.value === "USA" ? false : usPermanentResident.value!,
    };
  }
}

export default Citizenship;
