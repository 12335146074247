import React, { FC, useContext } from "react";

import { observer } from "mobx-react-lite";

import {
	FormControl,
	FormControlLabel,
	Switch,
	Dialog,
	Typography,
	Box,
} from "@material-ui/core";

import ApplicationContext from "stores/application/ApplicationRoot";
import PoliticalExposureDetails from "containers/new/PoliticalExposureDetails";
import { useState } from "react";
import RouterLink from "components/UI/new/RouterLink";
import useErrorStyles from "utils/useErrorStyles";

interface IProps {}

const PoliticalExposureContainer: FC<IProps> = observer(() => {
	const styles = useErrorStyles();

	const { politicalExposure } = useContext(ApplicationContext);

	const setStatus = (e: any, hasPoliticalExposure: boolean) => {
		if (hasPoliticalExposure) {
			setIsOpen(true);
		}

		politicalExposure.isPoliticallyExposed.set(hasPoliticalExposure);
	};

	const { isPoliticallyExposed } = politicalExposure;

	const [isOpen, setIsOpen] = useState(false);

	const handleClose = () => {
		setIsOpen(false);

		if (
			!politicalExposure.organizations.value ||
			!politicalExposure.relations.value
		) {
			politicalExposure.isPoliticallyExposed.set(false);
		}
	};

	const setRelations = (value: string) => {
		politicalExposure.relations.set(value);
	};

	const setOrganizations = (value: string) => {
		politicalExposure.organizations.set(value);
	};

	const handleSave = (organizations = "", relations = "") => {
		setOrganizations(organizations);
		setRelations(relations);

		handleClose();
	};

	const handleOpen = () => {
		setIsOpen(true);
	};

	const displayError =
		politicalExposure.isPoliticallyExposed.value &&
		(!!politicalExposure.organizations.errMsg ||
			!!politicalExposure.relations.errMsg);

	return (
		<FormControl fullWidth={true} margin="none">
			<FormControlLabel
				control={
					<Switch
						checked={isPoliticallyExposed.value}
						onChange={setStatus}
						value={isPoliticallyExposed.value}
						color="primary"
					/>
				}
				label={isPoliticallyExposed.value ? "Yes" : "No"}
			/>

			{displayError && (
				<Typography className={styles.error}>
					{politicalExposure.organizations.errMsg}
					{politicalExposure.relations.errMsg}
				</Typography>
			)}

			{isPoliticallyExposed.value && (
				<RouterLink to="#" onClick={handleOpen}>
					Edit Names and Organizations
				</RouterLink>
			)}

			<Dialog onClose={handleClose} open={isOpen}>
				<Box p={2}>
					<Typography variant="h5">Political Exposure</Typography>

					<PoliticalExposureDetails
						initialOrganizations={politicalExposure.organizations.value}
						initialRelations={politicalExposure.relations.value}
						onSave={handleSave}
						handleClose={handleClose}
					/>
				</Box>
			</Dialog>
		</FormControl>
	);
});

export default PoliticalExposureContainer;
