import React, { FC, FormEvent, useEffect } from "react";

import Box from "@material-ui/core/Box";
import PageNavigation from "components/UI/new/PageNavigation";
import FullPageLoader from "components/UI/FullPageLoader/FullPageLoader";
import { makeStyles } from "@material-ui/core";

import theme from "./theme";
import LogoWithText from "components/UI/new/LogoWithText";

interface IDefaultLayoutProps {
  onNext?: () => void;
  secondaryRoute?: string;
  onNextDisabled?: boolean;
  onBackDisabled?: boolean;
  hasContinue?: boolean;
  loading?: boolean;
  disableGutters?: boolean;
  continueText?: string;
}

const useStyles = ({ disableGutters = false }) =>
  makeStyles({
    contentBox: {
      paddingLeft: disableGutters ? 0 : theme.spacing(2.5),
      paddingRight: disableGutters ? 0 : theme.spacing(2.5),
      maxWidth: 900,
      margin: "0 auto",
    },
    generalBox: {
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
      maxWidth: 900,
      margin: "0 auto",
    },
    root: {
      paddingTop: 34,
      paddingBottom: 34,
    },
    height100: {
      height: "100%",
    },
  });

const DefaultLayout: FC<IDefaultLayoutProps> = ({
  children,
  onNextDisabled = true,
  onBackDisabled = false,
  onNext,
  secondaryRoute,
  hasContinue = true,
  loading,
  disableGutters = false,
  continueText,
}) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const { generalBox, height100, root, contentBox } = useStyles({
    disableGutters,
  })();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
  };

  if (loading) {
    return <FullPageLoader />;
  }

  return (
    <Box className={`${height100} ${root}`}>
      <form id="form" className={height100} onSubmit={handleSubmit}>
        <Box className={`${height100} `}>
          <Box
            minHeight="100vh"
            pb={6}
            position="relative"
            id="scrolling_div"
            flex={1}
          >
            <Box className={generalBox} pb={2.5}>
              <LogoWithText />
            </Box>

            <Box className={contentBox}>{children}</Box>

            <Box
              className={generalBox}
              pt={5}
              display="flex"
              justifyContent="space-between"
              width="100%"
              marginTop="auto"
            >
              <PageNavigation
                continueText={continueText}
                hasContinue={hasContinue}
                onBackDisabled={onBackDisabled}
                secondaryRoute={secondaryRoute}
                onContinue={onNext}
                onNextDisabled={onNextDisabled}
              />
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default DefaultLayout;
