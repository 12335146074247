import React, { useState } from "react";

import { Typography, IconButton, Box } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

import useIsDesktop from "hooks/useIsDesktop";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "components/UI/Dialog/Dialog";
import Button from "components/UI/Button";
import Tooltip from "components/UI/Tooltip";

const useStyles = makeStyles((theme) => ({
	scrollContainer: {
		overflowY: "scroll",
	},
	infoIcon: {
		"&:hover": {
			cursor: "pointer",
		},
		fill: "#2c74f6",
		height: 18,
		width: 18,
	},
	iconButton: {
		padding: 5,
		[theme.breakpoints.down("xs")]: {
			padding: 8,
		},
	},
	tooltipTitle: {
		fontSize: 14,
		fontWeight: 700,
	},
	tooltipDescription: {
		fontSize: 12,
		fontWeight: 500,
		marginTop: 8,
	},
	dialog: {
		zIndex: 6,
		padding: "40px 50px",
		maxWidth: "600px",
		boxShadow: "0 4px 33px 8px rgb(0 0 0 / 10%) !important",
		borderRadius: "1px",
		display: "block",
		[theme.breakpoints.down("xs")]: {
			margin: 0,
			height: "100%",
			maxWidth: "100%",
			maxHeight: "100%",
			padding: 30,
			display: "flex",
			justifyContent: "space-between",
		},
	},
	descriptionMargin: {
		margin: "12px 0px",
	},
	dialogTitle: {
		fontSize: "16px !important",
		fontWeight: "900 !important" as any,
		marginBottom: "16px",
	},
	dialogDescription: {
		color: "#828282",
		fontSize: "13px !important",
		fontWeight: "500 !important" as any,
		lineHeight: 1.75,
	},
	buttonText: {
		fontSize: "14px",
		fontWeight: 600,
		margin: "0px 75px",
	},
}));

const TimeHorizonHelp = React.memo(() => {
	const isDesktop = useIsDesktop();

	const classes = useStyles();

	const [open, setOpen] = useState(false);

	const toggleOpen = () => setOpen((prev) => !prev);

	const close = () => setOpen(false);

	return (
		<>
			{isDesktop ? (
				<Tooltip
					placement="top"
					setClose={close}
					open={open}
					interactive
					noMaxWidth
					title={
						<Box p={1} maxWidth={500}>
							<Typography className={classes.tooltipTitle}>
								What is Time Horizon?
							</Typography>

							<Typography className={classes.tooltipDescription}>
								Your time horizon is the estimated amount of time you expect to
								hold your investment before needing access to your capital.
								Different time horizons typically correspond to different
								investment strategies and risk tolerances.
							</Typography>

							<ul>
								<li>
									<Typography
										className={`${classes.tooltipDescription} ${classes.descriptionMargin}`}
									>
										<b>Short:</b> You expect to need your investment within a
										few years. Consider less volatile, more liquid investments.
									</Typography>
								</li>

								<li>
									<Typography
										className={`${classes.tooltipDescription} ${classes.descriptionMargin}`}
									>
										<b>Average:</b> You have a moderate time horizon, typically
										5 to 10 years, and may be open to a moderate level of risk
										for potentially higher returns.
									</Typography>
								</li>

								<li>
									<Typography
										className={`${classes.tooltipDescription} ${classes.descriptionMargin}`}
									>
										<b>Longest:</b> You don't expect to need your investment for
										many years or decades. You can potentially take on more risk
										for higher returns.
									</Typography>
								</li>
							</ul>
						</Box>
					}
				>
					<IconButton className={classes.iconButton} onClick={toggleOpen}>
						<InfoOutlined className={classes.infoIcon} />
					</IconButton>
				</Tooltip>
			) : (
				<>
					<IconButton className={classes.iconButton} onClick={toggleOpen}>
						<InfoOutlined className={classes.infoIcon} />
					</IconButton>

					<Dialog
						paperClassName={classes.dialog}
						open={open}
						onClose={toggleOpen}
						noBackdrop
					>
						<div className={classes.scrollContainer}>
							<Typography className={classes.dialogTitle}>
								What is Time Horizon?
							</Typography>

							<Typography className={classes.dialogDescription}>
								Your time horizon is the estimated amount of time you expect to
								hold your investment before needing access to your capital.
								Different time horizons typically correspond to different
								investment strategies and risk tolerances.
							</Typography>

							<ul>
								<li>
									<Typography
										className={`${classes.dialogDescription} ${classes.descriptionMargin}`}
									>
										<b>Short:</b> You expect to need your investment within a
										few years. Consider less volatile, more liquid investments.
									</Typography>
								</li>

								<li>
									<Typography
										className={`${classes.dialogDescription} ${classes.descriptionMargin}`}
									>
										<b>Average:</b> You have a moderate time horizon, typically
										5 to 10 years, and may be open to a moderate level of risk
										for potentially higher returns.
									</Typography>
								</li>

								<li>
									<Typography
										className={`${classes.dialogDescription} ${classes.descriptionMargin}`}
									>
										<b>Longest:</b> You don't expect to need your investment for
										many years or decades. You can potentially take on more risk
										for higher returns.
									</Typography>
								</li>
							</ul>
						</div>

						<Box mt={4}>
							<Button fullWidth={!isDesktop} onClick={toggleOpen}>
								<Typography className={classes.buttonText}>Got It!</Typography>
							</Button>
						</Box>
					</Dialog>
				</>
			)}
		</>
	);
});

export default TimeHorizonHelp;
