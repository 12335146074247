import React, { FC, useContext } from "react";

import { observer } from "mobx-react-lite";
import { ButtonGroup } from "@material-ui/core";

import ApplicationContext from "stores/application/ApplicationRoot";
import { TimeHorizon } from "core/application/Application.d";
import { TIME_HORIZON_VALUES } from "core/constants/perferenceValues";
import TextfieldWithValidation from "components/UI/TextField/TextfieldWithValidation";
import ButtonGroupButton from "components/UI/new/ButtonGroupButton";

interface IProps {}

const TimeHorizonContainer: FC<IProps> = (props) => {
	const { preferences } = useContext(ApplicationContext);

	const setTimeHorizon = (value: TimeHorizon) => {
		preferences.timeHorizon.set(value);
	};

	return (
		<TextfieldWithValidation
			customField={
				<ButtonGroup fullWidth={true}>
					{TIME_HORIZON_VALUES.map((val) => (
						<ButtonGroupButton
							key={val.value}
							active={val.value === preferences.timeHorizon.value}
							onClick={() => setTimeHorizon(val.value)}
						>
							{val.label}
						</ButtonGroupButton>
					))}
				</ButtonGroup>
			}
			error={preferences.timeHorizon.errMsg}
			valid={
				preferences.timeHorizon.value !== undefined &&
				!preferences.timeHorizon.errMsg
			}
		/>
	);
};

export default observer(TimeHorizonContainer);
