import React, { FC, useState, useContext } from "react";

import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";

import MaritalForm from "containers/new/MaritialForm";
import Dependents from "containers/new/Dependents";
import ApplicationContext from "stores/application/ApplicationRoot";
import SectionHeader from "components/UI/Typography/SectionHeader";
import SectionDescription from "components/UI/Typography/SectionDescription";
import ApplicationFormLayout from "containers/Layout/ApplicationFormLayout";
import BasicInfoPanel from "containers/Steps/BasicInfoPanel";
import APP_PATHS from "pages/appPaths";

interface IMaritalProps
	extends RouteComponentProps<
		{},
		{},
		{ returnUrl?: string; from?: boolean; upload?: true; }
	> { }

const MaritalNew: FC<IMaritalProps> = ({ history, location }) => {
	const application = useContext(ApplicationContext);
	const { taxStatus } = application;

	const onNextDisabled = taxStatus.pageInvalid || application.loading;

	const [returnUrl] = useState(location.state?.returnUrl);

	const handleNext = async () => {
		if (returnUrl === APP_PATHS.review) {
			await application.submitStepOne();

			location.state.returnUrl = undefined;

			history.replace(APP_PATHS.review);
			return;
		}

		history.push("/steps/2", { upload: true, from: true });
	};

	return (
		<ApplicationFormLayout
			step={2}
			loading={application.loading}
			leftPanelChildren={<BasicInfoPanel isSubscriber={application.isSubscriber} />}
			onNext={handleNext}
			onNextDisabled={onNextDisabled}
			continueText="Next: Suitability"
			formChildren={
				<>
					<Box pt="36px">
						<SectionHeader marginBottom={0} value="Marital Status" />

						<SectionDescription value="Which of the following best describes you?" />
					</Box>

					<MaritalForm />

					<Box py="38px">
						<SectionHeader marginBottom={0} value="Dependents" />

						<SectionDescription value="How many dependents are you responsible for?" />

						<Dependents />
					</Box>
				</>
			}
		/>
	);
};

export default observer(MaritalNew);
