import React, { useCallback, useContext, useState } from "react";
import Component from "components/UI/Status/ResendEmailVerification";
import ApplicationContext from "stores/application/ApplicationRoot";

const ResendEmailVerification = React.memo(() => {
  const application = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [called, setCalled] = useState(false);

  const makeCall = useCallback(async () => {
    if (loading) {
      return;
    }

    setCalled(true);

    setLoading(true);
    setError(false);

    try {
      await application.apiClient?.resendEmailVerification();
    } catch (error) {
      setError("Something went wrong, please try again.");
    }

    setLoading(false);
  }, [application, setError, setLoading, loading]);

  return (
    <Component
      onResend={makeCall}
      loading={loading}
      error={!!error}
      success={!!called && !loading && !error}
    />
  );
});

export default ResendEmailVerification;
