import React, { FC, useRef } from "react";

import { Collapse, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import PressIcon from "components/UI/icons/PressIcon";

const useStyles = makeStyles({
	buttonContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	buttonText: {
		marginLeft: 7,
		fontWeight: 900,
		fontSize: 14,
		color: "#0066FF",
	},
	contentContainer: {
		backgroundColor: "#0066FF",
		width: "100%",
		padding: 20,
		borderRadius: 6,
		marginTop: 20,
	},
});

const ApplicationAboutPageCollapse: FC = React.memo(({ children }) => {
	const classes = useStyles();
	const contentRef = useRef<HTMLDivElement | null>(null);

	const [expanded, setExpanded] = React.useState<boolean>(false);

	const handleExpandClick = () => {
		setExpanded((prevExpanded) => {
			// if the content was previously not expanded, then scroll it into view
			if (!prevExpanded && contentRef.current) {
				setTimeout(() => {
					if (contentRef.current) {
						contentRef.current.scrollIntoView({
							behavior: "smooth",
							block: "start",
						});
					}
				}, 400);
			}
			return !prevExpanded;
		});
	};

	return (
		<>
			<div className={classes.buttonContainer} onClick={handleExpandClick}>
				<PressIcon />

				<Typography className={classes.buttonText}>
					{expanded ? "Collapse" : "More about this step"}
				</Typography>
			</div>

			<Collapse in={expanded}>
				<div className={classes.contentContainer} ref={contentRef}>
					{children}
				</div>
			</Collapse>
		</>
	);
});

export default ApplicationAboutPageCollapse;
