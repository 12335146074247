import React, { useCallback } from "react";

import { useEffect, useState } from "react";

import Snack from "components/UI/Snack/Snack";

interface IUseSnackbarParams {
  message?: string;
  variant: "info" | "success" | "warning" | "error";
  timeout: number;
}

const useSnackbar = ({ message, variant, timeout }: IUseSnackbarParams) => {
  const [open, setOpen] = useState(false);
  const [Snackbar, setSnackbar] = useState<any | null>(null);
  const [timeoutFunc, setTimeoutFunc] = useState<any>();

  const closeSnackbar = useCallback(() => {
    if (open) {
      setOpen(false);
      return;
    }
  }, [setOpen, open]);

  useEffect(() => {
    setTimeoutFunc(clearTimeout(timeoutFunc));

    if (message) {
      setOpen(true);

      setTimeoutFunc(
        setTimeout(() => {
          setOpen(false);
        }, timeout)
      );

      return;
    }

    setOpen(false);
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    if (open && message) {
      setSnackbar(
        <Snack
          onClose={closeSnackbar}
          open={true}
          variant={variant}
          message={message}
        />
      );
      return;
    }

    setSnackbar(null);
  }, [open, closeSnackbar, message, variant]);

  return [Snackbar];
};

export default useSnackbar;
