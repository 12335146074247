const APP_PATHS = {
	basicInfo: "/basic-info",
	identity: "/identity",
	marital: "/marital",
	investmentKnowledge: "/investment-knowledge",
	investmentGoals: "/investment-goals",
	financialProfile: "/financial-profile",
	tradingProfile: "/trading-profile",
	affiliations: "/affiliations",
	employment: "/employment",
	sweepProgram: "/sweep-program",
	trustedContact: "/trusted-contact",
	agree: "/agree",
	review: "/review",
	signature: "/signature",
	steps: "/steps/:id",
	status: "/status",
	error500: "/500",
};

export default APP_PATHS;
