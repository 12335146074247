import React, { FC } from "react";

import {
	Box,
	Card,
	createStyles,
	makeStyles,
	Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
	createStyles({
		card: {
			width: "100%",
			maxWidth: "430px",
			minHeight: "fit=content",
			height: "100%",
			maxHeight: 500,
			boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
			display: "flex",
			alignItems: "flex-start",
			padding: "32px",
			transition: "all 0.25s ease-in-out",
			border: "1px solid #e7e7e7",
			"&:hover": {
				cursor: "pointer",
				border: "1px solid #2C74F6",
			},
		},
		header: {
			fontSize: 20,
			fontWeight: 900,
			fontFamily: "Cerebri Sans",
			marginBottom: "5px",
			[theme.breakpoints.down("sm")]: {
				fontSize: "16px",
			},
		},
		description: {
			fontSize: 15,
			fontWeight: 400,
			fontFamily: "Cerebri Sans",
			color: "#828282",
			[theme.breakpoints.down("sm")]: {
				fontSize: "13px",
			},
		},
		textContainer: {
			marginLeft: "28px",
			[theme.breakpoints.down("sm")]: {
				marginLeft: "20px",
			},
		},
		anchor: {
			textTransform: "none",
			textDecoration: "none",
		},
		noIcon: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
		},
	})
);

interface ComponentParams {
	header: string;
	icon?: JSX.Element;
	description?: string;
	descriptionComponent?: JSX.Element;
	link: string;
}

const ToolsCard: FC<ComponentParams> = React.memo(
	({ icon, header, link, description, descriptionComponent }) => {
		const styles = useStyles();

		return (
			<a className={styles.anchor} href={link}>
				<Card className={styles.card}>
					<Box minWidth="fit-content">{icon}</Box>

					<div className={icon ? styles.textContainer : styles.noIcon}>
						<Typography className={styles.header}>{header}</Typography>

						{descriptionComponent ? (
							descriptionComponent
						) : (
							<Typography className={styles.description}>
								{description}
							</Typography>
						)}
					</div>
				</Card>
			</a>
		);
	}
);

export default ToolsCard;
