import React, { FC, useState } from "react";
import DefaultLayout from "./DefaultLayout";
import { Box, Typography, Button } from "@material-ui/core";
import RouterLink from "components/UI/new/RouterLink";
import { useContext } from "react";
import ApplicationContext from "stores/application/ApplicationRoot";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import Linkify from "linkifyjs/react";

interface IErrorPageProps {}

const MUIButton: FC = ({ ...props }) => (
  <Button
    color="primary"
    disableElevation={true}
    variant="contained"
    {...props}
    style={{ color: "#fff" }}
  />
);

const linkifyOptions = {
  formatHref: function (href: any, type: string) {
    return href;
  },
  format: (value: string) => {
    if (value === "support@optionsai.com") {
      value = "support";
    }
    return value;
  },
};

const ErrorPage: FC<IErrorPageProps> = () => {
  const application = useContext(ApplicationContext);

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    if (!application.error) {
      return;
    }

    if (
      application.error.response?.status &&
      application.error.response?.status < 500 &&
      application.error.response?.status >= 400
    ) {
      setErrorMessage(
        application.error.response.data?.message || `Unknown Error`
      );
    } else {
      setErrorMessage(`Unknown Error`);
    }

    application.setError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application.error]);

  return (
    <DefaultLayout hasContinue={false} onBackDisabled={true}>
      <Box py={4}>
        {!!errorMessage && (
          <Typography gutterBottom={true} variant="h4" color="error">
            {errorMessage}
          </Typography>
        )}

        <Typography gutterBottom={true} variant="body1" color="error">
          Opps, something went wrong while processing your request. Please
          refresh the page to try again.
        </Typography>
      </Box>

      <RouterLink component={MUIButton} to="/">
        Refresh
      </RouterLink>

      <Box py={4}>
        <Linkify options={linkifyOptions}>
          <Typography variant="body1" color="textSecondary">
            If this error continues, please contact support@optionsai.com
          </Typography>
        </Linkify>
      </Box>
    </DefaultLayout>
  );
};

export default observer(ErrorPage);
