import React, { FC } from "react";

import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import CheckBadgeIcon from "components/UI/icons/CheckBadgeIcon";
import LocationIcon from "components/UI/icons/LocationIcon";
import ShieldIcon from "components/UI/icons/ShieldIcon";
import StepLoader from "components/UI/new/StepLoader/StepLoader";

const useStyles = makeStyles({
	step1Container: {
		maxWidth: 572,
	},
	listItem: {
		display: "flex",
		alignItems: "flex-start",
		"& > :nth-child(2)": {
			fontSize: "14px",
			fontWeight: 600,
			marginLeft: 10,
			lineHeight: "21px",
			color: "#828282",
		},
	},
	subtitle: {
		fontWeight: 600,
		fontSize: 18,
		lineHeight: "27px",
		marginBottom: 25,
	},
	blue: {
		color: "#0066ff !important",
	},
	link: {
		textTransform: "none",
		textDecoration: "none",
		color: "inherit",
		opacity: 1,
		"&:hover": {
			cursor: "pointer",
			opacity: 0.75,
		},
		transition: "opacity 0.15s ease-in-out",
		width: "fit-content",
	},
	title: {
		fontSize: 34,
		fontWeight: 900,
		marginBottom: 24,
		marginTop: 36,
		fontFamily: "Cerebri Sans",
	},
	listContainer: {
		maxWidth: "550px",
		"& > *": {
			marginBottom: 25,
		},
		"& > :last-child": {
			marginBottom: 0,
		},
	},
	continueButton: {
		borderRadius: 4,
		marginTop: 48,
		minWidth: 137,
		paddingTop: 8,
		paddingBottom: 8,
		textTransform: "none",
		fontSize: 18,
		fontWeight: 600,
		marginRight: 24,
		display: "block",
	},
});

interface IStepDisplayProps {
	activeStep: string;
	onNext: () => void;
	loading: boolean;
}

const StepDisplay: FC<IStepDisplayProps> = React.memo(
	({ activeStep, onNext, loading }) => {
		const styles = useStyles();

		if (activeStep === "1") {
			return (
				<div className={styles.step1Container}>
					<Typography className={styles.title} variant="h1">
						Let's get to know you.
					</Typography>

					<Typography
						paragraph
						color="textSecondary"
						variant="h4"
						className={styles.subtitle}
					>
						We are required to collect the following information when we open a
						brokerage account. Don't worry, we only ask what is essential for
						verifying your identity and ensuring options trading is right for
						you.
					</Typography>

					<div className={styles.listContainer}>
						<div className={styles.listItem}>
							<ShieldIcon />

							<Typography>Your data's safety is our priority.</Typography>
						</div>

						<div className={styles.listItem}>
							<CheckBadgeIcon size={24} />

							<Typography>
								Options AI Financial LLC is US based and a member of{" "}
								<a
									href="https://www.finra.org"
									target="_blank"
									rel="noopener noreferrer"
									className={styles.link}
								>
									<u>FINRA</u>
								</a>{" "}
								and{" "}
								<a
									href="https://www.sipc.org"
									target="_blank"
									rel="noopener noreferrer"
									className={styles.link}
								>
									<u>SIPC</u>
								</a>
								.
							</Typography>
						</div>

						<div className={styles.listItem}>
							<LocationIcon />

							<Typography>
								Options AI is only available to US Residents. You can switch to
								a{" "}
								<a
									className={`${styles.link} ${styles.blue}`}
									href="https://id.options.ai/subscribe"
								>
									subscription account
								</a>{" "}
								if you wish.
							</Typography>
						</div>
					</div>

					<Button
						className={styles.continueButton}
						disableElevation={true}
						variant="contained"
						color="primary"
						onClick={onNext}
					>
						Continue
					</Button>
				</div>
			);
		}

		if (activeStep === "2") {
			return (
				<StepLoader
					onNext={onNext}
					loading={loading}
					finishedStep={{
						caption: "JUST FINISHED",
						header: "Verify Identity",
						description: `You’ve just completed your first step in applying for an Options AI
  account.`,
						step: "1",
					}}
					nextStep={{
						caption: "Up Next",
						header: "Options Suitability",
						description: `Options trading is not for everyone. We need to know if its right for you.`,
						step: "2",
					}}
				/>
			);
		}

		if (activeStep === "3") {
			return (
				<StepLoader
					onNext={onNext}
					loading={loading}
					finishedStep={{
						caption: "JUST FINISHED",
						header: "Options Suitability",
						description: `Congrats on completeing another step towards finishing your application.`,
						step: "2",
					}}
					nextStep={{
						caption: "Up Next",
						header: "Review and Submit",
						description: `One more step to go. `,
						step: "3",
					}}
				/>
			);
		}

		if (activeStep === "welcome") {
			return (
				<StepLoader
					loading={loading}
					finishedStep={{
						caption: "JUST FINISHED",
						header: "Review and Submit",
						description: `Your application has been submitted successfully.`,
						step: "3",
					}}
					nextStep={{
						caption: "UP NEXT",
						header: "Under Review",
						description: `We typically take less than 1 business day to process your application. In the mean time check out our website to learn how to use our`,
						step: "3",
					}}
				/>
			);
		}

		return <></>;
	}
);

export default StepDisplay;
