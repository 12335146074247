import React from "react";

import { Box, useMediaQuery } from "@material-ui/core";

import StepPanelBasic from "components/Application/StepPanelBasic";
import SIPCBanner from "components/Banners/SIPCBannerSmall";
import SIPCLogo from "components/Banners/SIPCLogo.svg";

interface Params {
	isSubscriber?: boolean;
}

const BasicInfoPanel = React.memo(({ isSubscriber }: Params) => {
	const isDesktop = useMediaQuery("(min-width: 850px)");
	return (
		<Box
			width="100%"
			height="100%"
			display={"flex"}
			flex={1}
			flexDirection={"column"}
			justifyContent={"space-between"}
		>
			<StepPanelBasic
				title={isSubscriber ? "We need a little information." : "Let's get to know you"}
				descriptions={[
					isSubscriber ? `To give you low-cost access to real-time market data, we need to know a little bit about you. This information is used to confirm your non-professional status and ensure data will not be used for commercial purposes.` :
						`This section is used to confirm your identity and ensure we have the latest information to properly communicate with you. No credit checks are performed. We're required by law to verify your identity.`,
				]}
			/>




			{!isSubscriber && (
				<Box mt={!isDesktop ? 3 : 0}>
					<SIPCBanner logo={SIPCLogo} backgroundColor="rgba(51, 59, 73, 0.15)" />
				</Box>
			)}

		</Box>
	);
});

export default BasicInfoPanel;
