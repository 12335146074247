import { action, computed, observable, toJS } from "mobx";
import FormField from "stores/helpers/FormField";

import { InvestmentObjective } from "core/application/Application";
import { Application } from "stores/application/ApplicationRoot";

export enum ExperienceEnum {
  LIMITED = "LIMITED",
  GOOD = "GOOD",
  EXTENSIVE = "EXTENSIVE",
}

interface IInvestmentProfile {
  investmentObjectives: string[];
  stocksExperience: ExperienceEnum;
  optionsExperience: ExperienceEnum;
  spreadsExperience: ExperienceEnum;
}

class InvestmentProfile {
  public rootStore: Application;

  @observable
  public stocksExperience = new FormField<ExperienceEnum>(
    undefined,
    ["required"],
    { required: "Please select your experience trading stock." }
  );

  @observable
  public optionsExperience = new FormField<ExperienceEnum>(
    undefined,
    ["required"],
    { required: "Please select your experience with call and put options." }
  );

  @observable
  public spreadsExperience = new FormField<ExperienceEnum>(
    undefined,
    ["required"],
    { required: "Please select your experience with option spreads." }
  );

  @observable
  public objectives = new FormField<
    Array<{
      value: InvestmentObjective;
      isChecked: boolean;
    }>
  >([], ["array"]);

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @computed public get investmentObjectivesInvalid(): boolean {
    const { value } = this.objectives;

    const vals = toJS(value);

    if (!vals || vals.findIndex((val) => val.isChecked === true) < 0) {
      return true;
    }
    return false;
  }

  @action
  public set = (payload: IInvestmentProfile) => {
    this.optionsExperience.set(payload.optionsExperience);
    this.stocksExperience.set(payload.stocksExperience);
    this.spreadsExperience.set(payload.spreadsExperience);

    const objectives = payload.investmentObjectives.map((objective) => ({
      value: objective as InvestmentObjective,
      isChecked: true,
    }));

    this.objectives.set(objectives);
  };

  public putRequest() {
    const {
      objectives,
      optionsExperience,
      stocksExperience,
      spreadsExperience,
    } = this;

    const { apiClient } = this.rootStore;

    if (!apiClient) {
      throw new Error("Could not connect to services.");
    }

    return {
      investmentObjectives: objectives
        .value!.filter((objective: any) => objective.isChecked)
        .map((item: any) => item.value),
      optionsExperience: optionsExperience.value!,
      spreadsExperience: spreadsExperience.value!,
      stocksExperience: stocksExperience.value!,
    };
  }
}

export default InvestmentProfile;
