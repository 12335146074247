import { useOktaAuth } from "@okta/okta-react";
import JwtDecode from "jwt-decode";

enum OktaGroup {
  SUBSCRIPTION_APPLICANT = "Subscription Applicants",
  APPLICANTS = "Applicants",
  TRADERS = "Traders",
  SUBSCRIBERS = "Subscribers",
}

interface ParsedToken {
  groups?: OktaGroup[];
}

const useOkta = () => {
  const { oktaAuth, authState } = useOktaAuth();

  const accessToken = authState?.accessToken?.accessToken;

  const decoded = accessToken ? JwtDecode<ParsedToken>(accessToken) : undefined;
  const groups = decoded?.groups;

  const isSubscriber = groups?.includes(OktaGroup.SUBSCRIBERS);
  const isSubscriberApplicant = groups?.includes(
    OktaGroup.SUBSCRIPTION_APPLICANT
  );
  const isTrader = groups?.includes(OktaGroup.TRADERS);
  const isTraderApplicant = groups?.includes(OktaGroup.APPLICANTS);

  return {
    oktaAuth,
    authState,
    decoded,
    isSubscriber,
    isSubscriberApplicant,
    isTrader,
    isTraderApplicant,
    accessTokenStr: accessToken,
  };
};

export default useOkta;
