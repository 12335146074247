import { computed, observable } from "mobx";

import { Application } from "stores/application/ApplicationRoot";
import FormField from "../helpers/FormField";

interface ITaxStatus {
  maritalStatus: string;
  numberOfDependents: string;
}

class TaxStatus {
  @computed
  public get dependentsAsInt() {
    if (this.dependents.value === undefined) {
      return undefined;
    }

    return Number.parseInt(this.dependents.value, 10);
  }

  @computed
  public get pageInvalid() {
    return (
      this.marital.value === "" ||
      this.dependents.value === undefined ||
      this.dependents.error
    );
  }
  public rootStore: Application;

  @observable
  public dependents = new FormField<string>(
    undefined,
    ["required", "between:0,19", "integer"],
    {
      between: "Looks like you may have made a typo.",
      required:
        "Please enter number of dependents. If you do not have any dependents enter 0.",
    }
  );

  @observable
  public marital = new FormField<string>("");

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  public set = (payload: ITaxStatus) => {
    this.marital.set(payload.maritalStatus);
    this.dependents.set(payload.numberOfDependents?.toString());
  };

  public putRequest() {
    const { marital, dependentsAsInt } = this;

    const { apiClient } = this.rootStore;

    if (!apiClient) {
      throw new Error("Could not connect to services.");
    }

    return {
      maritalStatus: marital.value!,
      numberOfDependents: dependentsAsInt!,
    };
  }
}

export default TaxStatus;
