import React, { FC, useContext } from "react";

import { observer } from "mobx-react-lite";
import { Select as MUISelect, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { MaritalStatus } from "core/application/Application.d";
import ApplicationContext from "stores/application/ApplicationRoot";
import TextfieldWithValidation from "components/UI/TextField/TextfieldWithValidation";

const useStyles = makeStyles({
	select: {
		"&:focus": {
			backgroundColor: "white",
		},
	},
	root: {
		width: "100%",
	},
});

interface IMaritialFormProps {}

const MaritialForm: FC<IMaritialFormProps> = () => {
	const classes = useStyles();

	const { taxStatus } = useContext(ApplicationContext);

	const setMarital = (e: string) => {
		taxStatus.marital.set(e);
	};

	const handleChange = (e: any) => {
		setMarital(e.target.value);
	};

	return (
		<TextfieldWithValidation
			customField={
				<MUISelect
					className={classes.root}
					classes={{ select: classes.select }}
					displayEmpty
					value={taxStatus.marital.value}
					onChange={handleChange}
					error={!!taxStatus.marital.errMsg}
				>
					<MenuItem value="">Select</MenuItem>
					{VALUES.map((value) => (
						<MenuItem key={value.label} value={value.value}>
							{value.label}
						</MenuItem>
					))}
				</MUISelect>
			}
			valid={!taxStatus.marital.errMsg && taxStatus.marital.value !== ""}
			error={taxStatus.marital.errMsg}
		/>
	);
};

const VALUES = [
	{ label: "Single", value: MaritalStatus.Single },
	{ label: "Married", value: MaritalStatus.Married },
	{ label: "Divorced", value: MaritalStatus.Divorced },
	{ label: "Widowed", value: MaritalStatus.Widowed },
];

export default observer(MaritialForm);
