import React from "react";

const SearchIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.6973 20.156L17.2573 15.6981C18.3875 14.2391 19.0333 12.4559 19.0333 10.5106C18.9526 5.80952 15.1584 2 10.4763 2C5.79415 2 2 5.80952 2 10.5106C2 15.2118 5.79415 19.0213 10.4763 19.0213C12.4137 19.0213 14.1897 18.3728 15.6428 17.2381L20.0827 21.696C20.4864 22.1013 21.2129 22.1013 21.6973 21.696C22.1009 21.2908 22.1009 20.6424 21.6973 20.156ZM10.4763 16.7518C7.00505 16.7518 4.26034 13.9149 4.26034 10.5106C4.26034 7.10638 7.08577 4.2695 10.4763 4.2695C13.8668 4.2695 16.6922 7.10638 16.6922 10.5106C16.6922 13.9959 13.9475 16.7518 10.4763 16.7518Z"
        fill="#333333"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.6973 20.156L17.2573 15.6981C18.3875 14.2391 19.0333 12.4559 19.0333 10.5106C18.9526 5.80952 15.1584 2 10.4763 2C5.79415 2 2 5.80952 2 10.5106C2 15.2118 5.79415 19.0213 10.4763 19.0213C12.4137 19.0213 14.1897 18.3728 15.6428 17.2381L20.0827 21.696C20.4864 22.1013 21.2129 22.1013 21.6973 21.696C22.1009 21.2908 22.1009 20.6424 21.6973 20.156ZM10.4763 16.7518C7.00505 16.7518 4.26034 13.9149 4.26034 10.5106C4.26034 7.10638 7.08577 4.2695 10.4763 4.2695C13.8668 4.2695 16.6922 7.10638 16.6922 10.5106C16.6922 13.9959 13.9475 16.7518 10.4763 16.7518Z"
        fill="black"
        fill-opacity="0.2"
      />
    </svg>
  );
};

export default SearchIcon;
