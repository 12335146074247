import React, { FC, useCallback, useContext, useState } from "react";

import { observer } from "mobx-react-lite";
import {
	Button,
	ButtonGroup,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";

import ApplicationContext from "stores/application/ApplicationRoot";
import { RiskTolerance } from "core/application/Application.d";
import { RISK_TOLERANCE_VALUES } from "core/constants/perferenceValues";
import TextfieldWithValidation from "components/UI/TextField/TextfieldWithValidation";
import ButtonGroupButton from "components/UI/new/ButtonGroupButton";

const RiskToleranceContainer: FC = () => {
	const { preferences } = useContext(ApplicationContext);

	const [warn, setWarn] = useState(false);

	const setRiskTolerance = (value: RiskTolerance) => {
		if (value !== RiskTolerance.HIGH) {
			setWarn(true);
		}

		preferences.riskTolerance.set(value);
	};

	const setWarnFalse = useCallback(() => {
		setWarn(false);
	}, []);
	return (
		<>
			<TextfieldWithValidation
				customField={
					<ButtonGroup fullWidth={true}>
						{RISK_TOLERANCE_VALUES.map((val) => (
							<ButtonGroupButton
								key={val.value}
								active={val.value === preferences.riskTolerance.value}
								onClick={() => setRiskTolerance(val.value)}
							>
								{val.label}
							</ButtonGroupButton>
						))}
					</ButtonGroup>
				}
				valid={
					!preferences.riskTolerance.errMsg &&
					preferences.riskTolerance.value !== undefined
				}
				error={preferences.riskTolerance.errMsg}
			/>

			<Dialog open={warn}>
				<DialogContent>
					<Typography gutterBottom={true} variant="h5">
						Please note:
					</Typography>

					<Typography paragraph={true}>
						While options may be used for various investment objectives, they do
						involve risk and may expose investors to potentially significant
						losses. Please carefully consider your risk tolerance before using
						options.
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={setWarnFalse}
						size="large"
						fullWidth={true}
						color="primary"
						variant="contained"
					>
						<Check fontSize="small" />
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default observer(RiskToleranceContainer);
