export enum AnalyticEvents {
  application_submitted_start = "application_submitted_start",
  application_submitted_success = "Application Submitted",
  application_submitted_error = "application_submitted_error",
}

const trackEvent = (name: AnalyticEvents) => {
  if (!(window as any).gtag) {
    return;
  }

  (window as any)?.gtag("event", name, {
    page_path: window.location?.pathname,
  });
};

export default trackEvent;
