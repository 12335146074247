import React, { FC, useContext, useMemo } from "react";

import { observer } from "mobx-react-lite";

import { Box, FormControlLabel, Switch, Typography } from "@material-ui/core";
import { Select as MUISelect, MenuItem } from "@material-ui/core";

import ApplicationContext from "stores/application/ApplicationRoot";
import LocationIcon from "components/UI/icons/LocationIcon";
// import SectionHeader from "components/UI/Typography/SectionHeader";

import { makeStyles } from "@material-ui/core/styles";
import CountriesContext from "stores/Countries";
import SectionHeader from "components/UI/Typography/SectionHeader";
import TextfieldWithValidation from "components/UI/TextField/TextfieldWithValidation";

const useStyles = makeStyles({
	hint: {
		fontSize: 12,
		lineHeight: "16px",
		color: "#828282",
		marginLeft: 10,
	},
	link: {
		color: "#0066FF",
		textDecoration: "none",
		opacity: 1,
		transition: "opacity 0.2s ease-in-out",
		textTransform: "none",
		"&:hover": {
			opacity: 0.8,
		},
	},
	select: {
		"&:focus": {
			backgroundColor: "white",
		},
	},
	root: {
		width: "100%",
	},
});

const CitizenshipContainer: FC = observer(() => {
	const { citizenship } = useContext(ApplicationContext);

	const styles = useStyles();

	const countries = useContext(CountriesContext);

	const onUSCitizenshipSelection = (e: any, isUSCitizen: boolean) => {
		citizenship.isUSCitizen.set(isUSCitizen);

		if (isUSCitizen === false) {
			citizenship.country.set("");
		}

		if (isUSCitizen) {
			citizenship.country.set("USA");
		}
	};

	const onCountrySelection = (e: any) => {
		const country = e.target.value;

		citizenship.country.set(country);
	};

	const onUsPermanentResidentSelection = (e: any, resident: boolean) => {
		citizenship.usPermanentResident.set(resident);
	};

	const memoizedCountries = useMemo(
		() =>
			countries.value.map((item) => {
				return { value: item.code, label: item.name };
			}),
		[countries]
	);

	return (
		<>
			<FormControlLabel
				control={
					<Switch
						onChange={onUSCitizenshipSelection}
						value={citizenship.isUSCitizen.value}
						checked={citizenship.isUSCitizen.value}
						color="primary"
					/>
				}
				label={citizenship.citizenLabel}
			/>
			{!citizenship.isUSCitizen.value && (
				<>
					<Box pt={3}>
						<Typography variant="body1">
							{pageContent.descriptionTwo}
						</Typography>

						<TextfieldWithValidation
							customField={
								<MUISelect
									className={styles.root}
									classes={{ select: styles.select }}
									displayEmpty
									value={citizenship.country.value}
									onChange={onCountrySelection}
									error={!!citizenship.country.errMsg}
								>
									<MenuItem value="">Select</MenuItem>
									{memoizedCountries.map((value) => (
										<MenuItem key={value.label} value={value.value}>
											{value.label}
										</MenuItem>
									))}
								</MUISelect>
							}
							valid={
								!citizenship.country.errMsg && citizenship.country.value !== ""
							}
							error={citizenship.country.errMsg}
						/>
					</Box>

					<Box pt={3}>
						<SectionHeader value={pageContent.descriptionThree} />
						<FormControlLabel
							control={
								<Switch
									onChange={onUsPermanentResidentSelection}
									value={citizenship.usPermanentResident.value}
									checked={citizenship.usPermanentResident.value}
									color="primary"
								/>
							}
							label={
								citizenship.usPermanentResident.value
									? "Yes, I am a US resident."
									: "No, I am not a US resident."
							}
						/>

						{!!citizenship.residentErrorMessage ? (
							<Box display="flex" alignItems={"center"} mt={3}>
								<LocationIcon fill="#DF5E6B" />

								<Typography className={styles.hint}>
									Apologies, Options AI is only available to US Residents. If
									you are not a US Resident you may switch to a{" "}
									<a
										className={styles.link}
										href="https://id.options.ai/subscribe?utm_source=brokerage_app"
										target="_self"
									>
										subscription account
									</a>{" "}
									if you wish.
								</Typography>
							</Box>
						) : null}
					</Box>
				</>
			)}
		</>
	);
});

const pageContent = {
	header: "Citizenship",
	description: `Are you a US Citizen? US federal law requires all brokerages to collect
  this information.`,
	descriptionTwo: `In which country do you currently hold citizenship?`,
	descriptionThree: `US resident?`,
};

export default CitizenshipContainer;
