import React, { FC } from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import MUIDialog from "@material-ui/core/Dialog";
import { IconButton } from "@material-ui/core";
import Close from "@material-ui/icons/Close";

interface StyleParams {
	noBackdrop: boolean;
}

const useStyles = makeStyles<Theme, StyleParams>({
	backdrop: {
		backgroundColor: (props) =>
			props.noBackdrop ? "transparent" : "rgba(0, 0, 0, 0.2)",
	},
	paper: {
		position: "relative",
		backgroundColor: "#fff",
		borderRadius: "10px",
		overflowY: "unset",
		"&.MuiPaper-elevation24": {
			boxShadow: "none",
		},
	},
	close: {
		position: "absolute",
		top: "-41px",
		right: 0,
		height: "36px",
		width: "36px",
		padding: 0,
		"&:hover": {
			backgroundColor: "transparent",
		},
	},
	icon: {
		height: "36px",
		width: "36px",
		fill: "#fff",
	},
});

interface Params {
	open?: boolean;
	onClose?: () => void;
	paperClassName?: string;
	noBackdrop?: boolean;
}

const Dialog: FC<Params> = React.memo(
	({ open = false, onClose, children, paperClassName, noBackdrop = false }) => {
		const styles = useStyles({ noBackdrop });

		return (
			<>
				<MUIDialog
					BackdropProps={{ className: styles.backdrop }}
					transitionDuration={{ enter: 350, exit: 350 }}
					onClose={onClose}
					open={open}
					PaperProps={{ className: `${styles.paper} ${paperClassName}` }}
				>
					{!noBackdrop && (
						<IconButton
							onClick={onClose}
							disableRipple
							className={styles.close}
						>
							<Close className={styles.icon} />
						</IconButton>
					)}

					{children}
				</MUIDialog>
			</>
		);
	}
);

export default Dialog;
