import React, { FC, CSSProperties } from "react";

import { Link, LinkProps } from "react-router-dom";
import theme from "pages/theme";

interface IRouterLinkProps extends LinkProps {}

const RouterLink: FC<IRouterLinkProps> = ({ children, ...props }) => (
  <Link style={linkStyle} {...props}>
    {children}
  </Link>
);

const linkStyle = {
  fontWeight: "initial",
  textDecoration: "none",
  color: theme.palette.primary.main,
} as CSSProperties;

export default RouterLink;
