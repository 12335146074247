import React, { FC, useContext } from "react";

import { observer } from "mobx-react-lite";

import TextfieldWithValidation from "components/UI/TextField/TextfieldWithValidation";
import ApplicationContext from "stores/application/ApplicationRoot";

interface Params {}

const CompanyName: FC<Params> = observer(() => {
	const application = useContext(ApplicationContext);
	const { employment } = application;

	const setCompanyName = (e: any) => {
		employment.addressComponents.name.set(e.target.value);
	};

	return (
		<TextfieldWithValidation
			onChange={setCompanyName}
			value={employment.addressComponents.name.value}
			valid={
				!employment.addressComponents.name.errMsg &&
				employment.addressComponents.name.value !== "" &&
				employment.addressComponents.name.value !== undefined &&
				employment.addressComponents.name.value.length >= 2
			}
			helperText={
				!employment.addressComponents.name.errMsg
					? "If you are self employed, you may use your business or first and last name."
					: undefined
			}
			label="Company Name"
			error={employment.addressComponents.name.errMsg}
		/>
	);
});

export default CompanyName;
