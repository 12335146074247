import React, { FC, useContext, useEffect, useRef } from "react";

import { Box, Fab } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import { ArrowDownward } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import ApplicationContext from "stores/application/ApplicationRoot";
import DocumentLinks from "components/UI/new/DocumentLinks";
import SectionDescription from "components/UI/Typography/SectionDescription";
import APP_PATHS from "pages/appPaths";
import ApplicationFormLayout from "containers/Layout/ApplicationFormLayout";
import ReviewPanel from "containers/Steps/ReviewPanel";
import SectionHeader from "components/UI/Typography/SectionHeader";

const useStyles = makeStyles((theme) => ({
	linksContainer: {
		scrollBehavior: "smooth",
		position: "relative",
		maxHeight: 300,
		overflow: "scroll",
		border: "1px solid #E7E7E7",
		padding: "8px 12px 0px",
	},
}));

interface IAgreePageProps extends RouteComponentProps { }

const AgreePageNew: FC<IAgreePageProps> = ({ history }) => {
	const { agreement, isSubscriber } = useContext(ApplicationContext);

	const classes = useStyles();

	const handleNext = () => {
		agreement.field.set(true);

		if (isSubscriber) {
			history.push("/steps/welcome", { from: true, upload: true });
			return;
		}

		history.push(APP_PATHS.review, { from: true });
	};

	function isInViewport(offset = 0) {
		const bottomElement = document.getElementById("bottom");
		const fullPageDiv = document.getElementById("fullPage");

		if (!bottomElement || !fullPageDiv) return false;

		const top = bottomElement.getBoundingClientRect().top;
		const divBottom = fullPageDiv.getBoundingClientRect().bottom;

		return top + offset >= 0 && top - offset <= divBottom;
	}

	useEffect(() => {
		const handleScroll = () => {
			const isBottom = isInViewport();

			const arrowDown = document.getElementById("arrow-down");
			if (!arrowDown) return;

			if (isBottom) {
				arrowDown.style.display = "none";
			} else {
				arrowDown.style.display = "flex";
			}
		};

		document.addEventListener("scroll", handleScroll);

		return () => {
			document.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const onDown = (e: any) => {
		const fullPageDiv = document.getElementById("fullPage");
		if (fullPageDiv) {
			fullPageDiv.scrollTop = fullPageDiv.scrollHeight;
		}
	};

	const buttonRef = useRef<any>();

	return (
		<>
			<ApplicationFormLayout
				step={4}
				isSubscriber={isSubscriber}
				onNext={handleNext}
				onNextDisabled={false}
				continueText="I Confirm and Agree"
				leftPanelChildren={<ReviewPanel />}
				formChildren={
					<>
						<SectionHeader value="Important Documents for Review and Consent" />
						<Box pb={2}>
							<SectionDescription
								value="Please take the time to carefully read and review each of the
                following documents which incorporate important disclosures,
                terms and agreements relating to your account with Options AI."
							/>
						</Box>
						<div id="fullPage" className={classes.linksContainer}>
							<DocumentLinks isSubscriber={isSubscriber} />
							<Box id="bottom" />
							<Box
								position="sticky"
								display="flex"
								justifyContent="flex-end"
								width="100%"
								id="arrow-down"
								bottom={24}
								right={24}
							>
								<Fab ref={buttonRef} onClick={onDown} color="primary">
									<ArrowDownward />
								</Fab>
							</Box>
						</div>

						<Box pt={4} pb={0}>
							<SectionDescription
								value="Please confirm that you have received, read and understood each
                of the documents above and that you agree to all of the terms
                and provisions set forth in each document."
							/>
						</Box>
					</>
				}
			/>
		</>
	);
};

export default observer(AgreePageNew);
