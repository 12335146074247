import { computed, observable, action } from "mobx";
import FormField from "stores/helpers/FormField";

import { Application } from "stores/application/ApplicationRoot";

class PhoneNumber {
  public rootStore: Application;

  // Phone Number
  @observable public number: FormField<string> = new FormField("", [
    "required",
    "telephone",
  ]);

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @computed public get pageinValid(): boolean {
    return this.number.value!.split("")[13] === "_" || !this.number.value;
  }

  @action
  public setPhoneInitial = (value: string) => {
    const cleanedValue = value.replace("+1", "");

    let newValue = cleanedValue.split("");

    newValue.splice(0, 0, "(");
    newValue.splice(4, 0, ")");

    newValue.splice(8, 0, "-");

    this.number.set(newValue.join(""));
  };

  @computed public get parsedNumber() {
    if (!this.number.value) {
      return undefined;
    }

    const cleanedString = this.number.value
      .replace("(", "")
      .replace(" ", "")
      .replace(")", "")
      .replace("-", "")
      .trim();

    const numValue = Number.parseInt(cleanedString, 10);

    return numValue;
  }

  public putRequest() {
    const { parsedNumber } = this;

    const { apiClient } = this.rootStore;

    if (!apiClient) {
      throw new Error("Could not connect to services.");
    }

    return {
      phoneNumber: parsedNumber!,
    };
  }
}

export default PhoneNumber;
