import React, { Fragment, useContext, useMemo } from "react";

import { RouteComponentProps, withRouter } from "react-router";
import { observer } from "mobx-react-lite";

import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";

import ApplicationContext from "stores/application/ApplicationRoot";
import { Edit } from "@material-ui/icons";
import getPageTitleFromPath from "core/getPageTitleFromPath";

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      border: "1px solid #E7E7E7",
      marginBottom: 30,
      borderRadius: 4,
    },

    cardContent: {
      padding: "35px 26px",
    },

    cardHeaderContainer: {
      backgroundColor: "#F8F8F8",
      borderRadius: 4,
      margin: "6px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "2px 22px",
    },
    cardHeader: {
      fontSize: "24px",
      fontWeight: 900,
      fontFamily: "Cerebri Sans",
      lineHeight: "63px",
    },

    editIconContainer: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& > caption": {
        fontSize: 18,
        fontWeight: 400,
        marginLeft: 10,
      },
      "& > *": {
        color: theme.palette.primary.main,
      },
    },
    sectionHeader: {
      fontFamily: "Cerebri Sans",
      fontWeight: 700,
      fontSize: 18,
      marginBottom: 4,
    },

    sectionValue: {
      fontSize: 18,
      fontWeight: 400,
    },

    sectionContainer: {
      marginBottom: 38,
    },
  })
);

const ReviewApplicationList = observer(({ history }: RouteComponentProps) => {
  const styles = useStyles();

  const { review } = useContext(ApplicationContext);

  const reviewByGroup = useMemo(() => {
    let groups: any = [];

    review.reviewApplication.forEach((section) => {
      const foundIndex = groups.findIndex((g: any) => g.path === section.path);

      // if the group didnt exist
      if (foundIndex === -1) {
        groups = [...groups, { path: section.path, sections: [section] }];
      } else {
        const foundGroup = groups[foundIndex];

        if (foundGroup.hide) {
          return;
        }

        foundGroup.sections = [...foundGroup.sections, section];
      }
    });

    return groups;
  }, [review.reviewApplication]);

  return (
    <Box px={2}>
      {reviewByGroup.map((group: any) => {
        function navigateToPath() {
          history.push(group.path, { returnUrl: "/review" });
        }

        return (
          <Box className={styles.card}>
            <div className={styles.cardHeaderContainer}>
              <p className={styles.cardHeader}>
                {getPageTitleFromPath(group.path)}
              </p>

              <span
                onClick={navigateToPath}
                className={styles.editIconContainer}
              >
                <Edit />
                <caption>Edit</caption>
              </span>
            </div>

            <Box className={styles.cardContent}>
              {group.sections.map((section: any) => {
                if (section.hide) {
                  return <Fragment key={section.title} />;
                }

                return (
                  <Box className={styles.sectionContainer}>
                    <Typography className={styles.sectionHeader}>
                      {section.title}
                    </Typography>

                    <Typography className={styles.sectionValue}>
                      {section.fields
                        .filter((field: any) => !field.hide)
                        .map((field: any) => field.value)
                        .join(" ")}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
});

export default withRouter(ReviewApplicationList);
