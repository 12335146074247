import React, { FC, useState, useMemo, useContext } from "react";

import { Box, useMediaQuery } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";

import InvestmentGoalsForm from "containers/new/InvestmentGoalsForm";
import ApplicationContext from "stores/application/ApplicationRoot";
import RiskTolerance from "containers/new/RiskTolerance";
import RouterLink from "components/UI/new/RouterLink";
import PREFERENCES_COPY from "core/content/preferences";
import InfoPanel from "components/UI/new/InfoPanel";
import SectionDescription from "components/UI/Typography/SectionDescription";
import SectionHeader from "components/UI/Typography/SectionHeader";
import ApplicationFormLayout from "containers/Layout/ApplicationFormLayout";
import SuitabilityPanel from "containers/Steps/SuitabilityPanel";
import APP_PATHS from "pages/appPaths";

interface IInvestmentGoalsProps
	extends RouteComponentProps<{}, {}, { returnUrl?: string; from?: boolean }> {}

const InvestmentGoalsNew: FC<IInvestmentGoalsProps> = ({
	history,
	location,
}) => {
	const application = useContext(ApplicationContext);

	const { investmentProfile } = application;

	const onNextDisabled =
		investmentProfile.investmentObjectivesInvalid ||
		application.loading ||
		!application.preferences.riskTolerance.value;

	const [returnUrl] = useState(location.state?.returnUrl);

	const handleOnNext = async () => {
		if (returnUrl === APP_PATHS.review) {
			await application.submitStepTwo();

			location.state.returnUrl = undefined;

			history.replace(APP_PATHS.review);
			return;
		}

		history.push(APP_PATHS.financialProfile, { from: true });
	};
	const hash = location.hash;

	const infoPanelContent = useMemo(() => {
		switch (hash) {
			case "#liquidity-needs":
				return PREFERENCES_COPY.liquidityNeeds;

			case "#time-horizon":
				return PREFERENCES_COPY.timeHorizon;

			case "#risk-tolerance":
				return PREFERENCES_COPY.riskTolerance;

			default:
				return undefined;
		}
	}, [hash]);

	const isDesktop = useMediaQuery("(min-width: 850px)");

	return (
		<ApplicationFormLayout
			step={3}
			loading={application.loading}
			onNext={handleOnNext}
			onNextDisabled={onNextDisabled}
			continueText="Next: Financial Profile"
			formChildren={
				<>
					<InvestmentGoalsForm />

					<Box pt={4}>
						<SectionHeader marginBottom={0} value="What is your tolerance?" />

						<SectionDescription
							value={
								<>
									What is your{" "}
									<RouterLink to="#risk-tolerance">risk tolerance</RouterLink>?
								</>
							}
						/>
					</Box>

					<Box mb={isDesktop ? 0 : 5}>
						<RiskTolerance />
					</Box>

					<InfoPanel
						open={!!hash}
						header={infoPanelContent?.infoHeader}
						description={infoPanelContent?.info}
					/>
				</>
			}
			leftPanelChildren={<SuitabilityPanel />}
		/>
	);
};

export default observer(InvestmentGoalsNew);
