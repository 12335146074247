import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles<Theme, { marginBottom?: number }>((theme) =>
	createStyles({
		root: {
			fontFamily: "Cerebri Sans",
			fontWeight: 700,
			fontSize: 20,
			marginBottom: (props) =>
				props.marginBottom !== undefined ? props.marginBottom : 10,
		},
	})
);

interface Props {
	value?: string | React.ReactElement;

	marginBottom?: number;
}

const SectionHeader = React.memo(({ value, marginBottom }: Props) => {
	const styles = useStyles({ marginBottom });

	return <Typography className={styles.root}>{value}</Typography>;
});

export default SectionHeader;
