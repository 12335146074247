import React, { FC, useContext, useState } from "react";

import { Box, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";

import PoliticalExposureContainer from "containers/Forms/PoliticalExposure";
import AffiliationContainer from "containers/Forms/Affiliation";
import ControlPersonContainer from "containers/Forms/ControlPerson";
import ApplicationContext from "stores/application/ApplicationRoot";
import RouterLink from "components/UI/new/RouterLink";
import InfoPanel from "components/UI/new/InfoPanel";
import SectionDescription from "components/UI/Typography/SectionDescription";
import APP_PATHS from "pages/appPaths";
import ApplicationFormLayout from "containers/Layout/ApplicationFormLayout";
import SuitabilityPanel from "containers/Steps/SuitabilityPanel";
import SectionHeader from "components/UI/Typography/SectionHeader";

interface IAffiliationsProps
	extends RouteComponentProps<{}, {}, { returnUrl?: string; from?: boolean; }> { }

const AffiliationsNew: FC<IAffiliationsProps> = ({ history, location }) => {
	const application = useContext(ApplicationContext);

	const { affiliation, politicalExposure, controlPerson } = application;

	const onNextDisabled =
		affiliation.pageInvalid ||
		politicalExposure.pageInvalid ||
		controlPerson.pageInvalid ||
		application.loading;

	const [returnUrl] = useState(location.state?.returnUrl);

	const handleNext = async () => {
		if (returnUrl === APP_PATHS.review) {
			await application.submitStepTwo();

			history.replace(APP_PATHS.review);
			return;
		}

		if (application.isSubscriber) {
			history.push(APP_PATHS.agree, { from: true });

			return;
		}

		history.push(APP_PATHS.employment, { from: true });
	};

	const hash = location.hash;

	const infoPanelContent =
		location.hash === "#securities-professional"
			? COPY.affiliations.infoPanel
			: undefined;

	return (
		<>
			<ApplicationFormLayout
				step={3}
				isSubscriber={application.isSubscriber}
				loading={application.loading}
				onNext={handleNext}
				onNextDisabled={onNextDisabled}
				continueText={application.isSubscriber ? "Next: Agreements" : "Next: Employment"}
				leftPanelChildren={<SuitabilityPanel
					copyOverride={application.isSubscriber ?
						{
							title: "Please confirm your non-pro investor status.",
							subTitle: 'Subscription services that include real-time data are currently not available for any individual meeting the definition of a professional investor.'
						} : undefined}
				/>}
				formChildren={
					<>
						{!application.isSubscriber && (
							<>
								<SectionHeader marginBottom={0} value="Political Exposure" />

								<SectionDescription
									value="Is the account maintained for a current or former politically
                exposed person or public official (includes US and foreign
                individiuals)?"
								/>

								<PoliticalExposureContainer />
							</>
						)}

						<Box pt={2}>
							<SectionHeader
								value={
									application.isSubscriber
										? "Are you a professional investor?"
										: "Affiliations"
								}
								marginBottom={0}
							/>

							<SectionDescription
								value={
									!application.isSubscriber ? (
										<>
											Are you, or anyone authorized to trade in your account, a{" "}
											<RouterLink to="#securities-professional">
												Securities Professional
											</RouterLink>
											?
										</>
									) : (
										<>
											Options AI does not support professional investors. Please
											certify your status as a non professional investor
										</>
									)
								}
							/>
						</Box>

						{application.isSubscriber && (
							<Box>
								<Box p={2} bgcolor="#fafafa" borderRadius={8}>
									<Typography gutterBottom={true}>
										What is a professional investor?
									</Typography>

									<Typography>
										A professional investor is defined as one of more of the
										following:
									</Typography>

									<ul style={{ fontFamily: "Inter" }}>
										<li>registered or qualified with</li>
										<ul>
											<li>the SEC or CFTC</li>
											<li>any securities exchange</li>
											<li>any commodities or futures contract market</li>
											<li>
												associate or regulatory body in the US or elsewhere
											</li>
										</ul>
										<li>engaged in providing investment advice</li>
										<li>engaged as an asset manager</li>
										<li>
											conduct trading on behalf or benefit of a corporation,
											partnership or other entity
										</li>
										<li>
											entered into any agreement to share the profit of trading
											activity or receive compensation for your trading
											activities
										</li>
									</ul>
								</Box>

								<Typography
									style={{
										marginTop: 64,
										color: "#828282",
										fontWeight: 400,
										fontSize: 18,
									}}
								>
									Are you a professional investor?
								</Typography>
							</Box>
						)}

						<AffiliationContainer />

						{!application.isSubscriber && (
							<>
								<Box pt={2}>
									<SectionHeader marginBottom={0} value="Control Person" />
									<SectionDescription
										value=" Is the account holder a control person of a publicly traded company
    (Director, Officer, or 10% Stock Holder)."
									/>
								</Box>

								<ControlPersonContainer />
							</>
						)}

						<InfoPanel
							open={!!hash}
							header={infoPanelContent?.title}
							description={infoPanelContent?.description}
						/>
					</>
				}
			/>
		</>
	);
};

const COPY = {
	affiliations: {
		infoPanel: {
			title: "Securities Professional",
			description: `A securities professional is someone employed by, registered or associated with a broker dealer firm, a financial services regulator, a securities exchange or a member of a securities exchange or someone acting as an investment advisor.
      
      If you are not sure, then you are probably not affiliated.`,
		},
	},
};

export default observer(AffiliationsNew);
