import { action, observable } from "mobx";
import { createContext } from "react";

interface IStateItem {
  code: string;
  name: string;
}

class States {
  @observable
  public value: IStateItem[] = [];

  @action
  public set = (states: IStateItem[]) => {
    this.value = states;
  };
}

const StatesContext = createContext(new States());

export default StatesContext;
