import React, { FC, useRef, useContext, useState, useEffect } from "react";

import { format } from "date-fns";
import { Grid, Box, RootRef, IconButton } from "@material-ui/core";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { Close } from "@material-ui/icons";

import TextField from "components/UI/TextField/TextField";
import ErrorField from "components/UI/Typography/ErrorField";
import ApplicationContext from "stores/application/ApplicationRoot";
import TextfieldWithValidation from "components/UI/TextField/TextfieldWithValidation";

export const SERVER_RESPONSE_DOB = "****-**-**";

const DateOfBirth: FC = (props) => {
	const { dob } = useContext(ApplicationContext);
	const [date, setDate] = useState("");
	const [month, setMonth] = useState("");
	const [year, setYear] = useState("");

	const input1: any = useRef();
	const input2: any = useRef();
	const input3: any = useRef();

	const handleKeyDownInput2 = (e: any) => {
		if (e.which === 8 && date.length < 1) {
			input1.current?.focus();
		}
	};

	const handleKeyDownInput1 = (e: any) => {
		if (e.which === 8 && month.length < 1) {
			input2.current?.focus();
		}
	};

	const handleKeyDownInput3 = (e: any) => {
		if (e.which === 8 && year.length < 1) {
			input2.current?.focus();
		}
	};

	const handleDateChange = (event: any) => {
		const value = event.target.value as string;

		setDate(value);

		if (value.length > 1) {
			input3.current?.focus();
		}
	};

	const handleMonthChange = (event: any) => {
		const value = event.target.value as string;

		if (value.length < 3) {
			setMonth(value);
		}

		if (value === "") {
			input1.current?.focus();
		}

		if (value.length > 1) {
			input2.current?.focus();
		}
	};

	const handleYearChange = (event: any) => {
		const value = event.target.value as string;

		if (value.length < 5) {
			setYear(value);
		}
	};

	useEffect(() => {
		importDateOfBirth();
		/* eslint-disable-next-line  */
	}, []);

	useEffect(() => {
		if (dob.dateOfBirth.value === SERVER_RESPONSE_DOB) {
			return;
		}

		if (year.length === 4 && date.length > 0 && month.length > 0) {
			const dateString = DateTime.fromObject({
				month: Number.parseInt(month, 10),
				day: Number.parseInt(date, 10),
				year: Number.parseInt(year, 10),
			});

			console.log("setting", dateString.toISODate());

			dob.dateOfBirth.set(dateString.toISODate());
		}
		/* eslint-disable-next-line  */
	}, [date, month, year]);

	const importDateOfBirth = () => {
		if (!dob.dateOfBirth.value) {
			return;
		}

		if (dob.dateOfBirth.value !== SERVER_RESPONSE_DOB) {
			const datePieces = format(dob.dateOfBirth.value, "DD-MM-YYYY").split("-");

			setDate(datePieces[0]);

			setMonth(datePieces[1]);

			setYear(datePieces[2]);
		}

		if (dob.dateOfBirth.value === SERVER_RESPONSE_DOB) {
			setDate("**");
			setMonth("**");
			setYear("****");
		}
	};

	const removeServerResponse = () => {
		dob.dateOfBirthServer.set("");
	};

	console.log(
		"from page: errors: ",
		dob.dateOfBirth.error,
		dob.dateOfBirth.errMsg
	);

	return (
		<>
			{!dob.dateOfBirthServer.value && (
				<Grid container={true} justify="space-between">
					<Box width="27%">
						<TextfieldWithValidation
							hideErrorMessage
							inputRef={input1}
							placeholder="MM"
							autoFocus={true}
							fullWidth={true}
							type="number"
							error={!!dob.dateOfBirth.errMsg}
							value={month}
							onKeyDown={handleKeyDownInput1}
							onChange={handleMonthChange}
							showValidation={false}
						/>
					</Box>

					<Box width="27%">
						<TextfieldWithValidation
							inputRef={input2}
							hideErrorMessage
							placeholder="DD"
							error={!!dob.dateOfBirth.errMsg}
							onKeyDown={handleKeyDownInput2}
							fullWidth={true}
							type="number"
							value={date}
							onChange={handleDateChange}
							showValidation={false}
						/>
					</Box>

					<Box width="38%">
						<TextfieldWithValidation
							inputRef={input3}
							placeholder="YYYY"
							hideErrorMessage
							onKeyDown={handleKeyDownInput3}
							fullWidth={true}
							error={!!dob.dateOfBirth.errMsg}
							type="number"
							value={year}
							onChange={handleYearChange}
							valid={
								!dob.dateOfBirth.errMsg &&
								!dob.pageInvalid &&
								month.length === 2 &&
								date.length === 2 &&
								year.length === 4
							}
						/>
					</Box>
				</Grid>
			)}

			{dob.dateOfBirthServer.value !== "" && (
				<>
					<Box pb={1}>
						<Grid container={true} justify="space-between">
							<Box width="27%">
								<RootRef rootRef={input1}>
									<TextField
										disabled={true}
										placeholder="MM"
										fullWidth={true}
										value={dob.dateOfBirthServerComponents?.month}
									/>
								</RootRef>
							</Box>

							<Box width="27%">
								<RootRef rootRef={input2}>
									<TextField
										disabled={true}
										placeholder="DD"
										fullWidth={true}
										value={dob.dateOfBirthServerComponents?.day}
									/>
								</RootRef>
							</Box>

							<Box position="relative" width="38%">
								<RootRef rootRef={input3}>
									<TextField
										disabled={true}
										placeholder="YYYY"
										fullWidth={true}
										value={dob.dateOfBirthServerComponents?.year}
									/>
								</RootRef>

								<Box position="absolute" top="31%" right={0}>
									<IconButton onClick={removeServerResponse}>
										<Close fontSize="small" color="error" />
									</IconButton>
								</Box>
							</Box>
						</Grid>
					</Box>
				</>
			)}

			<ErrorField value={dob.dateOfBirth.errMsg} />
		</>
	);
};

export default observer(DateOfBirth);
