import { AnnualIncome, WorthValues } from "core/application/Application";
import { action, computed, observable } from "mobx";
import FormField from "../helpers/FormField";
import { Application } from "./ApplicationRoot";

interface IFinancialSuitability {
  annualIncome: string;
  totalNetWorth: string;
  liquidNetWorth: string;
  federalTaxBracketPercent: string;
}

class FinancialSuitability {
  public rootStore: Application;

  @observable
  public annualIncome = new FormField<AnnualIncome>(undefined, ["required"], {
    required: "This is a required field",
  });

  @observable
  public liquidWorth = new FormField<WorthValues>(undefined, ["required"], {
    required: "This is a required field",
  });

  @observable
  public totalNetWorth = new FormField<WorthValues>(undefined, ["required"], {
    required: "This is a required field",
  });

  @computed
  public get totalLessThanLiquid() {
    if (!this.liquidWorth.value || !this.totalNetWorth.value) {
      return false;
    }

    const liquid = this.liquidWorth.value.split("-")[1];
    const total = this.totalNetWorth.value.split("-")[1];

    if (!liquid || !total) {
      return false;
    }

    const liquidParsed = parseInt(liquid, 10);
    const totalParsed = parseInt(total, 10);

    return totalParsed < liquidParsed;
  }

  @computed
  public get totalLessThanLiquidMessage() {
    if (!this.totalLessThanLiquid) {
      return undefined;
    }

    return "Your total worth can not be less than your liquid worth.";
  }

  @computed
  public get pageInvalid() {
    const { annualIncome, liquidWorth, totalNetWorth } = this;
    return (
      !annualIncome.value ||
      !liquidWorth.value ||
      !totalNetWorth.value ||
      this.totalLessThanLiquid
    );
  }

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @action
  public set = (payload: IFinancialSuitability) => {
    this.annualIncome.set(payload.annualIncome as AnnualIncome);
    this.liquidWorth.set(payload.liquidNetWorth as WorthValues);
    this.totalNetWorth.set(payload.totalNetWorth as WorthValues);
  };

  public putRequest() {
    const { annualIncome, totalNetWorth, liquidWorth } = this;

    const { apiClient } = this.rootStore;

    if (!apiClient) {
      throw new Error("Could not connect to services.");
    }

    return {
      annualIncome: annualIncome.value!,
      totalNetWorth: totalNetWorth.value!,
      liquidNetWorth: liquidWorth.value!,
    };
  }
}

export default FinancialSuitability;
