import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";

import { useOktaAuth } from "@okta/okta-react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps, withRouter } from "react-router-dom";

import FullPageLoader from "components/UI/FullPageLoader/FullPageLoader";

// config
import OptionsAIApiClient from "core/api/client/apiClient";
import { ApplicationStatus } from "core/application/Application.d";
import { FieldRoute } from "pages/navRoutes";

import initializeAxios from "core/api/client/initializeAxios";
import ApplicationContext, {
  ApplicationType,
} from "stores/application/ApplicationRoot";
import CountriesContext from "stores/Countries";
import StatesContext from "stores/States";
import * as Sentry from "@sentry/react";
import jwtDecode from "jwt-decode";
import useQueryString from "utils/useQueryString";
import { REACT_APP_ID } from "core/constants";

export const ApplicationContainer: FunctionComponent<RouteComponentProps> =
  observer(({ children, history }) => {
    const { oktaAuth } = useOktaAuth();
    const query = useQueryString() as {
      registrationType: string;
      overrideRoute?: string;
    };

    const states = useContext(StatesContext);
    const countries = useContext(CountriesContext);
    const applicationContext = useContext(ApplicationContext);

    const [applicationPopulated, setApplicationPopulated] = useState(false);

    // application client
    const [applicationClient, setAppClient] = useState<
      OptionsAIApiClient | undefined
    >(undefined);

    const [user, setUser] = useState<boolean>(false);

    const initializeApiClient = async () => {
      const token = await oktaAuth.getAccessToken();

      if (!token) {
        window.location.href = REACT_APP_ID;
        return;
      }

      const axios = initializeAxios(token);

      const decodedToken = jwtDecode<{ groups?: string[]; sub?: string; oai?: string; }>(token);

      const groups = decodedToken.groups;
      const isTrader = groups?.includes("Traders");
      const isSubscriber = groups?.includes("Subscribers");
      const isTraderApplicant = groups?.find((g) => g === "Applicants");
      const isSubscriberApplicant = groups?.includes("Subscription Applicants");

      console.log('sub', decodedToken.sub);

      Sentry.setUser({
        email: decodedToken.sub,
        accountId: decodedToken.oai,
        groups: decodedToken.groups
      });

      console.log('groups', groups);

      if (isSubscriber && !isTraderApplicant) {
        throw new Error("Unauthorized user");
      }

      if (isTrader) {
        throw new Error("Unauthorized user");
      }

      if (query.registrationType) {
        applicationContext.setApplicationType(
          query.registrationType as ApplicationType
        );
      } else if (isSubscriberApplicant) {
        applicationContext.setApplicationType(ApplicationType.SUBSCRIPTION);
      } else {
        applicationContext.setApplicationType(ApplicationType.BROKERAGE);
      }

      const client = await new OptionsAIApiClient(axios);

      setAppClient(client);

      applicationContext.setApiClient(client);
    };

    const ensureUserExists = async () => {
      try {
        await applicationClient?.getUser();

        setUser(true);
      } catch (error) {
        const { response } = error as any;
        const { status } = response;

        if (status === 401) {
          const SIGN_IN_URL = process.env.REACT_APP_ID!;

          window.location.href = `${SIGN_IN_URL}?redirect=${window.location.href}`;
          return;
        }

        if (status !== 200) {
          await applicationClient?.createUser();

          ensureUserExists();
        }
      }
    };

    useEffect(() => {
      initializeApiClient();

      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (!applicationClient) {
        return;
      }

      ensureUserExists();

      // eslint-disable-next-line
    }, [applicationClient]);

    useEffect(() => {
      if (!applicationClient || !user || !applicationContext.applicationType) {
        return;
      }

      initializeApplicationContext();
      // eslint-disable-next-line
    }, [applicationClient, user, applicationContext.applicationType]);

    // initialize the api client and write initial data to application store
    const initializeApplicationContext = async () => {
      try {
        const client = applicationContext.apiClient;

        if (!client) {
          throw new Error("client not set up");
        }

        const applicationPromise = await client.getApplication();
        const statesListPromise = await client.getUSStates();
        const countryListPromise = await client.getCountries();

        const [
          { data: application },
          { data: statesList },
          { data: countryList },
        ] = await Promise.all([
          applicationPromise,
          statesListPromise,
          countryListPromise,
        ]);

        states.set(statesList || []);
        countries.set(countryList || []);

        // if the application has been finished route to complete
        if (
          (application.status !== ApplicationStatus.INPROGRESS &&
            applicationContext.applicationType === ApplicationType.BROKERAGE) ||
          (application.subscriptionStatus === "SUBMITTED" &&
            applicationContext.applicationType === ApplicationType.SUBSCRIPTION)
        ) {
          setApplicationPopulated(true);
          history.replace(FieldRoute.COMPLETE);
          return;
        }


        console.log('application', application);




        // resume from last path
        const resumeAtRoute =
          applicationContext.populateApplication(application);

        setApplicationPopulated(true);

        const override = query.overrideRoute;

        history.replace(
          override ? `/${override}?overrideRoute=${override}` : resumeAtRoute
        );
      } catch (error) {
        if (error.response) {
          const { response } = error;
          const { status } = response;

          switch (status) {
            case 401: {
              const SIGN_IN_URL = process.env.REACT_APP_ID!;

              window.location.href = `${SIGN_IN_URL}?redirect=${window.location.href}`;
              return;
            }

            case 404: {
              await applicationContext.apiClient?.createNewApplication();

              initializeApplicationContext();
              return;
            }

            default: {
              Sentry.captureException(error);

              window.location.href = "/500";
              return;
            }
          }
        }
      }
    };

    if (!applicationClient || !user || !applicationPopulated) {
      return <FullPageLoader />;
    }

    return (
      <div style={{ height: "100%" }} data-testid="intialize-application">
        {children}
      </div>
    );
  });

export default withRouter(ApplicationContainer);
