import React, { FC, useMemo } from "react";

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

interface ITextFieldProps {
  label?: string;
  value?: string;
  onChange?: (e: any) => void;
  type?: string;
  fullWidth?: boolean;
  multiline?: boolean;
  placeholder?: string;
  onKeyDown?: (e: any) => void;
  autoFocus?: boolean;
  disabled?: boolean;
  preventScroll?: boolean;
  error?: string;
  useRawEvent?: boolean;
  Action?: React.ReactNode;
  ref?: any;
}

const useStyles = makeStyles<Theme, { Action?: boolean }>((theme) =>
  createStyles({
    root: {
      width: (props) => (props.Action ? "85%" : "100%"),
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "40px",
      paddingLeft: 20,
      paddingTop: 5,
      paddingBottom: 5,
      borderRadius: 5,
      border: "1px solid #bdbdbd",
      marginTop: 12,
      marginBottom: 25,
      "&::placeholder": {
        color: "#bdbdbd",
      },

      "&:focus": {
        border: "1px solid #bdbdbd",
        outline: "none",
      },
    },

    error: {
      marginBottom: 25,
      marginTop: (props) => (props.Action ? 0 : -12.5),
      fontSize: 13,
      fontWeight: 500,
      width: "100%",
      flex: 1,
      display: "block",
    },
  })
);

const TextField: FC<ITextFieldProps> = ({
  value = "",
  onChange,
  type = "text",
  placeholder,
  onKeyDown,
  autoFocus,
  preventScroll = false,
  disabled = false,
  error,
  useRawEvent,
  Action,
  ref,
  ...props
}) => {
  const styles = useStyles({ Action: !!Action });

  const id = useMemo(
    () => `formfield${Math.random() * 100}${Math.random() * 100}`,
    []
  );

  const executeScroll = (e: any) => {
    if (preventScroll) {
      return;
    }

    const element = document.getElementById(`${id}`);

    element?.scrollIntoView({
      behavior: "smooth",
    });

    if (!element) {
      return;
    }

    const parent = document.getElementById("scrolling_div");

    if (!parent) {
      return;
    }

    parent.scrollTo({
      top: element.getBoundingClientRect().y,
      behavior: "smooth",
    });
  };

  const handleBlur = () => {
    if (!!value) {
      return;
    }

    if (useRawEvent) {
      onChange?.({ target: { value: "" } });
      return;
    }

    onChange?.("");
  };

  return (
    <>
      <input
        onBlur={handleBlur}
        className={styles.root}
        id={id}
        onFocus={executeScroll}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        value={value}
        onChange={(e: any) => {
          if (useRawEvent) {
            onChange?.(e);
            return;
          }

          onChange?.(e?.target?.value || "");
        }}
        type={type}
        disabled={disabled}
        autoComplete="new-password"
        {...props}
        ref={(node) => {
          if (node) {
            ref = node;
          }
        }}
      ></input>

      {!!Action && <Box width="15%">{Action}</Box>}

      {!!error && (
        <Typography className={styles.error} color="error">
          {error}
        </Typography>
      )}
    </>
  );
};

export default TextField;
