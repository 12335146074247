import React, { FC, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box, useMediaQuery } from "@material-ui/core";

import OptionsAiLogo from "components/UI/icons/OptionsAiLogo";
import ProgressBar from "components/Application/ProgressBar";
import ApplicationAboutPageCollapse from "containers/ApplicationAboutPageCollapse";
import FullPageLoader from "components/UI/FullPageLoader/FullPageLoader";
import PageNavigationNew from "components/UI/new/PageNavigationNew";

const useStyles = makeStyles({
	leftPanelContainer: {
		backgroundColor: "#0066FF",
		height: "100%",
		width: "42%",
		minWidth: "42%",
		padding: "40px 35px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	leftPanelChildContainer: {
		padding: "60px 10% 30px",
		width: "100%",
		flex: 1,
		display: "flex",
		flexDirection: "column",
		"& > :first-child": {
			marginBottom: 70,
		},
	},
	formContainer: {
		flex: 1,
		maxHeight: "100vh",
		overflowY: "scroll",
	},
	formContainerContent: {
		width: "100%",
		minHeight: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		padding: "0 15%",
	},
	mainContainer: {
		display: "flex",
		height: "100vh",
	},
	mobileContainer: {
		padding: "0px 11% 40px",
		"& > :first-child": {
			marginBottom: 25,
		},
	},
	buttonContainer: {
		marginTop: "40px",
	},
});

interface Params {
	formChildren: React.ReactNode;
	leftPanelChildren: React.ReactNode;
	hideMobileAbout?: boolean;
	step?: number;
	loading?: boolean;
	continueText?: string;
	onNext?: () => void;
	onNextDisabled?: boolean;
	onBackDisabled?: boolean;
	hasContinue?: boolean;
	noNavButtonMargin?: boolean;
	isSubscriber?: boolean;
}

const ApplicationFormLayout: FC<Params> =
	({
		formChildren,
		leftPanelChildren,
		hideMobileAbout,
		step = 1,
		loading,
		continueText,
		hasContinue,
		onBackDisabled,
		onNext,
		onNextDisabled,
		noNavButtonMargin,
		isSubscriber
	}) => {
		const classes = useStyles();

		useEffect(() => {
			window.scrollTo({ top: 0 });
		}, []);

		const isDesktop = useMediaQuery("(min-width: 850px)");

		if (loading) {
			return <FullPageLoader />;
		}


		console.log('application form - is subscriber', isSubscriber);

		return (
			<>
				{isDesktop ? (
					<div className={classes.mainContainer}>
						<div className={classes.leftPanelContainer}>
							<Box width="100%">
								<OptionsAiLogo
									height={30}
									width={110}
									fill="#fff"
									aiFill="#2c74f6"
								/>
							</Box>

							<div className={classes.leftPanelChildContainer}>
								<ProgressBar
									isSubscriber={isSubscriber}
									step={step} />

								{leftPanelChildren}
							</div>
						</div>

						<div className={classes.formContainer}>
							<div className={classes.formContainerContent}>
								{formChildren}

								<Box
									className={
										noNavButtonMargin ? undefined : classes.buttonContainer
									}
									my={2}
									width={"100%"}
								>
									<PageNavigationNew
										continueText={continueText}
										hasContinue={hasContinue}
										onBackDisabled={onBackDisabled}
										onNextDisabled={onNextDisabled}
										onContinue={onNext}
									/>
								</Box>
							</div>
						</div>
					</div>
				) : (
					<div>
						<Box mb={6} pt={3} pl={3} width="100%">
							<OptionsAiLogo
								height={30}
								width={110}
								fill="#0066FF"
								aiFill="#fff"
							/>
						</Box>

						<div className={classes.mobileContainer}>
							<ProgressBar isSubscriber={isSubscriber} step={step} />

							{formChildren}

							<Box my={2}>
								<PageNavigationNew
									continueText={continueText}
									hasContinue={hasContinue}
									onBackDisabled={onBackDisabled}
									onNextDisabled={onNextDisabled}
									onContinue={onNext}
								/>
							</Box>

							{!hideMobileAbout ? (
								<ApplicationAboutPageCollapse>
									{leftPanelChildren}
								</ApplicationAboutPageCollapse>
							) : null}
						</div>
					</div>
				)}
			</>
		);
	};


export default ApplicationFormLayout;
