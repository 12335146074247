import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2C74F6",
    },
    secondary: {
      main: "#D0DBFF",
    },
    background: {
      default: "#F0F2F9",
    },
    text: {
      secondary: "#828282",
    },
  },
  overrides: {
    MuiButton: {
      // Name of the rule
      root: {
        textEmphasis: "none",
        textTransform: "none",
      },
      text: {
        // Some CSS
        color: "white",
      },
    },
    MuiInputBase: {
      input: {
        "&::placeholder": {
          color: "red",
        },
      },
    },
    MuiTextField: {
      root: {
        padding: "8px 0px",
        boxSizing: "border-box",
      },
    },

    MuiPaper: {
      root: {
        backgroundColor: "#fff",
      },
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: "Inter, sans-serif",
  },
});

theme = responsiveFontSizes(theme);

export default theme;
