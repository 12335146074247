export const sortCountriesByName = (
  listOfCountries: Array<{ name: string; code: string }>
) => {
  return listOfCountries.sort((a: any, b: any) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }

    return 0;
  });
};
