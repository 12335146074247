import { computed, observable } from "mobx";

import {
	ApplicationStatus,
	EmploymentStatus,
	MaritalStatus,
} from "core/application/Application.d";
import mapEnumTo from "core/application/mapEnum/mapEnumTo";
import { getEnumLabel } from "core/constants/perferenceValues";
import {
	INCOME_VALUES,
	WORTH_VALUES,
} from "core/constants/personalFinancialValues";
import { FieldRoute } from "pages/navRoutes";
import { Application } from "./ApplicationRoot";
import capitalize from "utils/capitalize";

export class Review {
	@observable public status?: ApplicationStatus;

	public rootStore: Application;

	constructor(rootStore: any) {
		this.rootStore = rootStore;
	}

	@computed public get reviewApplication() {
		return [
			{
				title: "Full Name",
				path: FieldRoute.FIRST_LAST,
				fields: [
					{ label: "First Name", value: this.rootStore.name.firstName.value },
					{ label: "Middle Name", value: this.rootStore.name.middle.value },
					{ label: "Last Name", value: this.rootStore.name.last.value },
				],
			},
			{
				title: "Phone Number",
				path: FieldRoute.PHONE,
				fields: [{ label: "", value: this.rootStore.phone.number.value }],
			},

			{
				title: "Primary Address",
				path: FieldRoute.ADDRESS,
				fields: [
					{
						label: "Street",
						value: this.rootStore.addresses.residential.streetAddress.value,
					},
					{
						label: "Address 2",
						value: this.rootStore.addresses.residential.streetAddress2.value,
						hide:
							!this.rootStore.addresses.residential.streetAddress2.value ||
							this.rootStore.addresses.residential.streetAddress2.value.length <
								1,
					},
					{
						label: "City",
						value: this.rootStore.addresses.residential.city.value,
					},
					{
						label: "State",
						value: this.rootStore.addresses.residential.state.value,
					},
					{
						label: "Zip",
						value: this.rootStore.addresses.residential.zipCode.value,
					},
				],
			},
			{
				hide: !this.rootStore.addresses.useMailingAddress.value,
				title: "Mailing Address",
				path: FieldRoute.ADDRESS,
				fields: [
					{
						label: "Street",
						value: this.rootStore.addresses.mailing.streetAddress.value,
					},
					{
						label: "Address 2",
						value: this.rootStore.addresses.mailing.streetAddress2.value,
						hide:
							!this.rootStore.addresses.mailing.streetAddress2.value ||
							this.rootStore.addresses.mailing.streetAddress2.value.length < 1,
					},
					{ label: "City", value: this.rootStore.addresses.mailing.city.value },
					{
						label: "State",
						value: this.rootStore.addresses.mailing.state.value,
					},
					{
						label: "Zip",
						value: this.rootStore.addresses.mailing.zipCode.value,
					},
				],
			},
			{
				title: "Date of Birth",
				path: FieldRoute.DOB,
				fields: [{ label: "", value: "**-**-****" }],
			},

			{
				title: "Social Security Number",
				path: FieldRoute.SSN,
				fields: [
					{
						label: "",
						value: "***-**-****",
					},
				],
			},
			{
				title: "US Citizen",
				path: FieldRoute.CITIZENSHIP,
				fields: [
					{
						label: "US Citizen",
						value: this.rootStore.citizenship.isUSCitizen.value ? "Yes" : "No",
					},
					{
						label: "Country",
						value: this.rootStore.citizenship.country.value,
						hide: this.rootStore.citizenship.isUSCitizen.value,
					},
					{
						label: "US Resident",
						value: this.rootStore.citizenship.usPermanentResident.value
							? "Yes"
							: "No",
						hide: this.rootStore.citizenship.isUSCitizen.value,
					},
				],
			},
			{
				title: "Marital Status",
				path: FieldRoute.TAX_STATUS,
				fields: [
					{
						label: "Maritial Status",
						value: mapEnumTo.readableMaritalStatus(
							this.rootStore.taxStatus.marital.value as MaritalStatus
						),
					},
				],
			},
			{
				title: "Dependents",
				path: FieldRoute.TAX_STATUS,
				fields: [
					{
						label: "Dependents",
						value: this.rootStore.taxStatus.dependents.value,
					},
				],
			},

			{
				title: "Stock",
				path: FieldRoute.KNOWLEDGE,
				fields: [
					{
						label: "Stocks",
						value: capitalize(
							this.rootStore.investmentProfile.stocksExperience.value
						),
					},
				],
			},
			{
				title: "Calls & Puts",
				path: FieldRoute.KNOWLEDGE,
				fields: [
					{
						label: "Call and Put Options",
						value: capitalize(
							this.rootStore.investmentProfile.optionsExperience.value
						),
					},
				],
			},
			{
				title: "Investment Goals",
				path: FieldRoute.GOALS,
				fields: [
					{
						label: "Objectives",
						value: this.rootStore.investmentProfile.objectives.value
							?.map((objective: any) => {
								if (objective.isChecked) {
									return mapEnumTo.readableObjective(objective.value);
								}
								return undefined;
							})
							.filter((e) => e !== undefined)
							.join(", "),
					},
				],
			},

			{
				title: "Risk Tolerance",
				path: FieldRoute.GOALS,
				fields: [
					{
						label: "Risk Tolerance",
						value: capitalize(
							this.rootStore.preferences.riskTolerance.value?.replace("_", " ")
						),
					},
				],
			},

			{
				title: "Annual Income",
				path: FieldRoute.FINANCIAL,
				fields: [
					{
						label: "Annual Income",
						value: getEnumLabel(
							this.rootStore.financial.annualIncome.value!,
							INCOME_VALUES
						),
					},
				],
			},
			{
				title: "Total Net Worth",
				path: FieldRoute.FINANCIAL,
				fields: [
					{
						label: "Total Net Worth",
						value: getEnumLabel(
							this.rootStore.financial.totalNetWorth.value!,
							WORTH_VALUES
						),
					},
				],
			},
			{
				title: "Liquid Worth",
				path: FieldRoute.FINANCIAL,
				fields: [
					{
						label: "Liquid Worth",
						value: getEnumLabel(
							this.rootStore.financial.liquidWorth.value!,
							WORTH_VALUES
						),
					},
				],
			},
			{
				title: "Liquidity Needs",
				path: FieldRoute.FINANCIAL,
				fields: [
					{
						label: "Liquidity Needs",
						value: capitalize(
							this.rootStore.preferences.liquidityNeeds.value?.replace("_", " ")
						),
					},
				],
			},
			{
				title: "Time Horizon",
				path: FieldRoute.FINANCIAL,
				fields: [
					{
						label: "Time Horizon",
						value: capitalize(
							this.rootStore.preferences.timeHorizon.value?.replace("_", " ")
						),
					},
				],
			},

			{
				title: "Political Exposure",
				path: FieldRoute.POLITICAL_EXPOSURE,
				fields: [
					{
						label: "",
						value: this.rootStore.politicalExposure.isPoliticallyExposed.value
							? "Yes"
							: "No",
					},
					{
						label: "Organizations",
						value: this.rootStore.politicalExposure.organizations.value,
						hide: !this.rootStore.politicalExposure.isPoliticallyExposed.value,
					},
					{
						label: "Relations",
						value: this.rootStore.politicalExposure.relations.value,
						hide: !this.rootStore.politicalExposure.isPoliticallyExposed.value,
					},
				],
			},

			{
				title: "Control Person",
				path: FieldRoute.CONTROL_PERSON,
				fields: [
					{
						label: "",
						value: this.rootStore.controlPerson.isControlPerson.value
							? "Yes"
							: "No",
					},
					{
						label: "Description",
						value: this.rootStore.controlPerson.description.value,
						hide: !this.rootStore.controlPerson.isControlPerson.value,
					},
				],
			},

			{
				title: "Affiliated",
				path: FieldRoute.AFFILIATION,
				fields: [
					{
						label: "",
						value: this.rootStore.affiliation.isAffiliated.value ? "Yes" : "No",
					},
					{
						label: "Description",
						value: this.rootStore.affiliation.description.value,
						hide: !this.rootStore.affiliation.isAffiliated.value,
					},
				],
			},

			{
				title: "Status",
				path: FieldRoute.EMPLOYMENT,
				fields: [
					{
						label: "Status",
						value: mapEnumTo.genericCleanedEnum(
							this.rootStore.employment.employmentStatus
								.value as EmploymentStatus
						),
					},
				],
			},

			{
				title: "Occupation",
				path: FieldRoute.EMPLOYMENT,
				hide:
					this.rootStore.employment.employmentStatus.value !==
						EmploymentStatus.EMPLOYED &&
					this.rootStore.employment.employmentStatus.value !==
						EmploymentStatus.SELFEMPLOYED,
				fields: [
					{
						label: "Occupation",
						value: this.rootStore.employment.occupation.value,
						hide:
							this.rootStore.employment.employmentStatus.value !==
								EmploymentStatus.EMPLOYED &&
							this.rootStore.employment.employmentStatus.value !==
								EmploymentStatus.SELFEMPLOYED,
					},
				],
			},

			{
				title: "Employer Name",
				path: FieldRoute.EMPLOYMENT,
				hide:
					this.rootStore.employment.employmentStatus.value !==
						EmploymentStatus.EMPLOYED &&
					this.rootStore.employment.employmentStatus.value !==
						EmploymentStatus.SELFEMPLOYED,
				fields: [
					{
						label: "Employer Name",
						value: this.rootStore.employment.addressComponents.name.value,
						hide:
							this.rootStore.employment.employmentStatus.value !==
								EmploymentStatus.EMPLOYED &&
							this.rootStore.employment.employmentStatus.value !==
								EmploymentStatus.SELFEMPLOYED,
					},
				],
			},

			{
				title: "Employer Address",
				path: FieldRoute.EMPLOYMENT,
				hide:
					this.rootStore.employment.employmentStatus.value !==
						EmploymentStatus.EMPLOYED &&
					this.rootStore.employment.employmentStatus.value !==
						EmploymentStatus.SELFEMPLOYED,
				fields: [
					{
						label: "Employer Address",
						value:
							this.rootStore.employment.addressComponents.streetAddress.value,
						hide:
							this.rootStore.employment.employmentStatus.value !==
								EmploymentStatus.EMPLOYED &&
							this.rootStore.employment.employmentStatus.value !==
								EmploymentStatus.SELFEMPLOYED,
					},
				],
			},

			{
				title: "Title / Position",
				path: FieldRoute.EMPLOYMENT,
				hide:
					this.rootStore.employment.employmentStatus.value !==
						EmploymentStatus.EMPLOYED &&
					this.rootStore.employment.employmentStatus.value !==
						EmploymentStatus.SELFEMPLOYED,
				fields: [
					{
						label: "Title / Position",
						value: this.rootStore.employment.position.value,
						hide:
							this.rootStore.employment.employmentStatus.value !==
								EmploymentStatus.EMPLOYED &&
							this.rootStore.employment.employmentStatus.value !==
								EmploymentStatus.SELFEMPLOYED,
					},
				],
			},

			{
				title: "Job Function",
				path: FieldRoute.EMPLOYMENT,
				hide:
					this.rootStore.employment.employmentStatus.value !==
						EmploymentStatus.EMPLOYED &&
					this.rootStore.employment.employmentStatus.value !==
						EmploymentStatus.SELFEMPLOYED,
				fields: [
					{
						label: "Job Function",
						value: this.rootStore.employment.jobFunction.value,
						hide:
							this.rootStore.employment.employmentStatus.value !==
								EmploymentStatus.EMPLOYED &&
							this.rootStore.employment.employmentStatus.value !==
								EmploymentStatus.SELFEMPLOYED,
					},
				],
			},

			{
				title: "Soure of Income",
				hide:
					this.rootStore.employment.employmentStatus.value !==
					EmploymentStatus.UNEMPLOYED,
				path: FieldRoute.EMPLOYMENT,
				fields: [
					{
						label: "Soure of Income",
						value: this.rootStore.employment.unEmployedDescription.value,
						hide:
							this.rootStore.employment.employmentStatus.value !==
							EmploymentStatus.UNEMPLOYED,
					},
				],
			},

			{
				title: "Trusted Person",
				path: FieldRoute.TRUSTED_PERSON,
				fields: [
					{
						label: "",
						value: this.rootStore.trustedPerson.hasTrustedPerson.value
							? "Yes"
							: "No",
						hide: this.rootStore.trustedPerson.hasTrustedPerson.value,
					},
					{
						label: "First Name",
						value: this.rootStore.trustedPerson.firstName.value,
						hide: !this.rootStore.trustedPerson.hasTrustedPerson.value,
					},
					{
						label: "Last Name",
						value: this.rootStore.trustedPerson.lastName.value,
						hide: !this.rootStore.trustedPerson.hasTrustedPerson.value,
					},
					// {
					//   label: "Phone Number",
					//   value: this.rootStore.trustedPerson.phoneNumber.value,
					//   hide: !this.rootStore.trustedPerson.hasTrustedPerson.value,
					// },
					// {
					//   label: "Email",
					//   value: this.rootStore.trustedPerson.email.value,
					//   hide: !this.rootStore.trustedPerson.hasTrustedPerson.value,
					// },
					// {
					//   label: "Address",
					//   value: this.rootStore.trustedPerson.address.streetAddress.value,
					//   hide: !this.rootStore.trustedPerson.hasTrustedPerson.value,
					// },
					// {
					//   label: "Address 2",
					//   value: this.rootStore.trustedPerson.address.streetAddress2.value,
					//   hide: !this.rootStore.trustedPerson.hasTrustedPerson.value,
					// },
					// {
					//   label: "City",
					//   value: this.rootStore.trustedPerson.address.city.value,
					//   hide: !this.rootStore.trustedPerson.hasTrustedPerson.value,
					// },
					// {
					//   label: "State",
					//   value: this.rootStore.trustedPerson.address.state.value,
					//   hide: !this.rootStore.trustedPerson.hasTrustedPerson.value,
					// },
					// {
					//   label: "Zip",
					//   value: this.rootStore.trustedPerson.address.zipCode.value,
					//   hide: !this.rootStore.trustedPerson.hasTrustedPerson.value,
					// },
				],
			},
			{
				title: "Important Documents",
				path: FieldRoute.AGREEMENT,
				fields: [
					{
						label: "All documents recieved, read & understood",
						value: this.rootStore.agreement.field.value
							? "Reviewed and Agreed"
							: "No",
					},
				],
			},
		];
	}
}
