import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import MUIButton, { ButtonProps } from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
	button: {
		height: "42px",
		borderRadius: "6px",
		color: theme.palette.primary.contrastText,
		fontSize: "14px",
		fontWeight: "600 !important" as any,
		textTransform: "none !important" as any,
		whiteSpace: "nowrap",
		opacity: 1,
		transition: "all 0.3s",
		"&:hover": {
			backgroundColor: "#2C74F6",
			opacity: 0.8,
		},
	},
}));
interface Params extends ButtonProps {
	target?: string;
	buttonClassName?: string;
}

const Button = React.memo((props: Params) => {
	const styles = useStyles();
	return (
		<MUIButton
			fullWidth
			color="primary"
			variant="contained"
			disableElevation
			className={
				props.buttonClassName
					? `${styles.button} ${props.buttonClassName}`
					: styles.button
			}
			{...props}
		>
			{props.children}
		</MUIButton>
	);
});

export default Button;
