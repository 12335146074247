import React, { FC, useState, useContext } from "react";

import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";

import FinancialSituation from "containers/Forms/FinancialSituation";
import ApplicationContext from "stores/application/ApplicationRoot";
import LiquidityNeeds from "containers/new/LiquidityNeeds";
import TimeHorizon from "containers/new/TimeHorizon";
import SectionHeader from "components/UI/Typography/SectionHeader";
import APP_PATHS from "pages/appPaths";
import ApplicationFormLayout from "containers/Layout/ApplicationFormLayout";
import SuitabilityPanel from "containers/Steps/SuitabilityPanel";
import LiquidityNeedsHelp from "components/Help/LiquidityNeedsHelp";
import TimeHorizonHelp from "components/Help/TimeHorizonHelp";

interface IFinancialProfileProps
	extends RouteComponentProps<{}, {}, { returnUrl?: string; from?: boolean }> {}

const FinancialProfile: FC<IFinancialProfileProps> = ({
	history,
	location,
}) => {
	const application = useContext(ApplicationContext);

	const { financial } = application;

	const disableNext =
		financial.pageInvalid ||
		application.loading ||
		!application.preferences.timeHorizon.value ||
		!application.preferences.liquidityNeeds.value;

	const [returnUrl] = useState(location.state?.returnUrl);

	const handleNext = async () => {
		if (returnUrl === APP_PATHS.review) {
			await application.submitStepTwo();

			location.state.returnUrl = undefined;

			history.replace(APP_PATHS.review);
			return;
		}

		history.push(APP_PATHS.affiliations, { from: true });
	};

	return (
		<>
			<ApplicationFormLayout
				step={3}
				loading={application.loading}
				onNext={handleNext}
				onNextDisabled={disableNext}
				continueText="Next: Affiliations"
				formChildren={
					<>
						<SectionHeader marginBottom={20} value="Financial Profile" />

						<FinancialSituation />

						<Box mt={5} mb={3}>
							<Box
								mb={1.25}
								display={"flex"}
								alignItems={"center"}
								width={"100%"}
							>
								<SectionHeader
									marginBottom={0}
									value="What are your liquity needs?"
								/>

								<Box ml={0.5}>
									<LiquidityNeedsHelp />
								</Box>
							</Box>

							<LiquidityNeeds />
						</Box>

						<Box
							mb={1.25}
							display={"flex"}
							alignItems={"center"}
							width={"100%"}
						>
							<SectionHeader
								marginBottom={0}
								value="What is your time horizon?"
							/>

							<Box ml={0.5}>
								<TimeHorizonHelp />
							</Box>
						</Box>

						<TimeHorizon />
					</>
				}
				leftPanelChildren={<SuitabilityPanel />}
			/>
		</>
	);
};

export default observer(FinancialProfile);
