import { createStyles, makeStyles } from "@material-ui/core";

const useErrorStyles = makeStyles((theme) =>
  createStyles({
    error: {
      marginBottom: 25,
      fontSize: 13,
      fontWeight: 500,
      width: "100%",
      flex: 1,
      display: "block",
      color: theme.palette.error.main,
    },
  })
);

export default useErrorStyles;
