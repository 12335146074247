import axios, { AxiosInstance } from "axios";
import {
  EmploymentStatus,
  MaritalStatus,
} from "core/application/Application.d";
import { format } from "date-fns";
import { IAddress } from "stores/application/Address";
import { ApplicationType } from "stores/application/ApplicationRoot";
import { IFullName } from "stores/application/FullName";
import {
  IAddressRequestPayload,
  IAffiliatedRequestPayload,
  IControlPersonRequestPayload,
  IEmploymentRequestPayload,
  IFinancialSituationRequestPayload,
  IInvestmentProfileRequestPayload,
  IPoliticalExposureRequestPayload,
  ISuitabilityPayload,
  ITrustedContactPersonRequestPayload,
} from "../types/request/request";

interface Step1Payload {
  name: IFullName;
  dateOfBirth?: {
    dateOfBirth: string;
  };
  phoneNumber: {
    phoneNumber: number;
  };
  socialSecurityNumber?: {
    ssn: string;
  };
  citizenship: {
    country: string;
    usPermanentResident: boolean;
  };
  taxStatus: {
    maritalStatus: string;
    numberOfDependents: number;
  };
  address: {
    residentialAddress: IAddress;
    mailingAddress?: IAddress;
  };
}

interface Step2Payload {
  investmentProfile: {
    stocksExperience: string;
    optionsExperience: string;
    spreadsExperience: string;
    investmentObjectives: string[];
  };
  suitability: {
    liquidityNeeds: string;
    riskTolerance: string;
    timeHorizon: string;
  };
  financialSituation: {
    annualIncome: string;
    totalNetWorth: string;
    liquidNetWorth: string;
  };
  employment: {
    employmentStatus: string;
    employment?: {
      employerName: string;
      position: string;
      employerAddress: string;
      jobFunction: string;
      occupation: string;
    };
    unemployed?: {
      description: string;
    };
  };

  controlPerson: {
    controlPerson: boolean;
    companies?: string;
  };
  affiliation: {
    affiliated: boolean;
    firms?: string;
  };
  politicalExposure: {
    politicallyExposed: boolean;
    relatedFamilyMembers?: string;
    politicalOrganizations?: string;
  };
}

interface Step3Payload {
  joinSweepProgram: boolean;
  trustedContactPerson?: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    address: {
      streetAddress: string;
      streetAddress2?: string;
      city: string;
      state: string;
      zipCode: string;
    };
  };
  agreement: boolean;
}

interface SubscriberPayload {
  citizenship: {
    country: string;
    usPermanentResident?: boolean;
  };
  name: IFullName;
  phoneNumber: {
    phoneNumber: number;
  };
  address: {
    residentialAddress: IAddress;
    mailingAddress?: IAddress;
  };
  employment: {
    employmentStatus: string;
    employment?: {
      employerName: string;
      position: string;
      employerAddress: string;
      jobFunction: string;
      occupation: string;
    };
    unemployed?: {
      description: string;
    };
  };
  affiliation: {
    affiliated: boolean;
    firms?: string;
  };
  agreement: boolean;
}

class OptionsAIApiClient {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public submitStep1 = (payload: Step1Payload) => {
    return this.client.post("/options/users", payload);
  };

  public submitStep2 = (payload: Step2Payload) => {
    return this.client.post("/options/users", payload);
  };

  public submitStep3 = (payload: Step3Payload) => {
    return this.client.post("/options/users", payload);
  };

  public submitSubscriberApplication = (payload: SubscriberPayload) => {
    if (payload.address.residentialAddress.country !== "USA") {
      payload.citizenship = {
        country: payload.address.residentialAddress.country || "",
        usPermanentResident: false,
      };
    }

    return this.client.post("/options/users", {
      ...payload,
    });
  };

  public getLearnFeed = async () => {
    const baseURL = "https://learn.optionsai.com";

    const config = {
      baseURL,
    };

    const client = axios.create(config);

    client.interceptors.response.use((response) => response);

    return client.get(`category/markets/feed/rss`);
  };

  public createUser = async () => {
    return await this.client.post("/users");
  };

  public getUser = async () => {
    return await this.client.get("/users");
  };

  public resendEmailVerification = async () => {
    return await this.client.post("/emails/resend");
  };

  public getApplication = async () => {
    return await this.client.get("/applications");
  };

  public getUSStates = async () => {
    return await this.client.get("/states");
  };

  public getCountries = async () => {
    return await this.client.get("/countries");
  };

  public createNewApplication = async () => {
    return await this.client.post("/applications");
  };

  public setNames = async (
    firstName: string,
    lastName: string,
    middleName = ""
  ) => {
    return await this.client.put("/applications/names", {
      firstName,
      middleName,
      lastName,
    });
  };

  public setPhoneNumber = async (phoneNumber: number) => {
    return await this.client.put("/applications/phone-numbers", {
      phoneNumber,
    });
  };

  public setDateOfBirth = async (dob: string) => {
    const dateOfBirth = format(dob, "YYYY-MM-DD");

    return await this.client.put("/applications/date-of-births", {
      dateOfBirth,
    });
  };

  public setAddresses = async (addresses: IAddressRequestPayload) => {
    if (!addresses.useMailingAddress) {
      delete addresses.mailingAddress;
    }

    delete addresses.useMailingAddress;

    return await this.client.put("/applications/addresses", addresses);
  };

  public setSocialSecurityNumber = async (ssn: string) => {
    return await this.client.put("/applications/ssns", {
      ssn: ssn.split("-").join(""),
    });
  };

  public setCitizenship = async (
    country: string,
    usPermanentResident?: boolean
  ) => {
    let isUSResident = usPermanentResident;

    if (country === "USA") {
      isUSResident = false;
    }

    return await this.client.put("/applications/citizenships", {
      country,
      usPermanentResident: isUSResident,
    });
  };

  public setTaxStatus = async (marital: MaritalStatus, dependents: number) => {
    return await this.client.put("/applications/tax-statuses", {
      maritalStatus: marital,
      numberOfDependents: dependents,
    });
  };

  public setSweepProgram = async (enroll = false) => {
    if (!enroll) {
      return await this.client.delete("/applications/programs/sweeps");
    }

    return await this.client.put("/applications/programs/sweeps");
  };

  public setEmployment = async (payload: IEmploymentRequestPayload) => {
    if (
      payload.employmentStatus === EmploymentStatus.EMPLOYED ||
      payload.employmentStatus === EmploymentStatus.SELFEMPLOYED
    ) {
      delete payload.unemployed;
    }

    if (
      payload.employmentStatus !== EmploymentStatus.EMPLOYED &&
      payload.employmentStatus !== EmploymentStatus.SELFEMPLOYED
    ) {
      delete payload.employment;
    }

    return await this.client.put("/applications/employments", payload);
  };

  public setFinancialSituation = async (
    payload: IFinancialSituationRequestPayload
  ) => {
    return await this.client.put("/applications/financial-situations", payload);
  };

  public setInvestmentProfile = async (
    payload: IInvestmentProfileRequestPayload
  ) => {
    return await this.client.put("/applications/investment-profiles", payload);
  };

  public setSuitability = async (payload: ISuitabilityPayload) => {
    return await this.client.put("/applications/suitabilities", payload);
  };

  public setPoliticalExposure = async (
    payload: IPoliticalExposureRequestPayload
  ) => {
    if (!payload.politicallyExposed) {
      delete payload.politicalOrganizations;
      delete payload.relatedFamilyMembers;
    }

    return await this.client.put("/applications/political-exposures", payload);
  };

  public setControlPerson = async (payload: IControlPersonRequestPayload) => {
    if (!payload.controlPerson) {
      delete payload.companies;
    }

    return await this.client.put("/applications/control-persons", payload);
  };

  public setAffiliation = async (payload: IAffiliatedRequestPayload) => {
    if (!payload.affiliated) {
      delete payload.firms;
    }

    return await this.client.put("/applications/affiliations", payload);
  };

  public setTrustedContactPerson = async (
    payload: ITrustedContactPersonRequestPayload
  ) => {
    return await this.client.put(
      "/applications/trusted-contact-persons",
      payload
    );
  };

  public signApplication = async (signature: string) => {
    return await this.client.put("/applications/signatures", { signature });
  };

  public acceptAgreement = async () => {
    return await this.client.put("/applications/agreements", {});
  };

  public submitApplication = async (type: ApplicationType) => {
    return await this.client.put("/applications/submissions", {
      registrationType: type,
    });
  };

  public deleteTrustedPerson = async () => {
    return await this.client.delete("/applications/trusted-contact-persons");
  };
}

export default OptionsAIApiClient;
