import APP_PATHS from "pages/appPaths";

type Keys = keyof typeof APP_PATHS;
type Values = (typeof APP_PATHS)[Keys];

const getPageTitleFromPath = (path?: Values) => {
	switch (path) {
		case APP_PATHS.basicInfo: {
			return "Basic Info";
		}

		case APP_PATHS.identity: {
			return "Identity";
		}

		case APP_PATHS.marital: {
			return "Marital";
		}

		case APP_PATHS.investmentKnowledge: {
			return "Investment Knowledge";
		}

		case APP_PATHS.investmentGoals: {
			return "Investment Goals";
		}

		case APP_PATHS.financialProfile: {
			return "Financials";
		}

		case APP_PATHS.affiliations: {
			return "Affiliations";
		}

		case APP_PATHS.employment: {
			return "Employment";
		}

		case APP_PATHS.sweepProgram: {
			return "Sweep Program";
		}

		case APP_PATHS.trustedContact: {
			return "Trusted Contact";
		}

		case APP_PATHS.agree: {
			return "Agreement";
		}

		case APP_PATHS.signature: {
			return "Signature";
		}

		default: {
			return "Default";
		}
	}
};

export default getPageTitleFromPath;
