import { createContext } from "react";

// stores
import IApplicationResponse from "core/api/types/response/applicationResponse";
import { ApplicationStatus } from "core/application/Application.d";
import { FieldRoute } from "pages/navRoutes";
import Addresses from "./Address";
import Affiliation from "./Affiliation";
import Agreement from "./Agreement";
import Citizenship from "./Citizenship";
import ControlPerson from "./ControlPerson";
import DateOfBirth from "./DateOfBirth";
import Employment from "./Employment";
import FinancialSuitability from "./FinancialSuitability";
import FullName from "./FullName";
import InvestmentProfile from "./InvestmentProfile";
import PhoneNumber from "./PhoneNumber";
import PoliticalExposure from "./PoliticalExposure";
import { Review } from "./Review";
import Signature from "./Signature";
import SocialSecurityNumber from "./SocialSecurityNumber";
import InvestmentPreferences from "./Suitability";
import TaxStatus from "./TaxStatus";
import TrustedPerson from "./TrustedContactPerson";
import { observable, action, runInAction, computed } from "mobx";
import OptionsAIApiClient from "core/api/client/apiClient";
import APP_PATHS from "pages/appPaths";
import SweepProgram from "./SweepProgram";
import RedditPixel from "react-reddit-pixel";
import trackEvent, { AnalyticEvents } from "core/analytics/trackEvent";
import * as Sentry from "@sentry/react";

export enum LoadingState {
  UNKNOWN,
  TRUE,
  FALSE,
}

export enum ApplicationType {
  SUBSCRIPTION = "SUBSCRIPTION",
  BROKERAGE = "BROKERAGE",
}

export class Application {
  @observable
  public applicationType?: ApplicationType;

  @computed
  public get isSubscriber() {
    return this.applicationType === ApplicationType.SUBSCRIPTION;
  }

  public review: Review;
  public name: FullName;
  public phone: PhoneNumber;
  public dob: DateOfBirth;
  public addresses: Addresses;
  public ssn: SocialSecurityNumber;
  public citizenship: Citizenship;
  public taxStatus: TaxStatus;
  public employment: Employment;
  public investmentProfile: InvestmentProfile;
  public politicalExposure: PoliticalExposure;
  public controlPerson: ControlPerson;
  public affiliation: Affiliation;
  public trustedPerson: TrustedPerson;
  public signature: Signature;
  public agreement: Agreement;
  public financial: FinancialSuitability;
  public preferences: InvestmentPreferences;
  public sweepProgram: SweepProgram;

  public apiClient?: OptionsAIApiClient;

  @action
  public setApplicationType = (type: ApplicationType) => {
    console.log('setting application type: ', type);
    this.applicationType = type;
  };

  public setApiClient(client: OptionsAIApiClient) {
    this.apiClient = client;
  }

  private populateMap: {
    key: string;
    set: (payload: any) => void;
    route: string;
    notRequired?: boolean;
  }[] = [];

  constructor() {
    this.review = new Review(this);
    this.name = new FullName(this);
    this.phone = new PhoneNumber(this);
    this.dob = new DateOfBirth(this);
    this.addresses = new Addresses(this);
    this.ssn = new SocialSecurityNumber(this);
    this.citizenship = new Citizenship(this);
    this.taxStatus = new TaxStatus(this);
    this.employment = new Employment(this);
    this.investmentProfile = new InvestmentProfile(this);
    this.financial = new FinancialSuitability(this);
    this.preferences = new InvestmentPreferences(this);
    this.politicalExposure = new PoliticalExposure(this);
    this.controlPerson = new ControlPerson(this);
    this.affiliation = new Affiliation(this);
    this.trustedPerson = new TrustedPerson(this);
    this.signature = new Signature(this);
    this.agreement = new Agreement(this);
    this.sweepProgram = new SweepProgram(this);

    this.populateMap = [
      {
        key: "firstName",
        set: this.name.firstName.set,
        route: "/steps/1",
      },
      {
        key: "middleName",
        set: this.name.middle.set,
        route: FieldRoute.FIRST_LAST,
        notRequired: true,
      },
      {
        key: "lastName",
        set: this.name.last.set,
        route: FieldRoute.FIRST_LAST,
      },
      {
        key: "phoneNumber",
        set: this.phone.setPhoneInitial,
        route: FieldRoute.PHONE,
      },
      {
        key: "residentialAddress",
        set: this.addresses.setResidential,
        route: FieldRoute.ADDRESS,
      },
      {
        key: "mailingAddress",
        set: this.addresses.setMailing,
        route: FieldRoute.ADDRESS,
        notRequired: true,
      },
      {
        key: "dateOfBirth",
        set: this.dob.dateOfBirthServer.set,
        route: FieldRoute.DOB,
      },
      {
        key: "ssn",
        set: this.ssn.serverResponse.set,
        route: FieldRoute.SSN,
      },
      {
        key: "citizenship",
        set: this.citizenship.set,
        route: FieldRoute.CITIZENSHIP,
      },
      {
        key: "taxStatus",
        set: this.taxStatus.set,
        route: FieldRoute.TAX_STATUS,
      },
      {
        key: "investmentProfile",
        set: this.investmentProfile.set,
        route: "/steps/2",
      },
      {
        key: "suitability",
        set: this.preferences.set,
        route: "/steps/2",
      },

      {
        key: "financialSituation",
        set: this.financial.set,
        route: FieldRoute.FINANCIAL,
      },

      {
        key: "politicalExposure",
        set: this.politicalExposure.set,
        route: FieldRoute.POLITICAL_EXPOSURE,
      },
      {
        key: "controlPerson",
        set: this.controlPerson.set,
        route: FieldRoute.CONTROL_PERSON,
      },
      {
        key: "affiliation",
        set: this.affiliation.set,
        route: FieldRoute.AFFILIATION,
      },
      {
        key: "employment",
        set: this.employment.set,
        route: FieldRoute.EMPLOYMENT,
      },
      {
        key: "sweepProgram",
        set: this.sweepProgram.enroll.set,
        route: "/steps/3",
      },
      {
        key: "trustedContactPerson",
        set: this.trustedPerson.set,
        route: FieldRoute.TRUSTED_PERSON,
      },
      {
        key: "agreement",
        set: this.agreement.field.set,
        route: FieldRoute.AGREEMENT,
      },
      {
        key: "signature",
        set: this.signature.field.set,
        route: FieldRoute.SIGNATURE,
      },
    ];
  }

  public populateApplication = (application: IApplicationResponse) => {
    if (this.applicationType === ApplicationType.SUBSCRIPTION) {
      return APP_PATHS.basicInfo;
    }

    if (!application.status) {
      return APP_PATHS.error500;
    }

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.populateMap.length; i += 1) {
      const map = this.populateMap[i];
      const responseField = application[map.key as keyof IApplicationResponse];

      if (
        (responseField === undefined && !map.notRequired) ||
        (map.key === "agreement" && !responseField)
      ) {
        return map.route;
      }

      if (responseField) {
        if (map.key === "dateOfBirth") {
          map.set("****-**-**");
        } else if (map.key === "residentialAddress") {
          map.set({
            ...((responseField as any) || {}),
            country: application.citizenship?.country,
          });
        } else {
          map.set(responseField);
        }
      }

      if (map.key === "investmentProfile") {
        if (!(responseField as any).investmentObjectives?.length) {
          return FieldRoute.KNOWLEDGE;
        }
      }
    }

    if (application.status === ApplicationStatus.INPROGRESS) {
      return FieldRoute.REVIEW;
    }

    return "/steps/welcome";
  };

  @observable
  public loading?: boolean = false;

  @observable
  public error?: ExtendedError = undefined;

  @action
  public setError = (e?: ExtendedError) => {
    this.error = e;
  };

  @action
  public submitStepOne = async () => {
    this.loading = true;
    this.error = undefined;

    try {
      const namesResponse = this.name.putRequest();

      const dateOfBirthResponse = this.dob.putRequest();

      const phoneResponse = this.phone.putRequest();

      const ssnResponse = this.ssn.putRequest();

      const citizenResponse = this.citizenship.putRequest();

      const maritialResponse = this.taxStatus.putRequest();

      const addressResponse = this.addresses.putRequest();

      const response = await this.apiClient?.submitStep1({
        name: namesResponse,
        dateOfBirth: dateOfBirthResponse,
        phoneNumber: phoneResponse,
        socialSecurityNumber: ssnResponse,
        address: addressResponse,
        taxStatus: maritialResponse,
        citizenship: citizenResponse,
      });

      console.log(response);
    } catch (e) {
      Sentry.captureException(e);

      runInAction(() => {
        this.error = e;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  public submitStepTwo = async () => {
    this.loading = true;
    this.error = undefined;

    try {
      const investmentPromise = this.investmentProfile.putRequest();

      const suitabilityPromise = this.preferences.putRequest();

      const financialPromise = this.financial.putRequest();

      const employmentPromise = this.employment.putRequest();

      const controlPersonPromise = this.controlPerson.putRequest();

      const affiliationPromise = this.affiliation.putRequest();

      const politicalPromise = this.politicalExposure.putRequest();

      await this.apiClient?.submitStep2({
        employment: employmentPromise,
        investmentProfile: investmentPromise,
        financialSituation: financialPromise,
        suitability: suitabilityPromise,
        affiliation: affiliationPromise,
        politicalExposure: politicalPromise,
        controlPerson: controlPersonPromise,
      });
    } catch (e) {
      Sentry.captureException(e);

      runInAction(() => {
        this.error = e;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  public submitStepThree = async () => {
    runInAction(() => {
      this.loading = true;
      this.error = undefined;
    });

    const apiClient = this.apiClient;

    if (!apiClient) {
      throw new Error("Could not connect to services.");
    }

    window.onbeforeunload = function () {
      return "Your application is still being submitted.";
    };

    try {
      if (this.isSubscriber) {
        await this.apiClient?.submitSubscriberApplication({
          name: this.name.putRequest(),
          citizenship: {
            country: this.addresses.residential.country.value || "",
          },
          employment: {
            ...this.employment.putRequest(),
            unemployed: {
              description: "N/A",
            },
          },
          phoneNumber: this.phone.putRequest(),
          address: this.addresses.putRequest(),
          agreement: this.agreement.putRequest(),
          affiliation: this.affiliation.putRequest(),
        });

        await apiClient.submitApplication(ApplicationType.SUBSCRIPTION);
      } else {
        await this.apiClient?.submitStep3({
          agreement: this.agreement.putRequest(),
          joinSweepProgram: this.sweepProgram.putRequest(),
          trustedContactPerson: this.trustedPerson.putRequest(),
        });

        await this.signature.putRequest();

        trackEvent(AnalyticEvents.application_submitted_start);

        await apiClient.submitApplication(ApplicationType.BROKERAGE);

        trackEvent(AnalyticEvents.application_submitted_success);

        RedditPixel.track("Application Submitted");
      }
    } catch (e) {
      Sentry.captureException(e);

      trackEvent(AnalyticEvents.application_submitted_error);

      runInAction(() => {
        this.error = e;
      });
    } finally {
      runInAction(() => {
        this.loading = false;

        window.onbeforeunload = null;
      });
    }
  };
}

const ApplicationContext = createContext(new Application());

export default ApplicationContext;
