import { addYears, isBefore, isValid } from "date-fns";
import Validator from "validatorjs";
import { poBoxRegex } from "./isPoBox";

Validator.register(
  "over16",
  (value: any, requirement: any, attribute: any) => {
    const dob = new Date(value);
    // my dob years is before today - 16 years
    return isBefore(dob, addYears(new Date(), -16));
  },
  "You do not meet the required age."
);

Validator.register(
  "telephone",
  (value: any, requirement: any, attribute: any) => {
    const regex = /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/;

    return regex.test(value);
  },
  "Please provide a valid phone number, area code first."
);

Validator.register(
  "date-valid",
  (value: any, requirement: any, attribute: any) => {
    const valid = isValid(new Date(value));

    console.log(value);
    console.log(new Date(value).toISOString());
    console.log(valid);
    return valid;
  },
  "Please provide a valid date of birth."
);

Validator.register(
  "ssn",
  (value: any, requirement: any, attribute: any) => {
    const regex = /^\d{3}-\d{2}-\d{4}$/;
    const regex2 = /^\d{3}\d{2}\d{4}$/;

    return regex.test(value) || regex2.test(value);
  },
  "Please provide a valid social security number"
);

Validator.register(
  "pobox",
  (value: any, requirement: any, attribute: any) => {
    return !poBoxRegex.test(value);
  },
  "PO Box address is not permitted"
);

export default Validator;
