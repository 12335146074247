import React, { FC, useContext } from "react";

import { ButtonGroup, Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";

import ButtonGroupButton from "components/UI/new/ButtonGroupButton";
import ApplicationContext from "stores/application/ApplicationRoot";
import { ExperienceEnum } from "stores/application/InvestmentProfile";
import SectionHeader from "components/UI/Typography/SectionHeader";
import TextfieldWithValidation from "components/UI/TextField/TextfieldWithValidation";

interface IInvestmentKnowledgeFormProps {}

const InvestmentKnowledgeForm: FC<IInvestmentKnowledgeFormProps> = (props) => {
	const { investmentProfile } = useContext(ApplicationContext);

	const handleStockSelection = (value: ExperienceEnum) => {
		investmentProfile.stocksExperience.set(value);
	};

	const handleSingleLegSelection = (value: ExperienceEnum) => {
		investmentProfile.optionsExperience.set(value);
	};

	return (
		<Box>
			<SectionHeader value="Stock" />

			<TextfieldWithValidation
				customField={
					<ButtonGroup fullWidth={true}>
						{VALUES.map((val) => (
							<ButtonGroupButton
								key={val.value}
								active={val.value === investmentProfile.stocksExperience.value}
								onClick={() => handleStockSelection(val.value)}
							>
								{val.label}
							</ButtonGroupButton>
						))}
					</ButtonGroup>
				}
				valid={
					investmentProfile.stocksExperience.value !== undefined &&
					!investmentProfile.stocksExperience.errMsg
				}
			/>

			<Box mt="25px">
				<SectionHeader value="Calls & Puts" />

				<TextfieldWithValidation
					customField={
						<ButtonGroup fullWidth={true}>
							{VALUES.map((val) => (
								<ButtonGroupButton
									key={val.value}
									active={
										val.value === investmentProfile.optionsExperience.value
									}
									onClick={() => handleSingleLegSelection(val.value)}
								>
									{val.label}
								</ButtonGroupButton>
							))}
						</ButtonGroup>
					}
					valid={
						investmentProfile.optionsExperience.value !== undefined &&
						!investmentProfile.optionsExperience.errMsg
					}
				/>
			</Box>
		</Box>
	);
};

const VALUES = [
	{
		label: "Limited",
		value: ExperienceEnum.LIMITED,
	},
	{
		label: "Good",
		value: ExperienceEnum.GOOD,
	},
	{
		label: "Extensive",
		value: ExperienceEnum.EXTENSIVE,
	},
];

export default observer(InvestmentKnowledgeForm);
