import React, { FC } from "react";

interface Params {
	size?: number;
	fill?: string;
}

const ShieldIcon: FC<Params> = React.memo(({ size = 24, fill = "#2c74f6" }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 22C9.68333 21.4167 7.77067 20.0873 6.262 18.012C4.75333 15.9367 3.99933 13.6327 4 11.1V5L12 2L20 5V11.1C20 13.6333 19.2457 15.9377 17.737 18.013C16.2283 20.0883 14.316 21.4173 12 22Z"
				fill={fill}
			/>
		</svg>
	);
});

export default ShieldIcon;
