import { REACT_APP_GTAG_MEASURE_ID } from "core/constants";
import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route } from "react-router";
import { RouteProps } from "react-router-dom";

interface IAnalyticRouteProps extends RouteProps {
  title: string;
}

const AnalyticRoute: FC<IAnalyticRouteProps> = React.memo(
  ({ title, ...props }) => {
    window.document.title = `OPTIONS.AI - ${title}`;

    useEffect(() => {
      (window as any).gtag("config", REACT_APP_GTAG_MEASURE_ID, {
        page_path: props.location?.pathname,
        page_title: title,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title]);

    return (
      <Route
        {...props}
        // tslint:disable-next-line: jsx-no-lambda
        render={(routeProps) => {
          return (
            <>
              <Helmet title={title} />

              {React.createElement(props.component as any, routeProps)}
            </>
          );
        }}
      />
    );
  }
);

export default AnalyticRoute;
