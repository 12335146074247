import React, { FC, Fragment } from "react";

import { FormControl, Typography, Box, Button } from "@material-ui/core";
import TextField from "components/UI/TextField/TextField";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import PoliticalExposure from "stores/application/PoliticalExposure";
import { useEffect } from "react";

interface IPoliticalExposureDetailsProps {
  initialRelations?: string;
  initialOrganizations?: string;
  handleClose: () => void;
  onSave: (...props: any) => void;
}

const PoliticalExposureDetails: FC<IPoliticalExposureDetailsProps> = ({
  initialOrganizations = "",
  initialRelations = "",
  handleClose,
  onSave,
}) => {
  const [politicalExposure] = useState(new PoliticalExposure());

  const setRelations = (value: string) => {
    politicalExposure.relations.set(value);
  };

  const setOrganizations = (value: string) => {
    politicalExposure.organizations.set(value);
  };

  useEffect(() => {
    !!initialOrganizations &&
      politicalExposure.organizations.set(initialOrganizations);

    !!initialRelations && politicalExposure.relations.set(initialRelations);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { relations, organizations } = politicalExposure;

  const handleContinue = () => {
    onSave(organizations.value, relations.value);
  };

  return (
    <Fragment>
      <FormControl fullWidth={true} margin="normal">
        <Typography>{pageContent.namesOfOfficials}</Typography>

        <TextField
          error={relations.errMsg}
          onChange={setRelations}
          value={relations.value}
          fullWidth={true}
          multiline={true}
        />
      </FormControl>

      <FormControl fullWidth={true}>
        <Typography>{pageContent.organizations}</Typography>
        <TextField
          error={organizations.errMsg}
          onChange={setOrganizations}
          value={organizations.value}
          label="Organizations"
          fullWidth={true}
          multiline={true}
        />
      </FormControl>

      <Box pt={2} display="flex" justifyContent="flex-end">
        <Button
          onClick={handleClose}
          style={{ color: "#8B8B8B" }}
          variant="text"
        >
          Cancel
        </Button>
        <Button
          onClick={handleContinue}
          disableElevation={true}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </Box>
    </Fragment>
  );
};

const pageContent = {
  header: "Political Exposure",
  description: `Is the account maintained for a current or former politically exposed
  person or public official (includes US and foreign individuals)?`,
  infoPanel: `If you're not sure, then you probably are not politically exposed.`,
  namesOfOfficials: `Please provide the names of the officials and officials
  immediate family members`,
  organizations:
    "Please provide the names of the related political organization",
};

export default observer(PoliticalExposureDetails);
