import React, { FC, useContext, useMemo, useState } from "react";

import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import { Box } from "@material-ui/core";

import APP_PATHS from "pages/appPaths";
import ApplicationContext from "stores/application/ApplicationRoot";
import ApplicationFormLayout from "containers/Layout/ApplicationFormLayout";
import SectionDescription from "components/UI/Typography/SectionDescription";
import SectionHeader from "components/UI/Typography/SectionHeader";
import DateOfBirth from "containers/new/DateOfBirth";
import SocialSecurityNumber from "containers/new/SocialSecurityNumber";
import CitizenshipContainer from "containers/Forms/Citizenship";
import BasicInfoPanel from "containers/Steps/BasicInfoPanel";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	formChildrenContainer: {
		width: "100%",
		maxHeight: "calc(100% - 150px)",
		flexDirection: "column",
		alignItems: "center",
		"@media (max-width: 850px)": {
			display: "block",
			marginTop: "40px",
		},
	},
});

interface Params
	extends RouteComponentProps<{}, {}, { returnUrl?: string; from?: boolean; }> { }

const IdentityNew: FC<Params> = observer(({ history, location }) => {
	const application = useContext(ApplicationContext);

	const classes = useStyles();

	const nextDisabled = useMemo(() => {
		return (
			application.dob.pageInvalid ||
			application.ssn.pageInvalid ||
			application.citizenship.pageInvalid ||
			application.loading
		);
	}, [
		application.citizenship.pageInvalid,
		application.dob.pageInvalid,
		application.loading,
		application.ssn.pageInvalid,
	]);

	const [returnUrl] = useState(location.state?.returnUrl);

	const handleNext = async () => {
		if (returnUrl === APP_PATHS.review) {
			await application.submitStepOne();

			location.state.returnUrl = undefined;

			history.replace(APP_PATHS.review);
			return;
		}

		history.push(APP_PATHS.marital, { from: true });
	};
	return (
		<ApplicationFormLayout
			step={2}
			loading={application.loading}
			formChildren={
				<div className={classes.formChildrenContainer}>
					<Box width="100%">
						<SectionHeader value="Date of Birth" />

						<DateOfBirth />

						<Box mb={3}>
							<SectionHeader marginBottom={0} value="SSN" />

							<SectionDescription
								value="Federal law requires us to collect this. We use it to verify your
            identity. We'll keep it safe!"
							/>

							<SocialSecurityNumber />
						</Box>

						<SectionHeader value="US Citizen" />
						<SectionDescription value="Are you a US Citizen? US federal law requires all brokerages to collect this information." />

						<CitizenshipContainer />
					</Box>
				</div>
			}
			leftPanelChildren={<BasicInfoPanel isSubscriber={application.isSubscriber} />}
			onNextDisabled={nextDisabled}
			onNext={handleNext}
			continueText="Next: Household Info"
		/>
	);
});

export default IdentityNew;
