import React, { FC, Fragment, useEffect } from "react";
import { useState } from "react";
import Affiliation from "stores/application/Affiliation";
import { observer } from "mobx-react-lite";
import { FormControl, Typography, Box, Button } from "@material-ui/core";
import TextField from "components/UI/TextField/TextField";

interface IAffiliationDetailsProps {
  initialDescription?: string;
  onSave: (...params: any) => void;
  handleClose: () => void;
}

const AffiliationDetails: FC<IAffiliationDetailsProps> = ({
  initialDescription = "",
  onSave,
  handleClose,
}) => {
  const [affiliation] = useState(new Affiliation(undefined));

  const { description } = affiliation;

  const setDescription = (value: string) => {
    affiliation.description.set(value);
  };

  useEffect(() => {
    !!initialDescription && setDescription(initialDescription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    onSave(description.value);
  };

  return (
    <Fragment>
      <FormControl margin="normal" fullWidth={true}>
        <Typography variant="body1" gutterBottom={true}>
          {pageContent.nameOfFirms}
        </Typography>

        <TextField
          value={description.value}
          onChange={setDescription}
          fullWidth={true}
          multiline={true}
          label="Describe"
          error={affiliation.description.errMsg}
        />
      </FormControl>

      <Box pt={2} display="flex" justifyContent="flex-end">
        <Button
          onClick={handleClose}
          style={{ color: "#8B8B8B" }}
          variant="text"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          disableElevation={true}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </Box>
    </Fragment>
  );
};

const pageContent = {
  header: "Affiliations",
  description: `Are you, or anyone authorized to trade in your account, a Securities Professional?`,
  infoPanel: `A securities professional is someone employed by, registered or associated with a broker-dealer firm, a financial services regulator, a securities exchange or a member of a securities exchange or someone acting as an investment advisor.`,
  infoPanelP2: "If you are not sure, then you are probably not affilated.",
  nameOfFirms: `Please provide name of firm(s) or organization(s)`,
};

export default observer(AffiliationDetails);
