import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import ArrowRightWhite from "components/UI/icons/ArrowRightWhite";
import React, { FC } from "react";
import backgroundImage from "./SIPCBackgroundImage.svg";

const useStyles = makeStyles<Theme, { backgroundColor?: string }>((theme) =>
  createStyles({
    card: {
      backgroundColor: (props) => props.backgroundColor || "#333B49",
      backgroundImage: (props) =>
        props.backgroundColor ? "none" : `url(${backgroundImage})`,
      backgroundSize: 250,
      backgroundRepeat: "no-repeat",
      backgroundPositionY: -114,
      backgroundPositionX: -51,
      borderRadius: 4,
      display: "flex",
      paddingBottom: 24,
      paddingTop: 24,
      paddingLeft: 16,
      paddingRight: 16,
      alignItems: "flex-start",
      maxWidth: 600,
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    image: {
      paddingLeft: 25,
      paddingRight: 50,
      [theme.breakpoints.down("md")]: {
        paddingRight: 12,
        paddingLeft: 0,
        paddingBottom: 16,
      },
    },

    caption: {
      color: "#fff",
      textTransform: "uppercase",
      fontSize: 14,
      fontFamily: "Cerebri Sans",
      lineHeight: "23px",
      marginBottom: 4,
    },
    description: {
      color: "#fff",
      fontWeight: 900,
      lineHeight: "30px",
      fontSize: 20,
      fontFamily: "Cerebri Sans",
    },
    button: {
      color: "#fff",
      textEmphasis: "none",
      fontSize: 18,
      lineHeight: "28px",
      fontWeight: 400,
      fontFamily: "Cerebri Sans",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& > *": {
        marginLeft: 4,
      },
    },
  })
);

interface Params {
  logo?: string;
  buttonText?: string;
  description?: string;
  caption?: string;
  buttonHref?: string;
  backgroundColor?: string;
}

const SupportBannder: FC<Params> = ({
  logo,
  buttonHref,
  buttonText,
  caption,
  description,
  backgroundColor,
}) => {
  const styles = useStyles({ backgroundColor });

  return (
    <div className={styles.card}>
      <div className={styles.image}>
        <img alt="logo" src={logo || ""} />
      </div>

      <div>
        <Typography className={styles.caption}>{caption}</Typography>
        <Typography className={styles.description}>{description}</Typography>

        <a
          rel="noopener noreferrer"
          href={buttonHref}
          target="_blank"
          className={styles.button}
        >
          {buttonText} <ArrowRightWhite />
        </a>
      </div>
    </div>
  );
};

export default SupportBannder;
