import React, { FC, useState, useEffect } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Addresses from "stores/application/Address";
import { observer } from "mobx-react-lite";
import { IAddress } from "core/application/Application";
import Select from "components/UI/Select/Select";
import STATES from "core/constants/usStates";
import TextField from "components/UI/TextField/TextField";

interface IAddressDetailsProps {
  handleClose: () => void;
  onSave: (props: IAddress) => void;
  initialName?: string;
  initialStreet?: string;
  initialStreet2?: string;
  initialCity?: string;
  initialState?: string;
  initialZip?: string;
  isEmployerSearch?: boolean;
  initialCountry?: string;
}

const AddressDetails: FC<IAddressDetailsProps> = ({
  handleClose,
  onSave,
  initialName = "",
  initialStreet = "",
  initialStreet2 = "",
  initialCity = "",
  initialState = "",
  initialZip = "",
  isEmployerSearch = false,
  initialCountry,
}) => {
  const [address] = useState(
    new Addresses(undefined, undefined, isEmployerSearch)
  );

  useEffect(() => {
    if (isEmployerSearch) {
      address.residential.setNameRequired();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmployerSearch]);

  useEffect(() => {
    !!initialName && address.residential.name.set(initialName);
    !!initialStreet && address.residential.streetAddress.set(initialStreet);
    !!initialStreet2 && address.residential.streetAddress2.set(initialStreet2);
    !!initialCity && address.residential.city.set(initialCity);
    !!initialState && address.residential.state.set(initialState);
    !!initialCountry && address.residential.country.set(initialCountry);
    !!initialZip && address.residential.zipCode.set(initialZip);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onContinueSelect = () => {
    onSave({
      name: address.residential.name.value || "",
      streetAddress: address.residential.streetAddress.value || "",
      streetAddress2: address.residential.streetAddress2.value || "",
      city: address.residential.city.value || "",
      state: address.residential.state.value || "",
      zipCode: address.residential.zipCode.value || "",
      country: address.residential.country.value || "",
    });
  };

  return (
    <Box>
      {isEmployerSearch && (
        <TextField
          onChange={address.residential.name.set}
          value={address.residential.name.value}
          placeholder="Company Name"
          error={address.residential.name.errMsg}
        />
      )}

      <TextField
        onChange={address.residential.streetAddress.set}
        value={address.residential.streetAddress.value}
        error={address.residential.streetAddress.errMsg}
        placeholder="Address"
      />

      <TextField
        onChange={address.residential.streetAddress2.set}
        value={address.residential.streetAddress2.value}
        error={address.residential.streetAddress2.errMsg}
        placeholder="Apt, Suite, Bldg. (Optional)"
      />

      <TextField
        onChange={address.residential.city.set}
        value={address.residential.city.value}
        error={address.residential.city.errMsg}
        placeholder="City"
      />

      {address.residential.country.value === "USA" ? (
        <Select
          placeholder="State"
          handleChange={address.residential.state.set}
          selectedValue={address.residential.state.value}
          values={STATES}
        />
      ) : (
        <TextField
          disabled={true}
          value={address.residential.country.value}
          placeholder="Country"
        />
      )}

      <TextField
        onChange={address.residential.zipCode.set}
        value={address.residential.zipCode.value}
        error={address.residential.zipCode.errMsg}
        placeholder="Zip"
      />

      <Box pt={2} display="flex" justifyContent="flex-end">
        <Button
          onClick={handleClose}
          style={{ color: "#8B8B8B" }}
          variant="text"
        >
          Back
        </Button>

        <Button
          disabled={address.addressesInvalid}
          onClick={onContinueSelect}
          disableElevation={true}
          color="primary"
          variant="contained"
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default observer(AddressDetails);
