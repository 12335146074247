import React from "react";

import { Security } from "@okta/okta-react";
import { Route, Router, Switch } from "react-router-dom";

import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

import InitializeApplication from "containers/InitializeApplication";
import Routes from "pages/Routes";
import theme from "pages/theme";
import ErrorPage from "pages/ErrorPage";

import { browserHistory } from "core/history/history";
import { QueryClient, QueryClientProvider } from "react-query";

import "react-google-places-autocomplete/dist/index.min.css";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import storage from "utils/tokenStorage";
import { getEnvVar } from "@optionsai/oai-common-js";

const queryClient = new QueryClient();

const oktaAuth = new OktaAuth({
  issuer: `https://auth.options.ai/oauth2/${getEnvVar(
    "REACT_APP_OKTA_ISSUER"
  )}`,
  clientId: getEnvVar("REACT_APP_OKTA_CID"),
  redirectUri: window.location.origin + "/auth/callback",
  pkce: true,
  tokenManager: {
    autoRenew: true,
    secure: true,
    storage: storage,
  },
});

export default () => {
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    browserHistory.replace(
      toRelativeUrl(originalUri || "/", window.location.origin)
    );
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Router history={browserHistory}>
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Switch>
              <Route path="/500" component={ErrorPage} />

              <InitializeApplication>
                <Routes />
              </InitializeApplication>
            </Switch>
          </Security>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
