import React, { FC, useMemo, useState } from "react";
import DefaultLayout from "./DefaultLayout";
import { Box } from "@material-ui/core";
import LiquidityNeeds from "containers/new/LiquidityNeeds";
import TimeHorizon from "containers/new/TimeHorizon";
import RiskTolerance from "containers/new/RiskTolerance";
import { useContext } from "react";
import ApplicationContext from "stores/application/ApplicationRoot";
import { RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react-lite";
import APP_PATHS from "./appPaths";
import RouterLink from "components/UI/new/RouterLink";
import PREFERENCES_COPY from "core/content/preferences";
import InfoPanel from "components/UI/new/InfoPanel";
import SectionHeader from "components/UI/Typography/SectionHeader";
import SectionDescription from "components/UI/Typography/SectionDescription";

interface ITradingProfileProps
  extends RouteComponentProps<{}, {}, { returnUrl?: string; from?: boolean }> {}

const TradingProfile: FC<ITradingProfileProps> = ({ history, location }) => {
  const application = useContext(ApplicationContext);

  const { preferences } = application;

  const disabled = preferences.pageInvalid || application.loading;

  const hash = location.hash;

  const infoPanelContent = useMemo(() => {
    switch (hash) {
      case "#liquidity-needs":
        return PREFERENCES_COPY.liquidityNeeds;

      case "#time-horizon":
        return PREFERENCES_COPY.timeHorizon;

      case "#risk-tolerance":
        return PREFERENCES_COPY.riskTolerance;

      default:
        return undefined;
    }
  }, [hash]);

  const [returnUrl] = useState(location.state?.returnUrl);

  const handleNext = async () => {
    if (returnUrl === APP_PATHS.review) {
      await application.submitStepTwo();

      location.state.returnUrl = undefined;

      history.replace(APP_PATHS.review);
      return;
    }

    history.push(APP_PATHS.financialProfile, { from: true });
  };

  return (
    <>
      <DefaultLayout
        loading={application.loading}
        onNext={handleNext}
        onNextDisabled={disabled}
        secondaryRoute={APP_PATHS.financialProfile}
      >
        <Box pt={4} mb={-2}>
          <SectionHeader
            value={
              <>
                What are your{" "}
                <RouterLink to="#liquidity-needs">liquity needs?</RouterLink>
              </>
            }
          />

          <SectionDescription value=" Which of the following best describes you?" />
        </Box>

        <LiquidityNeeds />

        <Box pt={4} mb={-2}>
          <SectionHeader
            value={
              <>
                What is your{" "}
                <RouterLink to="#time-horizon">time horizon?</RouterLink>
              </>
            }
          />

          <SectionDescription value="Which of the following best describes you?" />
        </Box>

        <TimeHorizon />

        <Box pt={4} mb={-2}>
          <SectionHeader
            value={
              <>
                What is your{" "}
                <RouterLink to="#risk-tolerance">risk tolerance?</RouterLink>
              </>
            }
          />

          <SectionDescription value="Which of the following best describes you?" />
        </Box>

        <RiskTolerance />

        <InfoPanel
          open={!!hash}
          header={infoPanelContent?.infoHeader}
          description={infoPanelContent?.info}
        />
      </DefaultLayout>
    </>
  );
};

export default observer(TradingProfile);
