import React from "react";
import { render } from "react-dom";
import * as Sentry from "@sentry/react";
import RedditPixel from "react-reddit-pixel";

import App from "App";

import "core/fonts/index.css";

const env = process.env.REACT_APP_ENVIRONMENT as 'local' | 'uat' | 'production';

const legacyAPI = new RegExp(`^${process.env.REACT_APP_API}}/?.*$`);


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: env,
  release: process.env.GITHUB_SHA,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: env === 'local' ? 0 : env === 'uat' ? 1 : 1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay({
    maskAllText: false,
    blockAllMedia: false,
    maxReplayDuration: 60 * 1000 * 60,
    errorSampleRate: 1,
    sessionSampleRate: 0,
    networkDetailAllowUrls: [
      legacyAPI,
      'https://api.options.ai/users',
      'https://api.options.ai/applications'
    ],
    networkCaptureBodies: true,
  })],
});

const redditOptions = {
  debug: false,
};

RedditPixel.init("t2_1684oe", redditOptions);

const rootComponent = <App />;
const rootNode = document.getElementById("root");

render(rootComponent, rootNode);
