import React, { FC } from "react";

import Button from "@material-ui/core/Button";
import useGoBack from "hooks/useGoBack";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface IPageNavigationProps
  extends RouteComponentProps<{}, {}, { returnUrl?: string; from?: boolean }> {
  onContinue?: () => void;
  onNextDisabled?: boolean;
  secondaryRoute?: string;
  continueText?: string;
  onBackDisabled?: boolean;
  hasContinue?: boolean;
}

// const useStyles = makeStyles({
// })

const PageNavigation: FC<IPageNavigationProps> = ({
  onContinue,
  onNextDisabled = true,
  location,
  secondaryRoute = "/",
  continueText = "Continue",
  hasContinue = true,
  onBackDisabled = false,
}) => {
  const { goBack } = useGoBack({ location, secondaryRoute });

  const hasUrl = location.state?.returnUrl;

  const backDisabled = onBackDisabled || hasUrl;

  return (
    <>
      {!backDisabled ? (
        <Button onClick={goBack} style={{ color: "#8B8B8B" }} variant="text">
          Back
        </Button>
      ) : (
        <div />
      )}

      {hasContinue ? (
        <Button
          type="submit"
          disabled={onNextDisabled}
          size="large"
          style={{
            borderRadius: 4,
            minWidth: 137,
            paddingTop: 8,
            paddingBottom: 8,
            textTransform: "none",
            fontSize: 18,
            fontWeight: 600,
            marginRight: 24,
            display: "block",
          }}
          onClick={onContinue}
          disableElevation={true}
          color="primary"
          variant="contained"
        >
          {continueText}
        </Button>
      ) : (
        <div />
      )}
    </>
  );
};

export default withRouter(PageNavigation);
