const capitalize = (s?: string) => {
  if (!s) {
    return undefined;
  }

  const lower = s.toLowerCase();

  const vals = lower.split("");

  vals[0] = vals[0].toUpperCase();

  return vals.join("");
};

export default capitalize;
