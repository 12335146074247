import React, { FC, useContext } from "react";

import { observer } from "mobx-react-lite";
import { Box } from "@material-ui/core";

import ApplicationContext from "stores/application/ApplicationRoot";
import TextfieldWithValidation from "components/UI/TextField/TextfieldWithValidation";

interface IEmploymentRoleProps {}

const EmploymentRole: FC<IEmploymentRoleProps> = (props) => {
	const { employment } = useContext(ApplicationContext);

	const setOccupation = (e: any) => {
		const occupation = e.target.value;
		employment.occupation.set(occupation);
	};

	const setPosition = (e: any) => {
		const position = e.target.value;
		employment.position.set(position);
	};

	const setJobFunction = (e: any) => {
		const jobFunction = e.target.value;
		employment.jobFunction.set(jobFunction);
	};

	return (
		<>
			<TextfieldWithValidation
				value={employment.occupation.value}
				error={employment.occupation.errMsg}
				label="Occupation"
				onChange={setOccupation}
				valid={!employment.occupation.errMsg && !!employment.occupation.value}
			/>

			<Box my={1.25}>
				<TextfieldWithValidation
					value={employment.position.value}
					error={employment.position.errMsg}
					label="Title / Position"
					onChange={setPosition}
					valid={!employment.position.errMsg && !!employment.position.value}
				/>
			</Box>

			<TextfieldWithValidation
				value={employment.jobFunction.value}
				error={employment.jobFunction.errMsg}
				label="Job Function"
				onChange={setJobFunction}
				valid={!employment.jobFunction.errMsg && !!employment.jobFunction.value}
				multiline
				type="textarea"
			/>
		</>
	);
};

export default observer(EmploymentRole);
