import React, { FC } from "react";

import { Box } from "@material-ui/core";
import ReviewApplicationList from "components/ReviewApplicationList/ReviewApplicationList";
import { RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react-lite";
import ApplicationFormLayout from "containers/Layout/ApplicationFormLayout";
import ReviewPanel from "containers/Steps/ReviewPanel";
import APP_PATHS from "pages/appPaths";
import SectionHeader from "components/UI/Typography/SectionHeader";

const ReviewPageNew: FC<RouteComponentProps> = observer(({ history }) => {
	const handleNext = () => {
		history.push(APP_PATHS.signature, { from: true });
	};

	return (
		<ApplicationFormLayout
			step={4}
			continueText="Reviewed and Agreed"
			onNextDisabled={false}
			onNext={handleNext}
			leftPanelChildren={<ReviewPanel />}
			formChildren={
				<>
					<SectionHeader value=" Please review your application" />

					<Box maxHeight={500} overflow={"scroll"}>
						<ReviewApplicationList />
					</Box>
				</>
			}
		/>
	);
});

export default ReviewPageNew;
