import { action, computed, observable } from "mobx";
import FormField from "stores/helpers/FormField";

import { Application } from "stores/application/ApplicationRoot";
import Validator from "validatorjs";

export interface IFullName {
  firstName: string;
  lastName: string;
  middleName?: string;
}

class FullName {
  public rootStore: Application;

  @observable
  public firstName: FormField<string> = new FormField(
    "",
    ["required", "min:2", "max:20"],
    {
      required: "Please provide a first name",
      min: "Please provide a valid first name",
      max: "Maximum characters exceeded.",
    }
  );

  @observable
  public last: FormField<string> = new FormField(
    "",
    ["required", "min:2", "max:20"],
    {
      min: "Please provide a valid last name",
      required: "Please provide a last name",
      max: "Maximum characters exceeded.",
    }
  );

  @observable
  public middle: FormField<string> = new FormField("", ["max:20"], {
    max: "Maximum characters exceeded.",
  });

  constructor(rootStore?: any) {
    this.rootStore = rootStore;
  }

  // TODO: Missing middle name from
  @action
  public set({ firstName, lastName }: IFullName) {
    this.firstName.set(firstName || "");
    this.last.set(lastName || "");
  }

  @computed
  public get pageInvalid() {
    const validator = new Validator(
      {
        firstName: this.firstName.value,
        lastName: this.last.value,
        middleName: this.middle.value,
      },
      {
        firstName: "required|min:2|max:20",
        lastName: "required|min:2|max:20",
        middleName: "max:20",
      }
    );

    const isInvalid = validator.passes() ? false : true;

    return isInvalid;
  }

  @action
  public putRequest() {
    const { firstName, middle, last } = this;

    const { apiClient } = this.rootStore;

    if (!apiClient) {
      throw new Error("Could not connect to services.");
    }

    return {
      firstName: firstName.value!,
      lastName: last.value!,
      middleName: middle.value!,
    } as IFullName;
  }
}

export default FullName;
