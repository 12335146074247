import React, { FC } from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

interface IButtonGroupButtonProps extends ButtonProps {
	active?: boolean;
}

const useStyles = (active?: boolean) =>
	makeStyles({
		root: {
			backgroundColor: active
				? " linear-gradient(180deg, rgba(198, 198, 198, 0.1368) 0%, rgba(239, 239, 239, 0) 60%), #FFFFFF"
				: "#F8F8F8",
			color: active ? "#2C74F6" : "#BDBDBD",
			borderRadius: 8,
			textTransform: "none",
			fontWeight: 600,
			fontSize: 12,
			lineHeight: "14px",
			paddingTop: "14px",
			paddingBottom: "14px",
			border: "1px solid #E7E7E7",
			"&:focus": {
				backgroundColor: active
					? " linear-gradient(180deg, rgba(198, 198, 198, 0.1368) 0%, rgba(239, 239, 239, 0) 60%), #FFFFFF"
					: "#F8F8F8",
				color: active ? "#2C74F6" : "#BDBDBD",
			},

			"&:active": {
				backgroundColor: active
					? " linear-gradient(180deg, rgba(198, 198, 198, 0.1368) 0%, rgba(239, 239, 239, 0) 60%), #FFFFFF"
					: "#F8F8F8",
				color: active ? "#2C74F6" : "#BDBDBD",
			},

			"&:hover": {
				backgroundColor: active
					? " linear-gradient(180deg, rgba(198, 198, 198, 0.1368) 0%, rgba(239, 239, 239, 0) 60%), #FFFFFF"
					: "#F8F8F8",
				color: active ? "#2C74F6" : "#BDBDBD",
			},
		},
	});

const ButtonGroupButton: FC<IButtonGroupButtonProps> = ({
	children,
	active,
	...props
}) => {
	const { root } = useStyles(active)();

	return (
		<Button
			{...props}
			classes={{
				root,
			}}
		>
			{children}
		</Button>
	);
};

export default ButtonGroupButton;
