import React, { FC, useContext } from "react";

import { RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react-lite";

import TrustedContactForm from "containers/new/TrustedContact";
import ApplicationContext from "stores/application/ApplicationRoot";
import RouterLink from "components/UI/new/RouterLink";
import InfoPanel from "components/UI/new/InfoPanel";
import TRUSTED_CONTACT_COPY from "core/content/trustedPerson";
import SectionDescription from "components/UI/Typography/SectionDescription";
import APP_PATHS from "pages/appPaths";
import ApplicationFormLayout from "containers/Layout/ApplicationFormLayout";
import ReviewPanel from "containers/Steps/ReviewPanel";
import SectionHeader from "components/UI/Typography/SectionHeader";

interface ITrustedContactPageProps
	extends RouteComponentProps<{}, {}, { returnUrl?: string; from?: boolean }> {}

const TrustedContactPageNew: FC<ITrustedContactPageProps> = ({
	history,
	location,
}) => {
	const application = useContext(ApplicationContext);

	const { trustedPerson } = application;

	const handleNext = () => {
		history.push(APP_PATHS.agree, { from: true });
	};

	const hash = location.hash;

	const content = `
    ${TRUSTED_CONTACT_COPY?.infopanel.p1}\n
    ${TRUSTED_CONTACT_COPY?.infopanel.p2}\n
    ${TRUSTED_CONTACT_COPY?.infopanel.listHeader}\n
    • ${TRUSTED_CONTACT_COPY?.infopanel.list[0]}
    • ${TRUSTED_CONTACT_COPY?.infopanel.list[1]}
    • ${TRUSTED_CONTACT_COPY?.infopanel.list[2]}
  `;

	return (
		<ApplicationFormLayout
			step={4}
			loading={application.loading}
			onNext={handleNext}
			onNextDisabled={trustedPerson.pageInvalid}
			leftPanelChildren={<ReviewPanel />}
			continueText="Next: Disclosures"
			formChildren={
				<>
					<SectionHeader marginBottom={10} value="Trusted Contact Person" />

					<SectionDescription
						value={
							<>
								Would you like to add a
								<RouterLink to="#trusted-contact">
									{" "}
									trusted contact person
								</RouterLink>
								?
							</>
						}
					/>

					<TrustedContactForm />

					<InfoPanel
						open={!!hash}
						header={TRUSTED_CONTACT_COPY?.header}
						description={content}
					/>
				</>
			}
		/>
	);
};

export default observer(TrustedContactPageNew);
