import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

const useIsDesktop = (desktopSize: Breakpoint = "md") => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(desktopSize));

  return isDesktop;
};

export default useIsDesktop;
