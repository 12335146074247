import React, { FC, useEffect, useRef } from "react";

import { Tooltip as MUITooltip, TooltipProps } from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";

interface StyleParams {
	noMaxWidth: boolean;
}

const useStyles = makeStyles<Theme, StyleParams>({
	tooltip: {
		backgroundColor: "rgba(97, 160, 255, 1)",
		color: "white",
		boxShadow: "2px 3px 20px -1px rgba(0, 0, 0, 0.1)",
		maxWidth: (props) => (props.noMaxWidth ? "100%" : "300px"),
	},
});

interface Params extends TooltipProps {
	setClose?: () => void;
	noMaxWidth?: boolean;
}

const Tooltip: FC<Params> = React.memo((props) => {
	const classes = useStyles({ noMaxWidth: props.noMaxWidth || false });

	const { setClose } = props;

	const tooltipRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				tooltipRef.current &&
				!tooltipRef.current.contains(event.target as Node)
			) {
				setClose?.();
			}
		};

		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [setClose]);

	return (
		<MUITooltip
			ref={tooltipRef}
			{...props}
			classes={{ tooltip: classes.tooltip }}
		></MUITooltip>
	);
});

export default Tooltip;
