import { action, computed } from "mobx";

import { Application } from "stores/application/ApplicationRoot";
import FormField from "../helpers/FormField";

interface IAffiliation {
  affiliated: boolean;
  firms?: string;
}

class Affiliation {
  public rootStore: Application;

  public isAffiliated = new FormField<boolean>(false);

  public description = new FormField<string>("", ["required", "min:2"], {
    required: "Please provide additional detail.",
    min: "Names must be atleast 2 characters long.",
  });

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @computed
  public get pageInvalid() {
    return this.isAffiliated.value && this.description.value!.length < 2;
  }

  @action
  public set = (payload: IAffiliation) => {
    this.isAffiliated.set(payload.affiliated);

    if (!payload.affiliated) {
      return;
    }

    this.description.set(payload.firms || "");
  };

  public putRequest() {
    const { isAffiliated, description } = this;

    const { apiClient } = this.rootStore;

    if (!apiClient) {
      throw new Error("Could not connect to services.");
    }

    const payload = {
      affiliated: isAffiliated.value!,
      firms: description.value,
    };

    if (!payload.affiliated) {
      delete payload.firms;
    }

    return payload;
  }
}

export default Affiliation;
