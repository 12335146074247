export enum ApplicationStatus {
  NEW = "NEW",
  INPROGRESS = "IN_PROGRESS",
  SUBMITTED = "SUBMITTED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  PENDINGMOREINFO = "PENDING_MORE_INFO",
}

export enum LiquidityNeeds {
  VERY_IMPORTANT = "VERY_IMPORTANT",
  SOMEWHAT_IMPORTANT = "SOMEWHAT_IMPORTANT",
  NOT_IMPORTANT = "NOT_IMPORTANT",
}

export enum RiskTolerance {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum TimeHorizon {
  SHORT = "SHORT",
  AVERAGE = "AVERAGE",
  LONGEST = "LONGEST",
}

export enum InvestmentExperience {
  NONE = "NONE",
  LIMITED = "LIMITED",
  GOOD = "GOOD",
  EXTENSIVE = "EXTENSIVE",
}

export enum EmploymentStatus {
  EMPLOYED = "EMPLOYED",
  RETIRED = "RETIRED",
  STUDENT = "STUDENT",
  UNEMPLOYED = "UNEMPLOYED",
  SELFEMPLOYED = "SELF_EMPLOYED",
}

export interface IAddress {
  name?: string;
  streetAddress: string;
  streetAddress2?: string;
  city: string;
  state: string;
  zipCode: string;
  country?: string;
}

export enum AnnualIncome {
  EXTRA_LOW = "0-25000",
  LOW = "25001-50000",
  MID = "50001-100000",
  UPPER_MID = "100001-200000",
  UPPER = "200001-300000",
  UPPER_WEALTHY = "300001-500000",
  WEALTHY = "500001-1200000",
  EXTRA_WEALTHY = "1200001-9999999",
}

export enum WorthValues {
  LOWEST = "0-20000",
  EXTRA_LOW = "20001-50000",
  LOW = "50001-100000",
  MID = "100001-200000",
  UPPER_MID = "200001-500000",
  UPPER = "500001-1000000",
  WEALTHY = "1000001-5000000",
  EXTRA_WEALTHY = "5000001-9999999",
}

export enum InvestmentObjective {
  Speculation = "SPECULATION",
  Income = "INCOME",
  Growth = "GROWTH",
  Capital_Preservation = "CAPITAL_PRESERVATION",
  GrowthIncome = "GROWTH_INCOME",
  Other = "OTHER",
}

export enum MaritalStatus {
  Single = "SINGLE",
  Married = "MARRIED",
  Divorced = "DIVORCED",
  Widowed = "WIDOWED",
}
