import React, { FC, useContext } from "react";

import { observer } from "mobx-react-lite";
import { Grid, MenuItem, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { AnnualIncome, WorthValues } from "core/application/Application";
import {
	INCOME_VALUES,
	WORTH_VALUES,
} from "core/constants/personalFinancialValues";
import ApplicationContext from "stores/application/ApplicationRoot";
import TextfieldWithValidation from "components/UI/TextField/TextfieldWithValidation";
import LiquidNetWorthHelp from "components/Help/LiquidNetWorthHelpDialog";
import NetWorthHelp from "components/Help/NetWorthHelpDialog";

const useStyles = makeStyles((theme) => ({
	select: {
		"&:focus": {
			backgroundColor: "white",
		},
	},
	root: {
		width: "100%",
	},
	grid: {
		marginTop: 25,
		"@media (max-width:850px)": {
			marginTop: 12,
		},
	},
	infoIcon: {
		"&:hover": {
			cursor: "pointer",
		},
		fill: "#2c74f6",
		height: 18,
		width: 18,
	},
	iconButton: {
		padding: 5,
		[theme.breakpoints.down("xs")]: {
			padding: 8,
		},
	},
}));

const FinancialSituation: FC = observer(() => {
	const { financial } = useContext(ApplicationContext);

	const classes = useStyles();

	const setAnnualIncome = (e: any) => {
		const value = e.target.value as AnnualIncome;
		financial.annualIncome.set(value);
	};

	const setTotalNetWorth = (e: any) => {
		const value = e.target.value as WorthValues;
		financial.totalNetWorth.set(value);
	};

	const setLiquidWorth = (e: any) => {
		const value = e.target.value as WorthValues;
		financial.liquidWorth.set(value);
	};

	const isDesktop = useMediaQuery("(min-width: 850px)");

	return (
		<>
			<TextfieldWithValidation
				error={financial.annualIncome.errMsg}
				selectError
				select
				onChange={setAnnualIncome}
				label="Annual Income"
				value={financial.annualIncome.value}
				SelectProps={{ classes: { select: classes.select } }}
				valid={
					financial.annualIncome.value !== undefined &&
					!financial.annualIncome.errMsg
				}
			>
				{INCOME_VALUES.map((value) => (
					<MenuItem key={value.label} value={value.value}>
						{value.label}
					</MenuItem>
				))}
			</TextfieldWithValidation>

			<Grid container className={classes.grid} spacing={3}>
				<Grid item xs={12} sm={6}>
					<TextfieldWithValidation
						error={
							financial.totalLessThanLiquidMessage ||
							financial.totalNetWorth.errMsg
						}
						showValidation={!isDesktop}
						valid={
							!financial.totalNetWorth.errMsg &&
							!financial.totalLessThanLiquidMessage &&
							financial.totalNetWorth.value !== undefined
						}
						InputProps={{
							startAdornment: <NetWorthHelp />,
						}}
						selectError
						select
						label="Total Net Worth"
						value={financial.totalNetWorth.value}
						onChange={setTotalNetWorth}
						SelectProps={{ classes: { select: classes.select } }}
					>
						{WORTH_VALUES.map((value) => (
							<MenuItem key={value.label} value={value.value}>
								{value.label}
							</MenuItem>
						))}
					</TextfieldWithValidation>
				</Grid>

				<Grid item xs={12} sm={6}>
					<TextfieldWithValidation
						error={financial.liquidWorth.errMsg}
						valid={
							isDesktop
								? financial.totalNetWorth.value !== undefined &&
								  !financial.totalLessThanLiquidMessage &&
								  !financial.totalNetWorth.errMsg &&
								  financial.liquidWorth.value !== undefined &&
								  !financial.liquidWorth.errMsg
								: financial.liquidWorth.value !== undefined &&
								  !financial.liquidWorth.errMsg
						}
						selectError
						select
						label="Liquid Net Worth"
						value={financial.liquidWorth.value}
						onChange={setLiquidWorth}
						SelectProps={{ classes: { select: classes.select } }}
						InputProps={{
							startAdornment: <LiquidNetWorthHelp />,
						}}
					>
						{WORTH_VALUES.map((value) => (
							<MenuItem key={value.label} value={value.value}>
								{value.label}
							</MenuItem>
						))}
					</TextfieldWithValidation>
				</Grid>
			</Grid>
		</>
	);
});

export default FinancialSituation;
