import React from "react";

import { Switch } from "react-router-dom";

import Steps from "./Steps";
import TradingProfile from "./TradingProfile";
import APP_PATHS from "./appPaths";
import StatusContainer from "containers/Forms/Status";
import AnalyticRoute from "containers/Analytics/AnalyticsRoute";
import BasicInfoNew from "./NewLayout/BasicInfoNew";
import IdentityNew from "./NewLayout/IdentityNew";
import MaritalNew from "./NewLayout/MaritalNew";
import InvestmentKnowledgeNew from "./NewLayout/InvestmentKnowledgeNew";
import InvestmentGoalsNew from "./NewLayout/InvestmentGoalsNew";
import FinancialProfileNew from "./NewLayout/FinancialProfileNew";
import AffiliationsNew from "./NewLayout/AffiliationsNew";
import EmploymentNew from "./NewLayout/EmploymentNew";
import SweepProgramNew from "./NewLayout/SweepProgramNew";
import TrustedContactNew from "./NewLayout/TrustedContactNew";
import AgreeNew from "./NewLayout/AgreeNew";
import SignatureNew from "./NewLayout/SignatureNew";
import ReviewPageNew from "./NewLayout/ReviewNew";

const ROUTES = [
	{ path: APP_PATHS.basicInfo, component: BasicInfoNew, title: "Basic Info" },
	{
		path: APP_PATHS.identity,
		component: IdentityNew,
		title: "Verify Identity",
	},
	{ path: APP_PATHS.marital, component: MaritalNew, title: "Household Info" },
	{
		path: APP_PATHS.investmentKnowledge,
		component: InvestmentKnowledgeNew,
		title: "Investment Knowledge",
	},
	{
		path: APP_PATHS.investmentGoals,
		component: InvestmentGoalsNew,
		title: "Investment Goals",
	},
	{
		path: APP_PATHS.financialProfile,
		component: FinancialProfileNew,
		title: "Financial Profile",
	},
	{
		path: APP_PATHS.tradingProfile,
		component: TradingProfile,
		title: "Risk Profile",
	},
	{
		path: APP_PATHS.affiliations,
		component: AffiliationsNew,
		title: "Affiliations",
	},
	{ path: APP_PATHS.employment, component: EmploymentNew, title: "Employment" },
	{
		path: APP_PATHS.sweepProgram,
		component: SweepProgramNew,
		title: "Sweep Program",
	},
	{
		path: APP_PATHS.trustedContact,
		component: TrustedContactNew,
		title: "Trusted Contact",
	},
	{ path: APP_PATHS.agree, component: AgreeNew, title: "Important Documents" },
	{ path: APP_PATHS.signature, component: SignatureNew, title: "Signature" },
	{
		path: APP_PATHS.status,
		component: StatusContainer,
		title: "Application Status",
	},
	{
		path: APP_PATHS.review,
		component: ReviewPageNew,
		title: "Review Your Application",
	},
];

const Routes = React.memo(() => (
	<Switch>
		{/* <Route path="/" component={StatusContainer} /> */}

		{ROUTES.map((route) => (
			<AnalyticRoute
				title={route.title}
				key={route.path}
				path={route.path}
				component={route.component}
			/>
		))}
		<AnalyticRoute
			title={
				window.location.pathname.includes("1")
					? "Step 1"
					: window.location.pathname.includes("2")
					? "Step 2"
					: window.location.pathname.includes("3")
					? "Step 3"
					: "Status"
			}
			path="/steps/:step"
			component={Steps}
		/>
	</Switch>
));

export default Routes;
