import { computed, observable } from "mobx";
import FormField from "../helpers/FormField";
import { Application } from "./ApplicationRoot";

import Validator from "core/validations";

class SocialSecurityNumber {
	@observable
	public field: FormField<string> = new FormField(
		"",
		["required", "ssn"],
		undefined,
		1000
	);

	@observable
	public serverResponse: FormField<string> = new FormField("");

	public rootStore: Application;

	constructor(rootStore: any) {
		this.rootStore = rootStore;
	}

	@computed
	public get pageInvalid() {
		if (this.serverResponse.value) {
			return false;
		}

		const validator = new Validator(
			{ value: this.field.value },
			{ value: "ssn" }
		);

		return validator.fails() || !this.field.value;
	}

	public putRequest() {
		const { serverResponse, field } = this;

		const { apiClient } = this.rootStore;

		if (!apiClient) {
			throw new Error("Could not connect to services.");
		}

		if (serverResponse.value) {
			return;
		}

		return {
			ssn: field.value!.split("-").join(""),
		};
	}
}

export default SocialSecurityNumber;
