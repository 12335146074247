import { createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    caption: {
      fontSize: 14,
      fontWeight: 600,
      color: "#828282",
      lineHeight: "23px",
      fontFamily: "Cerebri Sans",
      letterSpacing: "1px",
    },
  })
);

interface Props {
  value?: string;
}

const StepCaption = React.memo(({ value }: Props) => {
  const styles = useStyles();

  return <Typography className={styles.caption}>{value}</Typography>;
});

export default StepCaption;
