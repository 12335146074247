import React, { FC, useContext } from "react";

import { observer } from "mobx-react-lite";
import { ButtonGroup } from "@material-ui/core";

import ApplicationContext from "stores/application/ApplicationRoot";
import { LiquidityNeeds } from "core/application/Application.d";
import { LIQUIDITY_NEEDS_VALUES } from "core/constants/perferenceValues";
import TextfieldWithValidation from "components/UI/TextField/TextfieldWithValidation";
import ButtonGroupButton from "components/UI/new/ButtonGroupButton";

interface ILiquidityNeedsProps {}

const LiquidityNeedsContainer: FC<ILiquidityNeedsProps> = (props) => {
	const { preferences } = useContext(ApplicationContext);

	const setLiquidityNeeds = (value: LiquidityNeeds) => {
		preferences.liquidityNeeds.set(value);
	};

	return (
		<TextfieldWithValidation
			customField={
				<ButtonGroup fullWidth={true}>
					{LIQUIDITY_NEEDS_VALUES.map((val) => (
						<ButtonGroupButton
							key={val.value}
							active={val.value === preferences.liquidityNeeds.value}
							onClick={() => setLiquidityNeeds(val.value)}
						>
							{val.label}
						</ButtonGroupButton>
					))}
				</ButtonGroup>
			}
			error={preferences.liquidityNeeds.errMsg}
			valid={
				preferences.liquidityNeeds.value !== undefined &&
				!preferences.liquidityNeeds.errMsg
			}
		/>
	);
};

export default observer(LiquidityNeedsContainer);
