import { action, computed } from "mobx";

import { Application } from "stores/application/ApplicationRoot";
import FormField from "../helpers/FormField";

interface IControlPerson {
  controlPerson: boolean;
  companies?: string;
}

class ControlPerson {
  public rootStore: Application;

  public isControlPerson = new FormField<boolean>(false);

  public description = new FormField<string>("", ["required", "min:2"], {
    required: "Please provide additional detail.",
    min: "Names must be atleast 2 characters long.",
  });

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @computed
  public get pageInvalid() {
    return this.isControlPerson.value && this.description.value!.length < 2;
  }

  @action
  public set = (payload: IControlPerson) => {
    this.isControlPerson.set(payload.controlPerson);

    if (!payload.controlPerson) {
      return;
    }

    this.description.set(payload.companies || "");
  };

  public putRequest() {
    const { isControlPerson, description } = this;

    const { apiClient } = this.rootStore;

    if (!apiClient) {
      throw new Error("Could not connect to services.");
    }

    const payload = {
      controlPerson: isControlPerson.value!,
      companies: description.value,
    };

    if (!payload.controlPerson) {
      delete payload.companies;
    }

    return payload;
  }
}

export default ControlPerson;
