import { makeStyles, createStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			fontWeight: 400,
			fontSize: 12,
			lineHeight: "16px",
			color: "#828282",
			marginBottom: 15,
		},
	})
);

interface Props {
	value?: string | React.ReactElement;
}

const SectionDescription = React.memo(({ value }: Props) => {
	const styles = useStyles();

	return <Typography className={styles.root}>{value}</Typography>;
});

export default SectionDescription;
