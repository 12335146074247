import { createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontWeight: 900,
      fontSize: 36,
      fontFamily: "Cerebri Sans",
    },
  })
);

interface Params {
  value?: string;
}

const StepTitle = React.memo(({ value }: Params) => {
  const styles = useStyles();

  return <Typography className={styles.header}>{value}</Typography>;
});

export default StepTitle;
