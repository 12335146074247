import React, { FunctionComponent, useContext, useMemo } from "react";

import {
	FormControl,
	FormControlLabel,
	Switch,
	Box,
	Grid,
} from "@material-ui/core";
import InputMask from "react-input-mask";
import { observer } from "mobx-react-lite";

import { ITrustedContactPersonRequestPayload } from "core/api/types/request/request";
import ApplicationContext from "stores/application/ApplicationRoot";
import Address from "./Address";
import SectionHeader from "components/UI/Typography/SectionHeader";
import TextfieldWithValidation from "components/UI/TextField/TextfieldWithValidation";
import useIsDesktop from "hooks/useIsDesktop";

interface IProps {
	onComplete?: () => void;
	updateTrustedContactPerson?: (
		payload: ITrustedContactPersonRequestPayload
	) => any;
	deleteTrustedContactPerson?: () => void;
}

const TrustedPersonContainer: FunctionComponent<IProps> = observer(() => {
	const { trustedPerson } = useContext(ApplicationContext);

	const setHasTrustedPerson = (e: any, controlPerson: boolean) => {
		trustedPerson.hasTrustedPerson.set(controlPerson);
	};

	const onPhoneNumberChange = (e: any) => {
		phoneNumber.set(e.target.value);
	};

	const { hasTrustedPerson, firstName, lastName, phoneNumber, email } =
		trustedPerson;

	const handleFirstNameChange = (e: any) => {
		const inputName = e.target.value;

		firstName.set(inputName || "");
	};

	const handleLastNameChange = (e: any) => {
		const inputName = e.target.value;

		lastName.set(inputName || "");
	};

	const setEmail = (e: any) => {
		email.set(e.target.value);
	};

	const rawPhoneNumber = phoneNumber.value?.replace(/\D/g, "");

	const validEmail = useMemo(() => {
		const regex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
		if (!email.value || email.errMsg) {
			return false;
		}

		return regex.test(email.value);
	}, [email.value, email.errMsg]);

	const isDesktop = useIsDesktop();

	return (
		<>
			<FormControlLabel
				control={
					<Switch
						checked={hasTrustedPerson.value}
						onChange={setHasTrustedPerson}
						value={hasTrustedPerson.value}
						color="primary"
					/>
				}
				label={hasTrustedPerson.value ? "Yes" : "No"}
			/>

			{hasTrustedPerson.value && (
				<>
					<Box pt={4}>
						<SectionHeader value="Trusted Contact Person Information" />
					</Box>

					{isDesktop ? (
						<TextfieldWithValidation
							customField={
								<Box flex={1}>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={6}>
											<TextfieldWithValidation
												error={firstName.errMsg}
												onChange={handleFirstNameChange}
												label="First"
												value={firstName.value}
												showValidation={false}
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<TextfieldWithValidation
												error={lastName.errMsg}
												onChange={handleLastNameChange}
												label="Last"
												value={lastName.value}
												showValidation={false}
											/>
										</Grid>
									</Grid>
								</Box>
							}
							valid={
								!lastName.errMsg &&
								!!lastName.value &&
								lastName.value.length > 2 &&
								!firstName.errMsg &&
								!!firstName.value &&
								firstName.value.length > 2
							}
						/>
					) : (
						<>
							<TextfieldWithValidation
								error={firstName.errMsg}
								onChange={handleFirstNameChange}
								label="First"
								value={firstName.value}
								valid={
									!firstName.errMsg &&
									!!firstName.value &&
									firstName.value.length > 2
								}
							/>

							<TextfieldWithValidation
								error={lastName.errMsg}
								onChange={handleLastNameChange}
								label="Last"
								value={lastName.value}
								valid={
									!lastName.errMsg &&
									!!lastName.value &&
									lastName.value.length > 2
								}
							/>
						</>
					)}

					<Box my={1.25}>
						<FormControl fullWidth={true}>
							<InputMask
								mask="(999) 999-9999"
								value={phoneNumber.value}
								onChange={onPhoneNumberChange}
							>
								{(inputProps: any) => (
									<TextfieldWithValidation
										{...inputProps}
										valid={
											!phoneNumber.errMsg &&
											!!rawPhoneNumber &&
											rawPhoneNumber.length === 10
										}
										onChange={onPhoneNumberChange}
										label="Phone Number"
										error={phoneNumber.errMsg && !!rawPhoneNumber}
										type="tel"
										useRawEvent
									/>
								)}
							</InputMask>
						</FormControl>
					</Box>

					<Box mb={1.25}>
						<TextfieldWithValidation
							valid={validEmail}
							onChange={setEmail}
							type="email"
							label="Email"
							error={email.errMsg}
							value={email.value}
						/>
					</Box>

					<Address
						id="trusted-contact-address"
						address={trustedPerson.address}
						label="Address"
					/>
				</>
			)}
		</>
	);
});

export default TrustedPersonContainer;
