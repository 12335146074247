import React, { FC } from "react";

import Button from "@material-ui/core/Button";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";

import useGoBack from "hooks/useGoBack";

const useStyles = makeStyles({
	backButton: {
		padding: "4px 0px",
		fontSize: "10px",
		display: "flex",
		width: "fit-content",
		alignItems: "center",
		color: "#0066ff",
		marginTop: 10,
		"&:hover": {
			cursor: "pointer",
		},
	},
	button: {
		fontSize: 16,
		textTransform: "none",
		padding: "8px 22px",
		width: "100%",
		fontWeight: 400,
	},
});

interface IPageNavigationNewProps
	extends RouteComponentProps<{}, {}, { returnUrl?: string; from?: boolean }> {
	onContinue?: () => void;
	onNextDisabled?: boolean;
	secondaryRoute?: string;
	continueText?: string;
	onBackDisabled?: boolean;
	hasContinue?: boolean;
}

const PageNavigationNew: FC<IPageNavigationNewProps> = ({
	onContinue,
	onNextDisabled = true,
	location,
	secondaryRoute = "/",
	continueText = "Continue",
	hasContinue = true,
	onBackDisabled = false,
}) => {
	const classes = useStyles();

	const { goBack } = useGoBack({ location, secondaryRoute });

	const hasUrl = location.state?.returnUrl;

	const backDisabled = onBackDisabled || hasUrl;

	return (
		<div>
			{hasContinue ? (
				<Button
					className={classes.button}
					fullWidth
					disabled={onNextDisabled}
					onClick={onContinue}
					disableElevation={true}
					color="primary"
					variant="contained"
				>
					{continueText}
				</Button>
			) : null}

			{!backDisabled ? (
				<div className={classes.backButton} onClick={goBack}>
					<ChevronLeft />

					<Typography>Go back</Typography>
				</div>
			) : null}
		</div>
	);
};

export default withRouter(PageNavigationNew);
