import React, { FC, useContext, useRef, useState, useEffect } from "react";

import { observer } from "mobx-react-lite";

import InvestmentGoalOption from "components/UI/new/InvestmentGoalOption";
import { InvestmentObjective } from "core/application/Application.d";
import ApplicationContext from "stores/application/ApplicationRoot";
import SectionHeader from "components/UI/Typography/SectionHeader";
import SectionDescription from "components/UI/Typography/SectionDescription";
import TextfieldWithValidation from "components/UI/TextField/TextfieldWithValidation";
import { Box } from "@material-ui/core";

interface IInvestmentFormProps {}

const InvestmentGoalsForm: FC<IInvestmentFormProps> = (props) => {
	const { investmentProfile } = useContext(ApplicationContext);
	const firstUpdate = useRef(true);

	const [objectives, setObjectives] = useState(
		OBJECTIVES.map((obj) => {
			return {
				value: obj.value,
				isChecked: false,
				label: obj.label,
				description: obj.description,
			};
		})
	);

	const onObjectiveSelection = (e: any, checked: boolean) => {
		const value = e;
		const isChecked = checked;

		const foundIndex = objectives.findIndex((obj) => obj.value === value);

		const newObjectives = [
			...objectives.map((o) => {
				return {
					...o,
					isChecked: false,
				};
			}),
		];

		newObjectives[foundIndex] = {
			isChecked,
			value,
			label: objectives[foundIndex].label,
			description: objectives[foundIndex].description,
		};

		setObjectives([...newObjectives]);
	};

	useEffect(() => {
		// do not run this use effect on first load
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}

		investmentProfile.objectives.set(objectives);
		/* eslint-disable-next-line  */
	}, [objectives]);

	useEffect(() => {
		if (!investmentProfile.investmentObjectivesInvalid) {
			const newObjectives = [...objectives];

			investmentProfile.objectives.value!.map((objective) => {
				const foundIndex = objectives.findIndex(
					(obj) => obj.value === objective.value
				);

				return (newObjectives[foundIndex] = {
					isChecked: objective.isChecked,
					value: objective.value,
					label: objectives[foundIndex].label,
					description: objectives[foundIndex].description,
				});
			});

			setObjectives(
				newObjectives as Array<{
					value: InvestmentObjective;
					label: string;
					isChecked: boolean;
					description: string;
				}>
			);
		}
		/* eslint-disable-next-line  */
	}, []);

	return (
		<>
			<TextfieldWithValidation
				customField={
					<Box flex={1}>
						<SectionHeader marginBottom={0} value="Trading Goals" />

						<SectionDescription value="Select the option that best describes your financial goals with option trading." />
					</Box>
				}
				valid={
					!investmentProfile.objectives.errMsg &&
					!investmentProfile.investmentObjectivesInvalid
				}
			/>

			{objectives.map((val) => (
				<InvestmentGoalOption
					onClick={() => onObjectiveSelection(val.value, !val.isChecked)}
					active={val.isChecked}
					title={val.label}
					subTitle={val.description}
				/>
			))}
		</>
	);
};

const OBJECTIVES = [
	{
		value: InvestmentObjective.Speculation,
		label: "Speculation",
		description:
			"Active stock or option trading and willing to take higher risk in the hope of higher returns",
	},
	{
		value: InvestmentObjective.GrowthIncome,
		label: "Growth and Income",
		description:
			"Stock or options trading for strong earnings, revenue growth and/or income potential",
	},
	{
		value: InvestmentObjective.Growth,
		label: "Growth",
		description:
			"Investing in stock or options for strong earnings and/or revenue growth potential",
	},
	{
		value: InvestmentObjective.Income,
		label: "Income",
		description:
			"Investments focused on achieving income over capital appreciation",
	},
	{
		value: InvestmentObjective.Capital_Preservation,
		label: "Capital Preservation",
		description: "Conservative investments with low risk appetite",
	},
];

export default observer(InvestmentGoalsForm);
