import React, { FC, useContext } from "react";

import { Box, MenuItem, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";

import ApplicationContext from "stores/application/ApplicationRoot";
import { EmploymentStatus } from "core/application/Application.d";
import SectionDescription from "components/UI/Typography/SectionDescription";
import TextfieldWithValidation from "components/UI/TextField/TextfieldWithValidation";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	select: {
		"&:focus": {
			backgroundColor: "white",
		},
	},
});

interface IProps {}

const EmploymentStatusContainer: FC<IProps> = (props) => {
	const { employment, isSubscriber } = useContext(ApplicationContext);

	const classes = useStyles();

	const setStatus = (e: any) => {
		const status = e.target.value;
		employment.employmentStatus.set(status);
	};

	const setUnemployedDescription = (e: any) => {
		const description = e.target.value;
		employment.unEmployedDescription.set(description);
	};

	const isUnemployed =
		employment.employmentStatus.value === EmploymentStatus.STUDENT ||
		employment.employmentStatus.value === EmploymentStatus.UNEMPLOYED;

	const hasDescriptionField =
		employment.employmentStatus.value === EmploymentStatus.RETIRED ||
		isUnemployed;

	return (
		<>
			<TextfieldWithValidation
				select
				error={employment.employmentStatus.errMsg}
				value={employment.employmentStatus.value}
				onChange={setStatus}
				selectError
				valid={
					!employment.employmentStatus.errMsg &&
					!!employment.employmentStatus.value
				}
				label={"Employment Status"}
				SelectProps={{ classes: { select: classes.select } }}
			>
				{possibleValues.map((value) => (
					<MenuItem key={value.label} value={value.value}>
						{value.label}
					</MenuItem>
				))}
			</TextfieldWithValidation>

			{Boolean(hasDescriptionField && !isSubscriber) && (
				<Box py={2}>
					<Box mb={-2}>
						<SectionDescription value="In a few words, please describe your source of income." />
					</Box>

					<TextfieldWithValidation
						value={employment.unEmployedDescription.value}
						onChange={setUnemployedDescription}
						error={employment.unEmployedDescription.errMsg}
						placeholder="Source of Income"
						valid={
							!employment.unEmployedDescription.errMsg &&
							!!employment.unEmployedDescription.value
						}
					/>
				</Box>
			)}

			{Boolean(isUnemployed && !isSubscriber) && (
				<Box>
					<Typography color="textSecondary" variant="body2">
						Please note you may be required to provide documentation to support
						your application.
					</Typography>
				</Box>
			)}
		</>
	);
};

const possibleValues = [
	{
		label: "Employed",
		value: EmploymentStatus.EMPLOYED,
	},
	{
		label: "Self Employed",
		value: EmploymentStatus.SELFEMPLOYED,
	},
	{
		label: "Retired",
		value: EmploymentStatus.RETIRED,
	},
	{
		label: "Student",
		value: EmploymentStatus.STUDENT,
	},
	{
		label: "Unemployed",
		value: EmploymentStatus.UNEMPLOYED,
	},
];

export default observer(EmploymentStatusContainer);
