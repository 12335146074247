import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles<Theme, { gutterBottom?: number }>((theme) =>
	createStyles({
		root: {
			fontWeight: 900,
			marginBottom: (props) =>
				props.gutterBottom === undefined ? 24 : props.gutterBottom,
			fontSize: 36,
			fontFamily: "Cerebri Sans",
			[theme.breakpoints.down("md")]: {
				fontSize: 32,
			},
			[theme.breakpoints.down("sm")]: {
				fontSize: 28,
			},
			"@media (max-width: 850px)": {
				fontSize: 24,
			},
		},
	})
);

interface Props {
	value?: string;
	gutterBottom?: number;
}

const PageHeader = React.memo(({ value, gutterBottom }: Props) => {
	const styles = useStyles({ gutterBottom });

	return <Typography className={styles.root}>{value}</Typography>;
});

export default PageHeader;
