import React, { FC } from "react";
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Link,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: "33px",
    },
    item: {
      paddingLeft: 32,
      paddingRight: 32,
      borderBottom: "1px solid #e7e7e7",
      marginBottom: 8,
      marginTop: 4,
    },
    icon: {
      color: theme.palette.primary.main,
    },
  })
);

interface IDocumentLinksProps {
  isSubscriber?: boolean;
}

const DocumentLinks: FC<IDocumentLinksProps> = ({ isSubscriber }) => {
  const styles = useStyles();

  return (
    <>
      {LINKS.map((link) => {
        if (isSubscriber && !link.subscriberDocument) {
          return null;
        }

        return (
          <Link
            style={{ color: "initial" }}
            key={link.label}
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItem
              className={styles.item}
              disableGutters
              button={true}
              key={link.label}
            >
              <ListItemText
                primaryTypographyProps={{ className: styles.label }}
                primary={link.label}
              />

              <ListItemIcon style={{ minWidth: 20 }}>
                <OpenInNew color="primary" />
              </ListItemIcon>
            </ListItem>
          </Link>
        );
      })}
    </>
  );
};

interface ILinks {
  label: string;
  href: string;
  subscriberDocument?: boolean;
}

const LINKS: Array<ILinks> = [
  {
    label: "Terms & Conditions",
    href: "https://disclosures.optionsai.com/agreements/terms-and-conditions",
    subscriberDocument: true,
  },
  {
    label: "Privacy Policy",
    href: "https://disclosures.optionsai.com/agreements/privacy-policy",
    subscriberDocument: true,
  },
  {
    label: "APEX Customer Agreement",
    href: "https://disclosures.optionsai.com/agreements/apex-customer-account-agreement",
  },
  {
    label: "APEX Customer Account Margin Agreement",
    href: "https://disclosures.optionsai.com/agreements/apex-customer-margin-agreement",
  },
  {
    label: "APEX Options Account Agreement",
    href: "https://disclosures.optionsai.com/agreements/apex-options-account-agreement",
  },
  {
    label: "Characteristics & Risks of Standardized Options",
    href: "https://disclosures.optionsai.com/disclosures/characteristics-and-risks-of-standardized-options",
  },
  {
    label: "Nasdaq Non Pro Subscriber Agreement",
    href: "https://disclosures.optionsai.com/agreements/nasdaq-non-professional-subscriber-agreement",
    subscriberDocument: true,
  },
  {
    label: "NYSE Non Pro Subscriber Agreement",
    href: "https://disclosures.optionsai.com/agreements/nyse-non-professional-subscriber-agreement",
    subscriberDocument: true,
  },
  {
    label: "OPRA Non Pro Subscriber Agreement",
    href: "https://disclosures.optionsai.com/agreements/opra-non-professional-subscriber-agreement",
    subscriberDocument: true,
  },
  {
    label: "Business Continuity Statement",
    href: "https://disclosures.optionsai.com/disclosures/business-continuity-statement",
  },

  {
    label: "Margin Risk Disclosure",
    href: "https://disclosures.optionsai.com/disclosures/margin-risk-disclosure",
  },
  {
    label: "ETF Risk Disclosure",
    href: "https://disclosures.optionsai.com/disclosures/etf-risk-disclosure",
  },
  {
    label: "Day Trading Risk Disclosure",
    href: "https://disclosures.optionsai.com/disclosures/day-trading-risk-disclosure",
  },
  {
    label: "Commission and Fees",
    href: "https://disclosures.optionsai.com/disclosures/commission-and-fees",
  },
  {
    label: "Options Spread Risk Disclosure",
    href: "https://disclosures.optionsai.com/disclosures/options-spread-risk-disclosure",
  },

  {
    label: "State Registrations",
    href: "https://disclosures.optionsai.com/disclosures/state-registrations",
  },

  {
    label: "Content & Social Media Disclosure",
    href: "https://disclosures.optionsai.com/disclosures/content-and-social-media-disclosure",
  },
  {
    label: "Form CRS",
    href: "https://disclosures.optionsai.com/disclosures/form-crs-customer-relationship-summary",
  },
];

export default DocumentLinks;
