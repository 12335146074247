import React, { FC } from "react";

// styles
import { Box, CircularProgress } from "@material-ui/core";
import DefaultLayout from "pages/DefaultLayout";

const FullPageLoader: FC = () => (
  <DefaultLayout hasContinue={false} onBackDisabled={true}>
    <Box
      data-testid="FullPageLoader"
      height="80vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
      {/* <Typography variant="h5">Almost there...</Typography> */}
    </Box>
  </DefaultLayout>
);

export default FullPageLoader;
