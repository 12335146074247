import { useMemo } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

const useQueryString = () => {
  const location = useLocation();

  const query = useMemo(
    () => queryString.parse(location.search),
    [location.search]
  );

  return query;
};

export default useQueryString;
