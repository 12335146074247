import React, { FC, useContext } from "react";

import { observer } from "mobx-react-lite";

import {
	Box,
	FormControl,
	FormControlLabel,
	Switch,
	Typography,
	Dialog,
} from "@material-ui/core";

import { IAffiliatedRequestPayload } from "core/api/types/request/request";
import ApplicationContext from "stores/application/ApplicationRoot";
import AffilationDetails from "containers/new/AffilationDetails";
import { useState } from "react";
import RouterLink from "components/UI/new/RouterLink";
import useErrorStyles from "utils/useErrorStyles";

interface IProps {
	onComplete?: () => void;
	updateAffiliations?: (payload: IAffiliatedRequestPayload) => any;
}

const AffiliationContainer: FC<IProps> = observer(() => {
	const styles = useErrorStyles();
	const { affiliation, isSubscriber } = useContext(ApplicationContext);

	const setAffiliation = (e: any, affilated: boolean) => {
		if (affilated) {
			setIsOpen(true);
		}

		affiliation.isAffiliated.set(affilated);
	};

	const { isAffiliated, description } = affiliation;

	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);

		if (isSubscriber) {
			return;
		}

		if (!affiliation.description.value) {
			affiliation.isAffiliated.set(false);
		}
	};

	const handleSave = (val = "") => {
		affiliation.description.set(val);

		handleClose();
	};

	const id = `formfield${Math.random() * 100}${Math.random() * 100}`;

	const executeScroll = () => {
		const element = document.getElementById(`${id}`);

		element?.scrollIntoView({ behavior: "smooth" });
	};

	const displayError = isAffiliated.value && !!description.errMsg;

	return (
		<Box>
			<FormControl id={id} onFocus={executeScroll} margin="none">
				<FormControlLabel
					control={
						<Switch
							onChange={setAffiliation}
							value={isAffiliated.value}
							checked={isAffiliated.value}
							color="primary"
						/>
					}
					label={isAffiliated.value ? "Yes" : "No"}
				/>

				{!isSubscriber && (
					<>
						{displayError && (
							<Typography className={styles.error}>
								Please provide name of firm(s) or organizations(s).
							</Typography>
						)}

						{isAffiliated.value && (
							<RouterLink to="#" onClick={handleOpen}>
								Edit Affiliations
							</RouterLink>
						)}
					</>
				)}
			</FormControl>

			{!isSubscriber ? (
				<Dialog open={isOpen} onClose={handleClose}>
					<Box p={2}>
						<Typography variant="h5">Affiliations</Typography>

						<AffilationDetails
							onSave={handleSave}
							handleClose={handleClose}
							initialDescription={description.value}
						/>
					</Box>
				</Dialog>
			) : (
				<Dialog open={isOpen} onClose={handleClose}>
					<Box p={4}>
						<Typography style={{ marginBottom: 16 }} variant="h3">
							We're Sorry
						</Typography>

						<Typography>
							Options AI Subscription is only available to non professional
							investors.
						</Typography>
					</Box>
				</Dialog>
			)}
		</Box>
	);
});

export default AffiliationContainer;
