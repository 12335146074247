import React, { FC, useContext } from "react";

import { observer } from "mobx-react-lite";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ApplicationContext from "stores/application/ApplicationRoot";
import TextfieldWithValidation from "components/UI/TextField/TextfieldWithValidation";
import useIsDesktop from "hooks/useIsDesktop";
import SectionHeader from "components/UI/Typography/SectionHeader";

const useStyles = makeStyles({
	nameDescription: {
		fontWeight: 400,
		fontSize: 12,
		lineHeight: "16px",
		color: "#828282",
		marginBottom: 15,
	},
});

interface ILegalNameProps {}

const LegalName: FC<ILegalNameProps> = (props) => {
	const { name, isSubscriber } = useContext(ApplicationContext);

	const styles = useStyles();

	const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		name.firstName.set(e.target.value);
	};

	const handleMiddleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		name.middle.set(e.target.value);
	};

	const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		name.last.set(e.target.value);
	};

	const isDesktop = useIsDesktop();

	return (
		<>
			<TextfieldWithValidation
				customField={
					<Box flex={1}>
						{!isDesktop ? (
							<>
								<TextfieldWithValidation
									customField={
										<Box flex={1}>
											<SectionHeader marginBottom={0} value="Your name" />

											<Typography className={styles.nameDescription}>
												{isSubscriber
													? `Please enter your legal name.`
													: `Please enter your full legal name as it appears on your government
										issued ID`}
											</Typography>
										</Box>
									}
									valid={
										!name.last.errMsg &&
										!!name.last.value &&
										name.last.value.length >= 2 &&
										!name.middle.errMsg &&
										!name.firstName.errMsg &&
										!!name.firstName.value &&
										name.firstName.value.length >= 2
									}
								/>
							</>
						) : null}
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={4}>
								<TextfieldWithValidation
									error={name.firstName.errMsg}
									onChange={handleFirstNameChange}
									autoFocus
									value={name.firstName.value}
									label="First"
									showValidation={false}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={4}>
								<TextfieldWithValidation
									error={name.middle.errMsg}
									onChange={handleMiddleNameChange}
									value={name.middle.value}
									label="Middle (optional)"
									showValidation={false}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={4}>
								<TextfieldWithValidation
									error={name.last.errMsg}
									value={name.last.value}
									onChange={handleLastNameChange}
									label="Last"
									showValidation={false}
								/>
							</Grid>
						</Grid>
					</Box>
				}
				valid={
					!name.last.errMsg &&
					!!name.last.value &&
					name.last.value.length >= 2 &&
					!name.middle.errMsg &&
					!name.firstName.errMsg &&
					!!name.firstName.value &&
					name.firstName.value.length >= 2
				}
				showValidation={isDesktop ? true : false}
			/>
		</>
	);
};

export default observer(LegalName);
