import { action, computed, observable } from "mobx";
import FormField from "stores/helpers/FormField";

import { Application } from "stores/application/ApplicationRoot";
import isValidEmail from "utils/validateEmail";
import Address from "../helpers/Address";

interface ITrustedContactPerson {
  address: {
    city: string;
    state: string;
    streetAddress: string;
    streetAddress2?: string;
    zipCode: string;
  };
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

class TrustedPerson {
  public rootStore: Application;

  @observable
  public hasTrustedPerson = new FormField<boolean>(false);

  @observable
  public firstName = new FormField<string>("", ["min:1"], {
    min: "Please provide a valid last name",
  });

  @observable
  public lastName = new FormField<string>("", ["min:1"], {
    min: "Please provide a valid last name",
  });

  @observable
  public phoneNumber = new FormField<string>("", ["required", "telephone"]);

  @observable
  public email = new FormField<string>("", ["required", "email"]);

  @observable
  public address = new Address();

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @computed
  public get pageInvalid() {
    return (
      this.hasTrustedPerson.value &&
      (this.firstName.value!.length < 1 ||
        this.lastName.value!.length < 1 ||
        this.phoneNumber.value!.split("")[13] === "_" ||
        !this.phoneNumber ||
        !isValidEmail(this.email.value!) ||
        this.address.streetAddress.value!.length < 5 ||
        this.address.streetAddress.error ||
        this.address.zipCode.error ||
        this.address.city.value!.length < 1 ||
        this.address.state.value!.length < 1 ||
        this.address.zipCode.value!.length < 1)
    );
  }

  @action
  public set = (payload: ITrustedContactPerson) => {
    this.hasTrustedPerson.set(!!payload.firstName);
    if (!payload.firstName) {
      return;
    }

    const cleanedValue = payload.phoneNumber
      .replace("+1", "")
      .replace("(", "")
      .replace(")", "")
      .replace(" ", "");

    let newValue = cleanedValue.split("");

    newValue.splice(0, 0, "(");
    newValue.splice(4, 0, ")");

    newValue.splice(8, 0, "-");

    this.firstName.set(payload.firstName);
    this.lastName.set(payload.lastName);
    this.email.set(payload.email);
    this.phoneNumber.set(newValue.join(""));

    const { streetAddress, streetAddress2, city, state, zipCode } =
      payload.address;

    this.address.streetAddress.set(streetAddress);
    this.address.streetAddress2.set(streetAddress2 || "");
    this.address.city.set(city);
    this.address.state.set(state);
    this.address.zipCode.set(zipCode);
  };

  public putRequest() {
    const {
      address,
      firstName,
      lastName,
      email,
      phoneNumber,
      hasTrustedPerson,
    } = this;

    const { apiClient } = this.rootStore;

    if (!apiClient) {
      throw new Error("Could not connect to services.");
    }

    if (hasTrustedPerson.value) {
      return {
        firstName: firstName.value!,
        lastName: lastName.value!,
        phoneNumber: phoneNumber.value
          ?.replace("(", "")
          .replace(")", "")
          .replace(" ", "")!,
        email: email.value!,
        address: {
          streetAddress: address.streetAddress.value!,
          streetAddress2: address.streetAddress2.value,
          city: address.city.value!,
          state: address.state.value!,
          zipCode: address.zipCode.value!,
        },
      };
    } else {
      return undefined; // todo test resubmitting w/out deletes
    }
  }
}

export default TrustedPerson;
