import { InvestmentObjective } from "../Application.d";

const readableObjective = (objective?: InvestmentObjective) => {
  switch (objective) {
    case InvestmentObjective.Capital_Preservation: {
      return "Capital Preservation";
    }

    case InvestmentObjective.Growth: {
      return "Growth";
    }

    case InvestmentObjective.GrowthIncome: {
      return "Growth & Income";
    }

    case InvestmentObjective.Income: {
      return "Income";
    }

    case InvestmentObjective.Other: {
      return "Other";
    }

    case InvestmentObjective.Speculation: {
      return "Speculation";
    }

    default:
      return undefined;
  }
};

export { readableObjective };
