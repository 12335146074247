import React from "react";

const AILogo = React.memo(() => {
	return (
		<svg
			width="36"
			height="36"
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2 9.2C2 5.22355 5.22355 2 9.2 2H26.8C30.7764 2 34 5.22355 34 9.2V26.8C34 30.7764 30.7764 34 26.8 34H9.2C5.22355 34 2 30.7764 2 26.8V9.2Z"
				fill="white"
			/>
			<path
				d="M4.52631 10.5895C4.52631 7.24094 7.24087 4.52637 10.5895 4.52637H25.4105C28.7591 4.52637 31.4737 7.24094 31.4737 10.5895V25.4106C31.4737 28.7592 28.7591 31.4737 25.4105 31.4737H10.5895C7.24088 31.4737 4.52631 28.7592 4.52631 25.4106V10.5895Z"
				fill="#30455E"
			/>
			<path
				d="M9.2 1C4.67126 1 1 4.67127 1 9.2V26.8C1 31.3287 4.67127 35 9.2 35H26.8C31.3287 35 35 31.3287 35 26.8V9.2C35 4.67126 31.3287 1 26.8 1H9.2Z"
				stroke="#30455E"
				stroke-width="2"
			/>
			<path
				d="M18.1295 23.8948L17.2942 21.5575H12.124L11.2975 23.8948H7.89471L12.6516 11.2632H16.7754L21.5323 23.8948H18.1295ZM13.0824 18.8593H16.3358L14.7091 14.2879L13.0824 18.8593Z"
				fill="white"
			/>
			<path d="M23.071 23.8948V11.2632H26.421V23.8948H23.071Z" fill="white" />
		</svg>
	);
});

export default AILogo;
