import React, { FC, useMemo } from "react";

import { makeStyles } from "@material-ui/core/styles";
import useIsDesktop from "hooks/useIsDesktop";

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		maxWidth: "500px",
	},
	stepsContainer: {
		width: "-webkit-fill-available",
		"@supports (width: -moz-available)": {
			width: "-moz-available",
		},
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	step: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		flex: 1,
	},
	line: {
		width: "100%",
		height: "2px",
	},
	completedLine: {
		backgroundColor: "white",
		"@media (max-width: 850px)": {
			backgroundColor: "#0066ff",
		},
	},
	inProgressLine: {
		position: "relative",
		width: "100%",
		height: "2px",
		backgroundColor: "rgba(255, 255, 255, 0.35)",
		"@media (max-width: 850px)": {
			backgroundColor: "rgba(0, 120, 255, 0.35)",
		},
		overflow: "hidden",

		"&::before": {
			content: '""',
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			backgroundColor: "white",
			"@media (max-width: 850px)": {
				backgroundColor: "#0066ff",
			},
			transformOrigin: "left",
			transform: "scaleX(0)",
			animation: "$sweepReverse 1s forwards",
		},
	},

	"@keyframes sweepReverse": {
		"0%": {
			transform: "scaleX(0)",
		},
		"100%": {
			transform: "scaleX(1)",
		},
	},
	toCompleteLine: {
		backgroundColor: "rgba(255,255,255,0.35)",
		"@media (max-width: 850px)": {
			backgroundColor: "rgba(0, 120, 255, 0.35)",
		},
	},
	circle: {
		minWidth: 20,
		minHeight: 20,
		borderRadius: "50%",
	},
	completedCircle: {
		backgroundColor: "white",
		"@media (max-width: 850px)": {
			backgroundColor: "#0066ff",
		},
	},
	inProgressCircle: {
		border: "3px solid rgba(255, 255, 255, 0.35)",
		animation: "$borderSweep 1s forwards 0.9s",
		"@media (max-width: 850px)": {
			border: "3px solid rgba(0, 120, 255, 0.35)",
			animation: "$borderSweepSm 1s forwards 0.9s",
		},
	},
	"@keyframes borderSweepSm": {
		"0%": {
			borderColor: "rgba(0, 120, 255, 0.35)",
		},
		"100%": {
			borderColor: "#0066FF",
		},
	},
	"@keyframes borderSweep": {
		"0%": {
			borderColor: "rgba(255,255,255,0.35)",
		},
		"100%": {
			borderColor: "white",
		},
	},
	toCompleteCircle: {
		border: "3px solid rgba(255, 255, 255, 0.35)",
		"@media (max-width: 850px)": {
			border: "3px solid rgba(0, 120, 255, 0.35)",
		},
	},
	completedstep: {
		color: "rgba(255,255,255, 1)",
		"@media (max-width: 850px)": {
			color: "rgba(0, 120, 255, 1)",
		},
	},
	inProgressStep: {
		color: "rgba(255,255,255,1)",
		fontWeight: "700 !important" as any,
		"@media (max-width: 850px)": {
			color: "rgba(0, 120, 255, 1)",
		},
	},

	stepIndicator: {
		position: "relative",
	},
	stepLabel: {
		position: "absolute",
		top: "calc(-100% - 5px)",
		fontSize: "10px",
		fontWeight: 400,
		letterSpacing: "1px",
		fontFamily: "Cerebri Sans",
		whiteSpace: "nowrap",
		[theme.breakpoints.up("xl")]: {
			fontSize: "11px",
		},
	},
	timeLabel: {
		top: "calc(100% + 5px) !important",
	},
	stepLabelCenter: {
		left: "50%",
		transform: "translateX(-50%)",
	},
	stepLabelRight: {
		right: "0%",
		transform: "translateX(0%)",
	},
	stepLabelLeft: {
		left: "0%",
		transform: "translateX(0%)",
	},
	white: {
		color: "white",
		fontWeight: "900 !important" as any,
		"@media (max-width: 850px)": {
			color: "#0066FF",
		},
	},
}));

interface Params {
	step: number;
	isSubscriber?: boolean;
}

const ProgressBar: FC<Params> = ({ step, isSubscriber }) => {
	const classes = useStyles();


	console.log('progress bar - is subscriber', isSubscriber);

	const steps = useMemo(() => {
		return ["Create Account", "About You", isSubscriber ? "Investor Status" : "Suitability", "Review & Sign"];
	}, [isSubscriber]);

	const isLg = useIsDesktop("lg");

	return (
		<div className={classes.mainContainer}>
			<div className={classes.stepIndicator}>
				<div
					className={`${classes.circle} ${step > 1
						? classes.completedCircle
						: step === 1
							? classes.inProgressCircle
							: classes.toCompleteCircle
						}
        `}
				/>

				{(isLg || step === 1) && (
					<div
						className={`${classes.stepLabel} ${classes.stepLabelLeft} ${step === 1 ? classes.white : classes.completedstep
							}`}
					>
						{steps[0]}
					</div>
				)}
			</div>

			<div className={classes.stepsContainer}>
				{steps.map((title, index) => (
					<>
						{index > 0 ? (
							<div className={classes.step}>
								<div
									className={`${classes.line} ${step > index + 1
										? classes.completedLine
										: step === index + 1
											? classes.inProgressLine
											: classes.toCompleteLine
										}
                `}
								/>

								<div className={classes.stepIndicator}>
									<div
										className={`${classes.circle} ${step > index + 1
											? classes.completedCircle
											: step === index + 1
												? classes.inProgressCircle
												: classes.toCompleteCircle
											}
                    `}
									/>

									{(isLg || step === index + 1) && (
										<div
											className={`${classes.stepLabel} ${index === 3
												? classes.stepLabelRight
												: classes.stepLabelCenter
												} ${step === index + 1
													? classes.inProgressStep
													: classes.completedstep
												}`}
										>
											{title}
										</div>
									)}

									{isLg && index !== 3 && step - 1 <= index && (
										<div
											className={`${classes.stepLabel} ${classes.timeLabel} ${index === 3
												? classes.stepLabelRight
												: classes.stepLabelCenter
												} ${step === index + 1
													? classes.inProgressStep
													: classes.completedstep
												}`}
										>
											2 min
										</div>
									)}
								</div>
							</div>
						) : null}
					</>
				))}
			</div>
		</div>
	);
};

export default ProgressBar;
