import React, { FunctionComponent, useContext, useState } from "react";

import { observer } from "mobx-react-lite";

import {
	FormControl,
	FormControlLabel,
	Switch,
	Typography,
	Dialog,
	Box,
} from "@material-ui/core";

import { IControlPersonRequestPayload } from "core/api/types/request/request";

import ApplicationContext from "stores/application/ApplicationRoot";
import RouterLink from "components/UI/new/RouterLink";
import ControlPersonDetails from "containers/new/ControlPersonDetails";
import useErrorStyles from "utils/useErrorStyles";

interface IProps {
	onComplete?: () => void;
	updateControlPerson?: (payload: IControlPersonRequestPayload) => any;
}

const ControlPersonContainer: FunctionComponent<IProps> = observer(() => {
	const styles = useErrorStyles();

	const { controlPerson } = useContext(ApplicationContext);

	const setIsControlPerson = (e: any, value: boolean) => {
		if (value) {
			setIsOpen(true);
		}

		controlPerson.isControlPerson.set(value);
	};

	const { isControlPerson, description } = controlPerson;

	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);

		if (!controlPerson.description.value) {
			controlPerson.isControlPerson.set(false);
		}
	};

	const handleSave = (value = "") => {
		controlPerson.description.set(value);

		handleClose();
	};

	const displayError = isControlPerson.value && !!description.errMsg;

	return (
		<>
			<FormControl margin="none">
				<FormControlLabel
					control={
						<Switch
							checked={isControlPerson.value}
							onChange={setIsControlPerson}
							value={isControlPerson.value}
							color="primary"
						/>
					}
					label={isControlPerson.value ? "Yes" : "No"}
				/>

				{displayError && (
					<Typography className={styles.error}>{description.errMsg}</Typography>
				)}

				{isControlPerson.value && (
					<RouterLink to="#" onClick={handleOpen}>
						Edit Control Person
					</RouterLink>
				)}
			</FormControl>

			<Dialog open={isOpen} onClose={handleClose}>
				<Box p={2}>
					<Typography variant="h5">Control Person</Typography>

					<ControlPersonDetails
						onSave={handleSave}
						handleClose={handleClose}
						initialDescription={description.value}
					/>
				</Box>
			</Dialog>
		</>
	);
});

export default ControlPersonContainer;
