import React, { FC, useState, useEffect, useContext, useRef } from "react";

import Box from "@material-ui/core/Box";

import FormControl from "@material-ui/core/FormControl";
import GooglePlacesAutocomplete, {
	geocodeByPlaceId,
} from "react-google-places-autocomplete";
import AddressDetails from "./AddressDetails";
import Dialog from "@material-ui/core/Dialog";
import {
	Typography,
	InputAdornment,
	Checkbox,
	Button,
	useMediaQuery,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { IAddress } from "core/application/Application";
import { Close } from "@material-ui/icons";

import Address from "stores/helpers/Address";
import Addresses from "stores/application/Address";
import ApplicationContext from "stores/application/ApplicationRoot";
import TextfieldWithValidation from "components/UI/TextField/TextfieldWithValidation";

interface IAddressProps {
	address: Address;
	isEmployerSearch?: boolean;
	initialEmploymentString?: string;
	hasMailingAddress?: boolean;
	id: string;
	label?: string;
}

const RenderTextField = (props: any) => {
	const { helperText } = props;

	console.log(helperText);

	console.log(props);
	return <TextfieldWithValidation {...props} useRawEvent />;
};

const AddressForm: FC<IAddressProps> = ({
	address,
	isEmployerSearch = false,
	initialEmploymentString,
	hasMailingAddress,
	id,
	label,
}) => {
	const application = useContext(ApplicationContext);
	const { addresses } = application;

	const [detailsOpen, setDetailsOpen] = useState(false);
	const [selectionInvalid, setSelectionInvalid] = useState(false);

	const [noAddressEntered, setNoAddressEntered] = useState(false);

	const checkIfAddressExists = () => {
		if (address.streetAddress.value) {
			const { streetAddress, streetAddress2, city, state, zipCode, name } =
				address;

			return `${name.value} ${streetAddress.value} ${streetAddress2.value} ${city.value}, ${state.value} ${zipCode.value}`.replace(
				"  ",
				" "
			);
		}
	};

	const [displayDisabledInput, setDisplayDisabledInput] =
		useState(checkIfAddressExists);

	const [tempAddress, setTempAddress] = useState(new Addresses());

	const [pId, setPId] = useState("");

	const handleClose = () => {
		setDetailsOpen(false);
	};

	const handleOpen = () => {
		setDetailsOpen(true);
	};

	const handlePlaceSelect = (a: any) => {
		setPId(a.place_id);
	};

	useEffect(() => {
		if (!pId) {
			return;
		}

		(async () => {
			try {
				const val = await geocodeByPlaceId(pId);

				const found = val[0];

				const { address_components } = found as any;

				const streetNumber: any = address_components.find((c: any) =>
					c.types.includes("street_number")
				)?.long_name;

				const street: any = address_components.find((c: any) =>
					c.types.includes("route")
				)?.long_name;

				const combinedStreet = `${streetNumber} ${street}`;

				const city: any = address_components.find((c: any) =>
					c.types.includes("locality")
				)?.long_name;

				let state: any = address_components.find((c: any) =>
					c.types.includes("administrative_area_level_1")
				)?.short_name;

				const zip: any = address_components.find((c: any) =>
					c.types.includes("postal_code")
				)?.short_name;

				let country: any = address_components.find((c: any) =>
					c.types.includes("country")
				)?.short_name;

				if(country === 'SG') {
					state = 'SG';
				}


				if (country === "US") {
					country = "USA";
				}

				tempAddress.residential.streetAddress.set(combinedStreet);
				tempAddress.residential.city.set(city);
				tempAddress.residential.state.set(state);
				tempAddress.residential.zipCode.set(zip);
				tempAddress.residential.country.set(country);

				handleOpen();
				setPId("");
				setSelectionInvalid(false);
			} catch (e) {
				setPId("");
				setSelectionInvalid(true);
			}
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pId]);

	const handleMailingCheckBox = (a: any, check: boolean) => {
		addresses.useMailingAddress.set(check);
	};

	const handleAddressSave = ({
		name = "",
		streetAddress,
		streetAddress2 = "",
		city,
		state,
		zipCode,
		country = "",
	}: IAddress) => {
		tempAddress.residential.streetAddress.set(streetAddress);
		tempAddress.residential.streetAddress2.set(streetAddress2);
		tempAddress.residential.city.set(city);
		tempAddress.residential.state.set(state);
		tempAddress.residential.zipCode.set(zipCode);
		tempAddress.residential.country.set(country);

		address.streetAddress.set(streetAddress);
		address.streetAddress2.set(streetAddress2);
		address.city.set(city);
		address.state.set(state);
		address.zipCode.set(zipCode);
		address.country.set(country);

		handleClose();

		setDisplayDisabledInput(
			`${
				name ? `${name},` : ""
			} ${streetAddress} ${streetAddress2} ${city}, ${state} ${zipCode}`.replace(
				"  ",
				" "
			)
		);
	};

	const handleResetAddress = () => {
		setTempAddress(new Addresses());

		setDisplayDisabledInput("");
		address.streetAddress.set("");
		address.streetAddress2.set("");
		address.city.set("");
		address.state.set("");
		address.zipCode.set("");
		address.country.set("");
	};

	const isDesktop = useMediaQuery("(min-width: 850px)");

	useEffect(() => {
		if (!address.streetAddress.value) {
			return;
		}

		handleAddressSave({
			streetAddress: address.streetAddress.value!,
			streetAddress2: address.streetAddress2.value!,
			city: address.city.value!,
			state: address.state.value!,
			zipCode: address.zipCode.value!,
			country: address.country.value,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const placesRef = useRef<GooglePlacesAutocomplete | null>(null);

	useEffect(() => {
		if (!placesRef.current) {
			return;
		}

		const input = document.getElementById(
			id + "-react-google-places-autocomplete-input"
		) as HTMLInputElement;

		input.onblur = () => {
			if (!tempAddress.residential.streetAddress.value) {
				setNoAddressEntered(true);
			} else {
				setNoAddressEntered(false);
			}
		};
		// eslint-disable-next-line
	}, [placesRef, tempAddress.residential.streetAddress.value]);

	return (
		<>
			{!displayDisabledInput && (
				<GooglePlacesAutocomplete
					idPrefix={id}
					ref={placesRef as any}
					placeholder="123 Main Street New York, NY 10001"
					renderInput={(props) => (
						<RenderTextField
							{...props}
							label={label}
							error={
								selectionInvalid
									? "Address not recognized, please enter manually"
									: noAddressEntered
									? "Please enter a valid address"
									: undefined
							}
						/>
					)}
					onSelect={handlePlaceSelect}
				/>
			)}

			{!!displayDisabledInput && (
				<FormControl margin="none" fullWidth={true}>
					<TextfieldWithValidation
						value={displayDisabledInput}
						disabled={true}
						InputProps={{
							endAdornment: (
								<InputAdornment onClick={handleResetAddress} position="end">
									<Close />
								</InputAdornment>
							),
						}}
						valid
					/>
				</FormControl>
			)}

			<Box alignItems="center" display={isDesktop ? "flex" : "block"} pt={1.25}>
				<Button variant="text" color="primary" onClick={handleOpen}>
					Edit Address Manually
				</Button>

				{hasMailingAddress && (
					<Box
						ml={isDesktop ? 5 : 0}
						justifyContent={isDesktop ? "center" : "flex-start"}
						display="flex"
						alignItems="center"
					>
						<Checkbox
							checked={addresses.useMailingAddress.value}
							onChange={handleMailingCheckBox}
							color="primary"
						/>
						<Typography>Add Mailing Address?</Typography>
					</Box>
				)}
			</Box>

			<Dialog open={detailsOpen}>
				<Box p={2}>
					<Typography variant="h5">
						{isEmployerSearch ? "Company Address" : "Address"}
					</Typography>

					<AddressDetails
						initialStreet={tempAddress.residential.streetAddress.value}
						initialStreet2={tempAddress.residential.streetAddress2.value}
						initialCity={tempAddress.residential.city.value}
						initialCountry={tempAddress.residential.country.value}
						initialState={tempAddress.residential.state.value}
						initialZip={tempAddress.residential.zipCode.value}
						handleClose={handleClose}
						onSave={handleAddressSave}
					/>
				</Box>
			</Dialog>
		</>
	);
};

export default observer(AddressForm);
