import React, { FC, useContext } from "react";

import { FormControlLabel, Switch } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react-lite";

import ApplicationContext from "stores/application/ApplicationRoot";
import RouterLink from "components/UI/new/RouterLink";
import InfoPanel from "components/UI/new/InfoPanel";
import SectionDescription from "components/UI/Typography/SectionDescription";
import APP_PATHS from "pages/appPaths";
import ApplicationFormLayout from "containers/Layout/ApplicationFormLayout";
import ReviewPanel from "containers/Steps/ReviewPanel";
import SectionHeader from "components/UI/Typography/SectionHeader";

interface ITrustedContactPageProps
	extends RouteComponentProps<{}, {}, { returnUrl?: string; from?: boolean }> {}

const SweepProgramPageNew: FC<ITrustedContactPageProps> = ({
	history,
	location,
}) => {
	const application = useContext(ApplicationContext);

	const { sweepProgram } = application;

	const handleNext = () => {
		history.push(APP_PATHS.trustedContact, { from: true });
	};

	const hash = location.hash;

	const content = `The Sweep Program terms and conditions and the list of banks participating and/or products available in the Sweep Program can be located at ApexClearing.com/disclosures. \n\n Free credit balances in the account, including dividends and proceeds from the sale of securities that are credited to the account while enrolled in the Sweep Program, may automatically be swept in accordance with the terms of the Sweep Program. \n\n Further, you agree Apex Clearing Corporation may make changes to the Sweep Program terms and conditions or any products or banks in the Sweep Program at any time in Apex’s sole discretion.  Your enrollment in the Sweep Program does not guarantee free credit balances in your account will be swept. \n\n If you wish to opt out of the Sweep Program you may select “No” in this Section or you may notify your introducing firm at any time.`;

	const setSweepProgram = (e: any, enroll: boolean) => {
		sweepProgram.enroll.set(enroll);
	};

	return (
		<>
			<ApplicationFormLayout
				step={3}
				loading={application.loading}
				onNext={handleNext}
				onNextDisabled={false}
				leftPanelChildren={<ReviewPanel />}
				continueText="Next: Trusted Contact"
				formChildren={
					<>
						<SectionHeader
							marginBottom={10}
							value="Would you like to enroll your account into the sweep program?"
						/>

						<SectionDescription
							value={
								<>
									By opening your account and/or selecting yes below, you agree
									to enroll in the Apex Clearing Corporation Sweep Program (the{" "}
									<RouterLink to="#info">“Sweep Program” </RouterLink> ) and
									agree that you have read and understand the terms and
									conditions of the Sweep Program.
								</>
							}
						/>

						<FormControlLabel
							control={
								<Switch
									checked={sweepProgram.enroll.value}
									onChange={setSweepProgram}
									value={sweepProgram.enroll.value}
									color="primary"
								/>
							}
							label={
								sweepProgram.enroll.value
									? "Yes, enroll me"
									: "No, do not enroll me"
							}
						/>

						<InfoPanel
							open={!!hash}
							header="Sweep Program"
							description={content}
						/>
					</>
				}
			/>
		</>
	);
};

export default observer(SweepProgramPageNew);
