import axios from "axios";

export default (token?: string) => {
  const config = {
    baseURL: process.env.REACT_APP_API,
  };

  const client = axios.create(config);

  client.defaults.headers.common.Authorization = `Bearer ${token}`;

  client.interceptors.response.use((response) => response);

  return client;
};
